import React from "react";
import { useParams } from "react-router";
import classnames from "classnames";
import MetaTags from "react-meta-tags";

import {
  TripHeader,
  TripAssets,
  TripOtherInf,
  TripProgram,
  TripEstimate,
  TripRoute,
  TripVideo,
  SimilarTrips,
} from "./";
import { Title } from "../../common";
import PageSection from "../../PageSection";

import styles from "./TripPage.module.scss";
import useTripData from "../../../services/dataHooks/useTrip";
import { ITrip } from "./tripData";
import useSimilarTrip from "../../../services/dataHooks/useSimilarTrips";
import GallerySlider from "../../common/Gallery/GallerySlider";
import { WWW_URL } from "appConfig";

export interface IParams {
  slug: string;
}

const TripPage: React.FC = () => {
  const { slug: tripSlug } = useParams<IParams>();
  const { trip } = useTripData(tripSlug);
  const { similarTrips } = useSimilarTrip(trip?.id);
  const sortedAndFiltered = similarTrips
    ?.slice()
    .filter((trip) => new Date(trip.startDate) >= new Date())
    .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

  return (
    <>
      <MetaTags>
        <link rel="canonical" href={WWW_URL + "/wyprawa/" + trip?.slug} />

        <title>{trip?.name} - Shangrila Travel</title>
        {trip?.metaDescription && trip.metaDescription.length > 0 && (
          <meta name="description" content={trip?.metaDescription} />
        )}
        {trip?.metaKeywords && trip.metaKeywords.length > 0 && <meta name="keywords" content={trip?.metaKeywords} />}
        {trip?.ogTitle && trip.ogTitle.length > 0 && <meta property="og:title" content={trip?.ogTitle} />}
        {trip?.ogDescription && trip.ogDescription.length > 0 && (
          <meta property="og:description" content={trip?.ogDescription} />
        )}
        <meta property="og:locale" content="pl_PL" />
        {trip?.slug && trip.slug.length > 0 && <meta property="og:url" content={WWW_URL + "/wyprawa/" + trip?.slug} />}
        {trip?.photo?.url && trip.photo.url.length > 0 && <meta property="og:image" content={trip?.photo.url} />}
        {trip?.ogTitle && trip.ogTitle.length > 0 && <meta name="twitter:title" content={trip?.ogTitle} />}
        {trip?.ogDescription && trip.ogDescription.length > 0 && (
          <meta name="twitter:description" content={trip?.ogDescription} />
        )}
        {trip?.photo?.url && trip.photo.url.length > 0 && <meta name="twitter:image" content={trip?.photo.url} />}

        {trip?.photo?.url && trip.photo.url.length > 0 && <meta name="twitter:card" content="summary_large_image" />}
      </MetaTags>

      {trip ? (
        <>
          <TripHeader trip={trip ?? ({} as ITrip)} />
          <PageSection className="mt-4">
            <div className="col-12">
              {trip?.skaleoButtonCode && (
                <div
                  className={styles.skaleoButtonInHeader}
                  dangerouslySetInnerHTML={{
                    __html: trip?.skaleoButtonCode,
                  }}
                ></div>
              )}
            </div>
          </PageSection>

          {trip?.description && (
            <PageSection>
              <div className="col-12">
                <Title className="text-left mb-4">Opis wyprawy</Title>
                <p
                  className={classnames(styles.tripPageDesc)}
                  dangerouslySetInnerHTML={{
                    __html: trip?.description,
                  }}
                ></p>
              </div>
            </PageSection>
          )}
          <TripAssets trip={trip ?? ({} as ITrip)} />

          {trip && (trip?.route?.length > 0 || trip?.routePoints?.length > 0) && (
            <PageSection>
              <div className="shangrilaDivider mb-4" />
            </PageSection>
          )}

          <TripRoute trip={trip ?? ({} as ITrip)} />

          <PageSection>
            <div className="col-12">
              {trip && (trip?.route?.length > 0 || trip?.routePoints?.length > 0) && (
                <div className="shangrilaDivider" />
              )}
              {trip &&
                (trip?.infoSections?.length > 0 ||
                  trip?.schedule?.length > 0 ||
                  trip?.priceExclude?.length > 0 ||
                  trip?.priceInclude?.length > 0) && (
                  <Title className={styles.tripSubtitle}>Pozostałe informacje</Title>
                )}
            </div>
          </PageSection>

          <TripProgram trip={trip ?? ({} as ITrip)} />

          <TripEstimate trip={trip ?? ({} as ITrip)} />

          <TripOtherInf trip={trip ?? ({} as ITrip)} />

          {trip && trip?.videos?.length > 0 && <TripVideo trip={trip ?? ({} as ITrip)} />}

          {trip?.infoSections && (
            <PageSection>
              <div className="col-12">
                <div className="shangrilaDivider my-0" />
              </div>
            </PageSection>
          )}

          <GallerySlider tripID={trip?.id} />

          {/*
          <-- div className={styles.tripPageContactForm}>
            <Contact
              header={contactData.goAhead}
              headerClass="text-center"
              actionText={contactData.actionText}
              onSubmit={() => alert("onSubmit")}
              initialValues={initialValues}
              showAdditionalInputs={true}
              nameTrip={trip?.name}
              participantsCount="1 osoba"
            />
          </div -->
*/}

          <PageSection className="mt-4">
            <div className="col-12">
              {trip?.skaleoButtonCode && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: trip?.skaleoButtonCode,
                  }}
                ></div>
              )}
            </div>
          </PageSection>

          <SimilarTrips trips={sortedAndFiltered} />
        </>
      ) : (
        <div id="first-global-spinner" className="spinner">
          <div></div>
        </div>
      )}
    </>
  );
};

export default TripPage;
