// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3JCeph99Xfud7FK7DNNxdd{width:32px;height:32px;border-radius:50%}._2EX6x2Dm-E_ZR4pAHsE1Jm{font-size:20px}._1bHpVmWUPYefJqJn1ebBFu{font-family:Montserrat;font-style:normal;font-weight:normal;font-size:18.8}._1URkwgyE1d04udDeA03kIT img{max-width:100%;height:auto}._1URkwgyE1d04udDeA03kIT iframe{max-width:100%}\n", ""]);
// Exports
exports.locals = {
	"authorImg": "_3JCeph99Xfud7FK7DNNxdd",
	"authorName": "_2EX6x2Dm-E_ZR4pAHsE1Jm",
	"postDate": "_1bHpVmWUPYefJqJn1ebBFu",
	"postContent": "_1URkwgyE1d04udDeA03kIT"
};
module.exports = exports;
