import * as React from "react";
import * as ReactDOM from "react-dom";

import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

import App from "./components/App";

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById("root")
);
