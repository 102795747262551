import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { formatData } from "../../../services/functions";
import styles from "./PostTile.module.scss";
import { ButtonTriangular } from "..";
import { ITilePost } from "../../pages/BlogPage/tilesPostsData";

interface IProps {
  post: ITilePost;
  className?: string;
}

const PostTile: React.FC<IProps> = ({ post, className }) => {
  return (
    <div className={classnames("card", styles.post, className)}>
      <div className="position-relative">
        <img className="card-img-top" src={post?.image?.thumbUrl} alt={post.title} />
        <div className={classnames("d-flex justify-content-between position-absolute w-100", styles.postInf)}>
          <span className={classnames("text-white align-self-center", styles.postDate)}>{formatData(post.date)}</span>
          <div className={classnames("text-white text-uppercase d-inline-block rounded", styles.tag)}>{post.tag}</div>
        </div>

        <h4 className={classnames("position-absolute text-uppercase text-white font-weight-bolder", styles.postTitle)}>
          {post.title}
        </h4>
      </div>
      <div className="card-body">
        <p className={classnames("text-muted font-weight-normal", styles.postDescription)}>{post.description}</p>

        <Link to={`/blog/${post.slug}`} className="d-flex justify-content-end">
          <ButtonTriangular className="font-weight-bolder btn-green">Czytaj więcej</ButtonTriangular>
        </Link>
      </div>
    </div>
  );
};

export default PostTile;
