// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2GrmzlNufL1wOeFXM7M85J{margin-top:1rem;margin-bottom:3.5rem}._19_t3gTtgC99hVR5U0HvbX{background-color:white;overflow:hidden;height:500px}._11vQ3J4ZBK3aj6XskeuhGC{width:40%}._2D3Dh0BRWXusNfSp9jzqsZ{width:60%}.-G0tw__V6usHIPC-65Ckx{overflow:hidden;object-fit:cover}._1beeuTMYccAqiVHN-3_z_1{background-color:rgba(43,173,86,0.509804);width:100%;height:100%}._1CJWwL7dOvA_acLIvLQMw1{flex:1}._2qoUbQZ3krM22v4x7a_Y31{color:white;font-size:45px}._7taIIxDbQoXZr_FXAfh53{height:100%}._2_I2I4cuskuBBQUepW8ENX{max-height:242px}@media only screen and (max-width: 1000px){._19_t3gTtgC99hVR5U0HvbX{min-height:850px;height:auto}._7taIIxDbQoXZr_FXAfh53{height:auto;max-height:100%}._11vQ3J4ZBK3aj6XskeuhGC{width:100%;margin-bottom:16px}._12ukNxERi_scK06HxS9A3s{width:100%}._2D3Dh0BRWXusNfSp9jzqsZ{width:100%;margin:0;padding:0}.-G0tw__V6usHIPC-65Ckx{padding-left:0 !important}._2_I2I4cuskuBBQUepW8ENX{max-height:320px}._1beeuTMYccAqiVHN-3_z_1{min-height:250px}.eD0OhVSf6ieC8wQZ_FZzn{height:100%}.u6a3T8VLUfrsIhF_9lnuO{width:100%;height:100%;margin:0 !important;padding:0 !important}._1CJWwL7dOvA_acLIvLQMw1>[class^=\"pl-\"]:first-of-type{padding-left:0 !important}}@media only screen and (max-width: 768px){._1CJWwL7dOvA_acLIvLQMw1{display:flex;flex-direction:column !important;width:100%}._1CJWwL7dOvA_acLIvLQMw1 [class^=\"pl-\"]{padding-left:0 !important}._2_I2I4cuskuBBQUepW8ENX{max-height:300px}._11vQ3J4ZBK3aj6XskeuhGC{margin-bottom:8px}._19_t3gTtgC99hVR5U0HvbX{min-height:700px;height:auto}.-G0tw__V6usHIPC-65Ckx{width:100%;padding:0 !important;padding-top:8px !important}}@media only screen and (max-width: 763px){.W-QWypwZm3ul7iPXGdqzL{padding-top:0 !important}._19_t3gTtgC99hVR5U0HvbX{min-height:650px;height:auto}}@media only screen and (max-width: 500px){._19_t3gTtgC99hVR5U0HvbX{min-height:500px;height:auto}}\n", ""]);
// Exports
exports.locals = {
	"galleryCongtainer": "_2GrmzlNufL1wOeFXM7M85J",
	"galleryPhoto": "_19_t3gTtgC99hVR5U0HvbX",
	"mainPhoto": "_11vQ3J4ZBK3aj6XskeuhGC",
	"nextPhotos": "_2D3Dh0BRWXusNfSp9jzqsZ",
	"image": "-G0tw__V6usHIPC-65Ckx",
	"noImage": "_1beeuTMYccAqiVHN-3_z_1",
	"photosRow": "_1CJWwL7dOvA_acLIvLQMw1",
	"iconNoImage": "_2qoUbQZ3krM22v4x7a_Y31",
	"imageVertical": "_7taIIxDbQoXZr_FXAfh53",
	"imageRow": "_2_I2I4cuskuBBQUepW8ENX",
	"mainPhotoA": "_12ukNxERi_scK06HxS9A3s",
	"noImageMain": "eD0OhVSf6ieC8wQZ_FZzn",
	"mainImageMobile": "u6a3T8VLUfrsIhF_9lnuO",
	"photosSecondRow": "W-QWypwZm3ul7iPXGdqzL"
};
module.exports = exports;
