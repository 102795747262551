// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1qhjbcsg2wQDCtdUQtehvg{margin-top:150px}._3oyPb7_kCJzxCsxwTZgWca{margin-bottom:3.375rem;font-family:Montserrat;font-size:1.125rem;line-height:1.8}\n", ""]);
// Exports
exports.locals = {
	"section": "_1qhjbcsg2wQDCtdUQtehvg",
	"tileTripTypeParagraph": "_3oyPb7_kCJzxCsxwTZgWca"
};
module.exports = exports;
