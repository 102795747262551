import React from "react";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";

import PageSection from "../../PageSection";
import { PostTile } from "../../common";

import { ITilePost } from "./tilesPostsData";
import styles from "./BlogPage.module.scss";

interface IListOfPosts {
  posts: ITilePost[];
  postsCount: number;
  fetchMore: () => void;
  hasMore: boolean;
}

const PostsList: React.FC<IListOfPosts> = ({ posts, fetchMore, postsCount, hasMore }) => {
  const [first, ...rest] = posts;

  return (
    <>
      {posts && postsCount > 0 && (
        <InfiniteScroll
          dataLength={postsCount}
          next={fetchMore}
          hasMore={hasMore}
          loader={
            <div
              className={classnames(
                "font-weight-bolder text-uppercase d-flex justify-content-center",
                "aligin-content-center justify-content-md-end col-12",
                styles.showMore
              )}
              onClick={fetchMore}
            >
              Pokaż starsze wpisy <span className="si si-chevron ml-2 mt-1"></span>
            </div>
          }
        >
          <PageSection className={styles.postsBlogPage}>
            <div className={classnames("col-md-6 d-flex d-md-none", styles.postBlogPage)}>
              <PostTile post={first} />
            </div>
            {rest?.map((postBlog: ITilePost, index: number) => (
              <div key={index} className={classnames("col-md-6", styles.postBlogPage)}>
                <PostTile key={index} post={postBlog} />
              </div>
            ))}
          </PageSection>
        </InfiniteScroll>
      )}
    </>
  );
};

export default PostsList;
