import React from "react";
import classnames from "classnames";

import PageSection from "../../../../PageSection";

import styles from "./SimilarTrip.module.scss";
import { TileTrip, Title } from "../../../../common";
import { ITile } from "../../../../common/Tile/tileData";

interface ISimilarTrip {
  trips: ITile[] | undefined;
}

const SimilarTrips: React.FC<ISimilarTrip> = ({ trips }) => {
  return (
    <PageSection fullWidth={true} className={classnames(styles.similarTripSection, "bg-lightblue")}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Title className={classnames("text-center mb-5")}>Podobne wyprawy</Title>

            <div className="row">
              {trips?.slice(0, 3).map((similarTrip: ITile, key: number) => (
                <div key={key} className="col-12 col-sm-12 col-md-6 col-lg-4">
                  <TileTrip tile={similarTrip} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default SimilarTrips;
