// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._27R4KVOhtLsVaIkH7vBsnB{padding-top:4.75rem;padding-bottom:4.75rem}\n", ""]);
// Exports
exports.locals = {
	"sectionFind": "_27R4KVOhtLsVaIkH7vBsnB"
};
module.exports = exports;
