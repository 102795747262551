import React, { useEffect, useState, useMemo } from "react";

import { useBootstrapBreakpoints } from "./useBootstrapBreakpoints";

interface useSliderProps<T> {
  sliderContainer: React.MutableRefObject<HTMLDivElement | null>;
  elements: T[];
}

interface sliderInterface {
  moveLeft: () => void;
  moveRight: () => void;
  containerWidth: number;
  elemWidth: number;
  sliderPosition: number;
  isRightButtonDisabled: boolean;
  isLeftButtonDisabled: boolean;
}

export function useSlider<T>(props: useSliderProps<T>): sliderInterface {
  const { sliderContainer, elements } = props;
  const [containerWidth, setContainerWidth] = useState(0);
  const [elemWidth, setElemWidth] = useState(0);
  const [sliderPosition, setSliderPosition] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const bootstrapBreakpoint = useBootstrapBreakpoints(windowWidth);

  const isRightButtonDisabled = useMemo(
    () =>
      (function getRightButtonDesiredState(sliderPosition: number): boolean {
        const onDesktop = bootstrapBreakpoint === "lg" || bootstrapBreakpoint === "xl";
        const onTablet = bootstrapBreakpoint === "md";

        return (
          (onDesktop && sliderPosition + 3 === elements.length) ||
          (onTablet && sliderPosition + 2 === elements.length) ||
          sliderPosition + 1 === elements.length
        );
      })(sliderPosition),
    [sliderPosition, bootstrapBreakpoint, elements.length]
  );

  const isLeftButtonDisabled = useMemo(
    () =>
      (function getLeftButtonDesiredState(sliderPosition: number): boolean {
        return sliderPosition === 0;
      })(sliderPosition),
    [sliderPosition]
  );

  function onWindowResize(): void {
    const sliderWidth = sliderContainer.current?.offsetWidth;
    if (!sliderWidth) {
      return;
    }

    if (bootstrapBreakpoint === "lg" || bootstrapBreakpoint === "xl") {
      setElemWidth(sliderWidth / 3);
      setContainerWidth((sliderWidth / 3) * elements.length);
    } else if (bootstrapBreakpoint === "md") {
      setElemWidth(sliderWidth / 2);
      setContainerWidth((sliderWidth / 2) * elements.length);
    } else {
      setElemWidth(sliderWidth);
      setContainerWidth(sliderWidth * elements.length);
    }
  }

  useEffect(() => {
    //TODO: debounce it with lodash
    const callback = (): void => setWindowWidth(getWindowWidth());
    window.addEventListener("resize", callback);
    return (): void => window.removeEventListener("resize", callback);
  }, []);

  useEffect(() => {
    onWindowResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, elements.length]);

  function moveLeft(): void {
    setSliderPosition((position) => position - 1);
  }

  function moveRight(): void {
    setSliderPosition((position) => position + 1);
  }

  function getWindowWidth(): number {
    return window.innerWidth;
  }

  return {
    moveLeft,
    moveRight,
    containerWidth,
    elemWidth,
    sliderPosition,
    isRightButtonDisabled,
    isLeftButtonDisabled,
  };
}
