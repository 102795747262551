import React from "react";
import Select, { components, IndicatorProps, SingleValueProps } from "react-select";
import { SelectComponentsProps } from "react-select/src/Select";

import CaretDownIcon from "./SelectIconDropdown";

import "./Inputs.scss";
import { IOption } from "./input.models";
interface IProps extends SelectComponentsProps {
  label: string;
  inputId: string;
  options?: IOption[];
  isInputMultiple?: boolean;
  containerClassName: string;
  disabled?: boolean;
}

// eslint-disable-next-line
const MultiValue = (props: SingleValueProps<any>): JSX.Element => {
  return (
    <components.SingleValue {...props}>
      <span>{"Zaznaczono " + props.selectProps.value?.length + " opcje"}</span>
    </components.SingleValue>
  );
};

const DropdownIndicator = (props: IndicatorProps<IOption, boolean>): JSX.Element => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon iconClassName="inputDropdownIcon" />
    </components.DropdownIndicator>
  );
};

const SelectInput: React.FC<IProps> = ({
  label,
  options,
  containerClassName,
  inputId,
  placeholder,
  isInputMultiple,
  disabled,
  ...props
}) => {
  return (
    <div className={containerClassName} {...props}>
      <label htmlFor={inputId} className="input-label font-weight-bold">
        {label}
      </label>
      <Select
        isDisabled={disabled}
        closeMenuOnSelect={!isInputMultiple}
        isMulti={isInputMultiple}
        placeholder={placeholder}
        options={options}
        classNamePrefix="react-select-custom"
        className="react-select-container"
        id={inputId}
        components={{ DropdownIndicator, MultiValue: MultiValue }}
        hideSelectedOptions={false}
        {...props}
      />
    </div>
  );
};

export default SelectInput;
