import React from "react";
import classnames from "classnames";

import "./Inputs.scss";

interface IProps {
  label: string;
  icon: string;
  inputId: string;
  iconClassName?: string;
  containerClassName: string;
  onChange: React.EventHandler<React.ChangeEvent<HTMLTextAreaElement>>;
  value: string;
}

const TextArea: React.FC<IProps> = ({ label, icon, inputId, iconClassName, containerClassName, ...props }) => {
  return (
    <div className={containerClassName}>
      <span
        className={classnames(
          `si si-${icon}`,
          "d-flex justify-content-center align-items-start inputIcon",
          iconClassName
        )}
      />

      <div className="form-group inputFieldSize">
        <label htmlFor={inputId} className="input-label font-weight-bold">
          {label}
        </label>
        <textarea className="form-control" rows={10} id={inputId} {...props} />
      </div>
    </div>
  );
};

export default TextArea;
