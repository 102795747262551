import React from "react";
import classnames from "classnames";
import { Map, TileLayer, Marker } from "react-leaflet";

import PageSection from "../../../../PageSection";

import styles from "./TripRoute.module.scss";
import { ITrip } from "../../tripData";
import { RoutePoint } from "../../../../../API/types";

interface ITextWithMap {
  trip: ITrip;
}

const TripRoute: React.FC<ITextWithMap> = ({ trip }) => {
  // eslint-disable-next-line
  const mapCenter = (): any => {
    const map = trip.routePoints.map((routePoint) => [routePoint.lat, routePoint.long]);
    return map;
  };

  return (
    <>
      {(trip?.routePoints?.length || trip?.route) > 0 && (
        <PageSection>
          <div className="col">
            <div className="row d-flex justify-content-between">
              <div className="col-sm-12 col-md-6 col-lg-5">
                <h2 className={classnames("font-weight-bold mb-3")}>Trasa</h2>
                <p className={styles.textWithMapDesc}>{trip?.route}</p>
              </div>

              <div
                className={classnames(
                  styles.textWithMap,
                  "col-sm-12 col-md-6 col-lg-6 d-flex justify-content-lg-end justify-content-md-center"
                )}
              >
                {!!trip?.routePoints && (
                  <Map zoom={7} bounds={mapCenter()} className={classnames(styles.textWithMapZoom)}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                    {trip.routePoints.map((routePoint: RoutePoint, key: number) => (
                      <div key={key}>
                        <Marker position={[routePoint.lat, routePoint.long]} />
                      </div>
                    ))}
                  </Map>
                )}
              </div>
            </div>
          </div>
        </PageSection>
      )}
    </>
  );
};

export default TripRoute;
