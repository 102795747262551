import React from "react";
import classnames from "classnames";

interface IProps {
  iconClassName?: string;
}

const CaretDownIcon: React.FC<IProps> = ({ iconClassName }): JSX.Element => {
  return <span className={classnames("si si-arrow-input", iconClassName)} />;
};

export default CaretDownIcon;
