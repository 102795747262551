import React from "react";
import { useLocation } from "react-router-dom";

import analytics from "./analytics";

// eslint-disable-next-line
export default function useGoogleAnalytics() {
  const location = useLocation();

  React.useEffect(() => {
    analytics.init();
  }, []);

  React.useEffect(() => {
    const currentPath = window.location.pathname;
    analytics.sendPageview(currentPath);
  }, [location]);
}
