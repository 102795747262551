// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Kq4C5hPJ3gd6ojumC1gGi{font-size:0.875rem;letter-spacing:0.035em;color:#393939}._28Ty8RZI5QAf5vHWLMsKpa{margin-top:7rem}@media only screen and (max-width: 600px){._28Ty8RZI5QAf5vHWLMsKpa{margin-top:10rem}}\n", ""]);
// Exports
exports.locals = {
	"searchCounter": "Kq4C5hPJ3gd6ojumC1gGi",
	"searchInput": "_28Ty8RZI5QAf5vHWLMsKpa"
};
module.exports = exports;
