// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2aEKJcFHdRQwjpbUG3D15u{margin:50px 0px;box-shadow:10px 10px 35px rgba(116,116,116,0.08);border-radius:10px}._2aEKJcFHdRQwjpbUG3D15u ._1WRc4olxTPiTG2TvRuJpDm{font-size:7.25rem;width:120px}._2aEKJcFHdRQwjpbUG3D15u h4{height:3.75rem;font-size:1rem}\n", ""]);
// Exports
exports.locals = {
	"reason": "_2aEKJcFHdRQwjpbUG3D15u",
	"image": "_1WRc4olxTPiTG2TvRuJpDm"
};
module.exports = exports;
