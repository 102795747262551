import React from "react";
import classnames from "classnames";

import PageSection from "../../../PageSection";
import { TitleHeader } from "../../../common";

import styles from "./ContactHeader.module.scss";
import { Page } from "../../../../API/types";

export interface IHeaderContact {
  contact: Page;
}

const ContactHeader: React.FC<IHeaderContact> = ({ contact }) => {
  return (
    <div className={styles.contactHeader}>
      <PageSection>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 align-content-center">
          <TitleHeader classNameTitle="text-dark mb-5">
            {contact?.title && (
              <span
                dangerouslySetInnerHTML={{
                  __html: contact?.title,
                }}
              ></span>
            )}
          </TitleHeader>
          {contact?.content && (
            <p
              className={classnames("text-dark", styles.contactHeaderParagraph)}
              dangerouslySetInnerHTML={{
                __html: contact?.content,
              }}
            ></p>
          )}
        </div>
      </PageSection>
    </div>
  );
};

export default ContactHeader;
