import React from "react";
import MetaTags from "react-meta-tags";

import { WWW_URL } from "appConfig";

import useHomeData from "../../../services/dataHooks/useHomeData";
import useApiPageData from "../../../services/dataHooks/useApiPageData";

import usePageGallery from "../../../services/dataHooks/usePageGallery";

import PageSection from "../../PageSection";

import { contactData } from "../../../content";
import { initialValues } from "../../common/Contact/ContactInitialValue";

import { Title, Contact, NextExpeditions } from "../../common";
import { AboutHeader, OurTeam } from "./";

import AboutGallery from "./AboutGallery/AboutGallery";

import { Page } from "../../../API/types";

const About: React.FC = () => {
  const { trips } = useHomeData();
  const tripsByDate = trips?.sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate));

  const { data: about } = useApiPageData("o-nas");
  const { gallery } = usePageGallery(about?.id);

  return (
    <>
      <MetaTags>
        <link rel="canonical" href={WWW_URL + "/o-nas"} />

        <title>{about?.title} - Shangrila Travel</title>
        {about?.metaDescription && about.metaDescription.length > 0 && (
          <meta name="description" content={about?.metaDescription} />
        )}
        {about?.metaKeywords && about.metaKeywords.length > 0 && <meta name="keywords" content={about?.metaKeywords} />}
        {about?.ogTitle && about.ogTitle.length > 0 && <meta property="og:title" content={about?.ogTitle} />}
        {about?.ogDescription && about.ogDescription.length > 0 && (
          <meta property="og:description" content={about?.ogDescription} />
        )}
        <meta property="og:locale" content="pl_PL" />
        {about?.mainImage && about?.mainImage?.url.length > 0 && (
          <meta property="og:image" content={about?.mainImage.url} />
        )}
        {about?.ogTitle && about.ogTitle.length > 0 && <meta name="twitter:title" content={about?.ogTitle} />}
        {about?.ogDescription && about.ogDescription.length > 0 && (
          <meta name="twitter:description" content={about?.ogDescription} />
        )}
        {about?.mainImage?.url && about?.mainImage?.url?.length > 0 && (
          <meta name="twitter:image" content={about?.mainImage.url} />
        )}

        {about?.mainImage?.url && about?.mainImage?.url?.length > 0 && (
          <meta name="twitter:card" content="summary_large_image" />
        )}
      </MetaTags>

      <AboutHeader about={about ?? ({} as Page)} />
      <PageSection>
        <div className="col-12">
          <Title className="text-center mb-4">Nasza Ekipa</Title>
        </div>
      </PageSection>

      <OurTeam />

      <PageSection>
        <div className="col-12 pb-5">
          <div className="shangrilaDivider mb-4" />
          <p className="py-5 text-center mb-0">
            <span className="text text-uppercase font-weight-bold">Shangrila Travel</span> posiada zezwolenie na 
            organizację imprez turystycznych o numerze 1701 wydane przez Marszałka Województwa Śląskiego oraz 
            certyfikat ubezpieczyciela Signal Iduna o numerze M 524519.
          </p>
          <div className="shangrilaDivider mt-4 mb-5" />
        </div>
      </PageSection>

      {/* <GallerySlider tripID={gallery}/> */}
      <AboutGallery photos={gallery} />

      <div className="pb-5">
        <Contact
          header={contactData.goAhead}
          headerClass="text-center"
          actionText={contactData.actionText}
          onSubmit={() => alert("onSubmit")}
          initialValues={initialValues}
          showAdditionalInputs={false}
        />
      </div>

      {tripsByDate && <NextExpeditions trips={tripsByDate} />}
    </>
  );
};

export default About;
