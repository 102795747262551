import React, { useCallback, useEffect, useState } from "react";
import { useSearchParam } from "react-use";
import classnames from "classnames";
import ReactGA from "react-ga";

import PageSection from "../../../PageSection";
import { SelectInput, ButtonIcon } from "../../../common";

import styles from "./FilterTripsContainer.module.scss";
import { tripsTypeOptions, tripsLengthOptions, TripDuration, tripsKindOptions } from "../../../common/Inputs/inputData";
import { IOption } from "../../../common/Inputs/input.models";
import { FilterFormTypes, IDestination, IFilter } from "../../../../models";
import { getTripDirection } from "../../../../services/tripService";
interface IFilterForm {
  tripsDirection: IOption | null;
  tripsType: IOption | IOption[] | null;
  tripsKind: IOption | IOption[] | null;
  tripsLength: IOption | null;
  tripsDifficulty: IOption | null;
}

interface IFilterTripsContainer {
  handleFilters: (filters: IFilter[]) => void;
}

const FilterTripsContainer: React.FC<IFilterTripsContainer> = ({ handleFilters }) => {
  const emptyInputValues: IFilterForm = {
    tripsDirection: null,
    tripsType: null,
    tripsKind: null,
    tripsLength: null,
    tripsDifficulty: null,
  };

  const typeTrip = useSearchParam("type");
  const kindTrip = useSearchParam("kind");

  const [filterDirection, setFilterDirection] = useState<IFilter[]>([]);
  const [filterType, setFilterType] = useState<IFilter[]>([]);
  const [filterKind, setFilterKind] = useState<IFilter[]>([]);
  const [filterDuration, setFilterDuration] = useState<IFilter[]>([]);
  const [inputValues, setInputValues] = useState<IFilterForm>(emptyInputValues);
  const [showResetBtn, setShowResetBtn] = useState(true);
  const [destinations, setDestinations] = useState<IOption[]>([]);

  const updateInputValue = (filterValue: IOption, type: FilterFormTypes): void => {
    setShowResetBtn(true);

    switch (type) {
      case FilterFormTypes.type: {
        const tempValue: string = ((filterValue as unknown) as IOption[])
          .map((filter: IOption) => filter.value)
          .reduce((acu, value) => `${acu}|${value}`, "")
          .split("|")
          .filter(Boolean)
          .join("|");

        const temp: IFilter = {
          param: FilterFormTypes.type,
          filterMode: "in",
          value: tempValue,
        };

        setInputValues({ ...inputValues, tripsType: filterValue });
        setFilterType([temp]);
        ReactGA.event({
          category: "Trip",
          action: "Tour search engine",
          label: `Selected filter type: ` + tempValue,
        });
        break;
      }
      case FilterFormTypes.kinds: {
        const tempValue: string = ((filterValue as unknown) as IOption[])
          .map((filter: IOption) => filter.value)
          .reduce((acu, value) => `${acu}|${value}`, "")
          .split("|")
          .filter(Boolean)
          .join("|");

        const temp: IFilter = {
          param: FilterFormTypes.kinds,
          filterMode: "in",
          value: tempValue,
        };

        setInputValues({ ...inputValues, tripsKind: filterValue });
        setFilterKind([temp]);
        ReactGA.event({
          category: "Trip",
          action: "Tour search engine",
          label: `Selected filer kind: ` + tempValue,
        });
        break;
      }
      case FilterFormTypes.destination:
        setInputValues({ ...inputValues, tripsDirection: filterValue });
        setFilterDirection([{ param: FilterFormTypes.destination, filterMode: "phrase", value: filterValue.value }]);
        ReactGA.event({
          category: "Trip",
          action: "Tour search engine",
          label: `Selected filter destination: ${filterValue.label}`,
        });
        break;
      case FilterFormTypes.length:
        switch (filterValue.value) {
          case TripDuration.first:
            setFilterDuration([{ param: FilterFormTypes.length, filterMode: "lte", value: 8 }]);
            ReactGA.event({
              category: "Trip",
              action: "Tour search engine",
              label: `Selected filter duration: < 8`,
            });
            break;
          case TripDuration.second:
            setFilterDuration([
              { param: FilterFormTypes.length, filterMode: "gte", value: 8 },
              { param: FilterFormTypes.length, filterMode: "lte", value: 15 },
            ]);
            ReactGA.event({
              category: "Trip",
              action: "Tour search engine",
              label: `Selected filter duration: 8 - 15`,
            });
            break;
          case TripDuration.third:
            setFilterDuration([
              { param: FilterFormTypes.length, filterMode: "gte", value: 15 },
              { param: FilterFormTypes.length, filterMode: "lte", value: 22 },
            ]);
            ReactGA.event({
              category: "Trip",
              action: "Tour search engine",
              label: `Selected filter duration: 15 - 22`,
            });
            break;
          case TripDuration.forth:
            setFilterDuration([{ param: FilterFormTypes.length, filterMode: "gte", value: 22 }]);
            ReactGA.event({
              category: "Trip",
              action: "Tour search engine",
              label: `Selected filter duration: > 22`,
            });
            break;
        }
        setInputValues({ ...inputValues, tripsLength: filterValue });
        break;
      default:
        break;
    }
  };

  const checkTripType = (): void => {
    typeTrip && setInputValues({ ...inputValues, tripsType: [{ label: typeTrip, value: typeTrip }] });
  };

  const checkTripKind = (): void => {
    kindTrip && setInputValues({ ...inputValues, tripsKind: [{ label: kindTrip, value: kindTrip }] });
  };

  const handleTypeFilter = useCallback(() => {
    checkTripType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeTrip]);

  const handleKindFilter = useCallback(() => {
    checkTripKind();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kindTrip]);

  useEffect(() => {
    handleTypeFilter();
  }, [handleTypeFilter]);

  useEffect(() => {
    handleKindFilter();
  }, [handleKindFilter]);

  useEffect(() => {
    getTripDirection().then((response: IDestination[]) =>
      setDestinations(response.map((item: IDestination) => ({ value: item.name, label: item.name })))
    );
  }, []);

  const resetInputValue = (): void => {
    checkTripType();
    checkTripKind();
    handleFilters([]);
    setShowResetBtn(false);
    setFilterType([]);
    setFilterKind([]);
    setFilterDuration([]);
    setFilterDirection([]);
    setInputValues(emptyInputValues);
  };

  return (
    <PageSection>
      <div className="col-12 d-flex justify-content-center align-content-center">
        <>
          <div
            className={classnames(
              "bg-white col-12 col-sm-12 col-md-10 col-lg-10 pt-5 py-4 d-flex flex-column justify-content-between",
              styles.filterContainer
            )}
          >
            {/*
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-4">
                <SelectInput
                  isInputMultiple
                  label="Typ wyprawy"
                  options={tripsTypeOptions}
                  inputId="tripTypeId"
                  placeholder="Wybierz"
                  containerClassName="form-group"
                  onChange={(value: IOption) => updateInputValue(value, FilterFormTypes.type)}
                  value={inputValues.tripsType}
                />
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-4">
                <SelectInput
                  label="Długość wyprawy"
                  options={tripsLengthOptions}
                  inputId="tripLengthId"
                  containerClassName="form-group"
                  placeholder="Wybierz"
                  onChange={(value: IOption) => updateInputValue(value, FilterFormTypes.length)}
                  value={inputValues.tripsLength}
                />
              </div>
            </div>
		  */}

            {/* inputs that should be invisible for now
             <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-4">
                <SelectInput
                  isInputMultiple
                  label="Rodzaj wyprawy"
                  options={tripsKindOptions}
                  inputId="tripKindId"
                  placeholder="Wybierz"
                  containerClassName="form-group"
                  onChange={(value: IOption) => updateInputValue(value, FilterFormTypes.kind)}
                  value={inputValues.tripsKind}
                />
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-4">
                <SelectInput
                  isInputMultiple
                  label="Poziom trudności"
                  options={tripsLevelOfDifficulty}
                  inputId="tripLevelId"
                  placeholder="Wybierz"
                  containerClassName="form-group"
                  onChange={(value: IOption) => updateInputValue(value, FilterFormTypes.difficulty)}
                  value={inputValues.tripsDifficulty}
                />
              </div>
            </div>*/}

            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-5">
                <SelectInput
                  label="Kierunek wyprawy"
                  options={destinations}
                  inputId="tripDirectionId"
                  placeholder="Wybierz"
                  containerClassName="form-group"
                  onChange={(value: IOption) => updateInputValue(value, FilterFormTypes.destination)}
                  value={inputValues.tripsDirection}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-3">
                {showResetBtn ? (
                  <ButtonIcon
                    icon={"close"}
                    iconSize={20}
                    className={classnames(styles.filterButton, "btn-white font-weight-bolder")}
                    onClick={resetInputValue}
                  >
                    Wyczyść filtry
                  </ButtonIcon>
                ) : null}
              </div>

              <div className="col-sm-12 col-md-6 col-lg-3">
                <ButtonIcon
                  icon={"search"}
                  iconSize={20}
                  className={classnames(styles.filterButton, "btn-green font-weight-bolder")}
                  onClick={() => handleFilters([...filterDirection, ...filterKind, ...filterType, ...filterDuration])}
                >
                  Znajdź wyprawę
                </ButtonIcon>
              </div>
            </div>
          </div>
        </>
      </div>
    </PageSection>
  );
};

export default FilterTripsContainer;
