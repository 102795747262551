import React from "react";

import { PhotoGallery } from "../../../common";
import { IPhoto } from "../../../common/Gallery/Photo";

export interface IPrivacyPolicyGallery {
  photos: IPhoto[] | null;
}

const ApiPageGallery: React.FC<IPrivacyPolicyGallery> = ({ photos }) => {
  return <>{photos && photos.length > 0 && <PhotoGallery photos={photos} />}</>;
};

export default ApiPageGallery;
