import React from "react";

import styles from "./Hero.module.scss";
interface IProps {
  heroVideo: string;
}

const HeroVideo: React.FC<IProps> = ({ heroVideo, children }) => {
  return (
    <>
      <div className={styles.videoBackground}>
        <video muted loop autoPlay className={styles.heroVideo}>
          <source src={heroVideo} type="video/mp4"></source>
        </video>
      </div>
      {children}
    </>
  );
};

export default HeroVideo;
