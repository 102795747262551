import React from "react";

import { FaqContent } from "../FaqData";
import { FaqTab } from ".";
import { useSearchParam } from "react-use";

const FaqContentTab: React.FC = () => {
  const faqSection = useSearchParam("faqSection");
  return (
    <div>
      {FaqContent.tabs.map((FaqContentElement, key: number) => (
        <FaqTab data={FaqContentElement} key={key} active={faqSection as string} />
      ))}
    </div>
  );
};

export default FaqContentTab;
