import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import ReactGA from "react-ga";

import PageSection from "../../PageSection";
import { Button, SelectInputIcon, TextArea, TextInput } from "../../common";

import styles from "./Contact.module.scss";
import { tripNumberOfPeople } from "../Inputs/inputData";
import { IOption, IOptionNumber } from "../Inputs/input.models";
import { initialValues, IContactValues } from "./ContactInitialValue";
import { contactFormMessage, sendMessageMail } from "../../../services/contactForm";
import { ContactFieldsTypes } from "../../../models";

interface IProps {
  header: string;
  headerClass: string;
  actionText?: string;
  description?: string;
  onSubmit: () => void;
  initialValues: IContactValues;
  showAdditionalInputs: boolean;
  nameTrip?: string;
  participantsCount?: string;
}

const Contact: React.FC<IProps> = ({
  header,
  headerClass,
  description,
  actionText,
  showAdditionalInputs,
  nameTrip,
  participantsCount,
  ...props
}) => {
  const [showSuccessInf, setShowSuccessInf] = useState(false);
  // eslint-disable-next-line
  const handleSentMeasurement = () => {
    ReactGA.event({
      category: "E-mail",
      action: "E-mail sent",
      label: "E-mail sent",
    });
  };

  const MessageSchema = Yup.object().shape({
    phoneId: Yup.string().required("Required"),
    messageId: Yup.string().required("Required"),
    emailId: Yup.string().email("Invalid email").required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: MessageSchema,

    onSubmit: (values) => {
      handleSentMeasurement();

      sendMessageMail(contactFormMessage(values));
      setShowSuccessInf(true);
      formik.resetForm();

      setTimeout(() => {
        setShowSuccessInf(false);
      }, 3000);
    },
  });

  useEffect(() => {
    nameTrip && formik.setFieldValue(ContactFieldsTypes.direction, nameTrip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameTrip]);

  return (
    <PageSection {...props}>
    </PageSection>
  );


};

export default Contact;

Contact.defaultProps = {
  showAdditionalInputs: false,
};
