// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._36Mz4OneWSTPJ1ZYYDZVF{font-size:2.9rem}\n", ""]);
// Exports
exports.locals = {
	"tripAssetsIcon": "_36Mz4OneWSTPJ1ZYYDZVF"
};
module.exports = exports;
