import React from "react";
import classnames from "classnames";
import useTeamData from "../../../../services/dataHooks/useTeamData";

import PageSection from "../../../PageSection";

import styles from "./OurTeam.module.scss";
import { OurTeamElement } from "./OurTeamElement";

const OurTeam: React.FC = () => {
  const { team } = useTeamData();

  return (
    <PageSection className={styles.ourTeam}>
      <div className="col-12">
        {team?.map((person, key: number) => {
          return (
            <div className={classnames(styles.elementDirectionRow, "col-12")} key={key}>
              <OurTeamElement isReverse={key % 2 === 0} person={person} />
            </div>
          );
        })}
      </div>
    </PageSection>
  );
};

export default OurTeam;
