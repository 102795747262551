export interface IContactValues {
  phoneId: string;
  emailId: string;
  messageId: string;
  directionId: string;
  quantityId: string;
}

export const initialValues: IContactValues = {
  phoneId: "",
  emailId: "",
  messageId: "",
  directionId: "",
  quantityId: "",
};
