import React from "react";

import PageSection from "../../../PageSection";
import { TitleHeader } from "../../../common";

import styles from "./SearchHeader.module.scss";

const SearchHeader: React.FC = () => {
  return (
    <div className={styles.searchHeader}>
      <PageSection>
        <TitleHeader classNameTitle="text-white col-12 col-md-10 col-lg-6">Znajdź swoją idealną wyprawę!</TitleHeader>
      </PageSection>
    </div>
  );
};

export default SearchHeader;
