import React from "react";
import { Link } from "react-router-dom";

import { Title, Subtitle, ButtonTriangular } from "../../../../common";

interface SectionTitleProps {
  title: string;
  link?: string;
  buttonLabel?: string;
  subtitle?: string;
}

const SectionTitle: React.FC<SectionTitleProps> = (props) => {
  return (
    <div className="d-flex flex-row justify-content-between">
      <div>
        <Subtitle>{props.subtitle}</Subtitle>
        <Title>{props.title}</Title>
      </div>
      {props.buttonLabel && (
        <Link to={props.link ?? "/"} className="text-decoration-none text-nowrap align-self-center pl-2">
          <ButtonTriangular className="font-weight-bolder btn-green">{props.buttonLabel}</ButtonTriangular>
        </Link>
      )}
    </div>
  );
};

export default SectionTitle;
