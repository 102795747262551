export enum SortBy {
  basePriceAsc = "basePriceAsc",
  basePriceDesc = "basePriceDesc",
  dateStartAsc = "dateStartAsc",
  dateStartDesc = "dateStartDesc",
  nameAsc = "nameAsc",
  nameDesc = "nameDesc",
  durationAsc = "durationAsc",
  durationDesc = "durationDesc",
}

export interface ISortOptions {
  value: SortBy;
  label: string;
}

export const searchSortOptions: ISortOptions[] = [
  {
    value: SortBy.dateStartDesc,
    label: "terminarza malejąco",
  },
  {
    value: SortBy.dateStartAsc,
    label: "terminarza rosnąco",
  },
  {
    value: SortBy.basePriceDesc,
    label: "ceny malejąco",
  },
  {
    value: SortBy.basePriceAsc,
    label: "ceny rosnąco",
  },
  {
    value: SortBy.nameDesc,
    label: "nazwy malejąco",
  },
  {
    value: SortBy.nameAsc,
    label: "nazwy rosnąco",
  },
  {
    value: SortBy.durationDesc,
    label: "czasu trwania malejąco",
  },
  {
    value: SortBy.durationAsc,
    label: "czasu trwania rosnąco",
  },
];
