// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Qu5JfELGh_Sste4Q6nQef{padding-top:3.75rem;padding-bottom:6.375rem}._1Xr0U_eFX54INkC_hAG8KU{font-family:Montserrat;font-size:1.125rem;margin-bottom:55px}._3rtS_ZX7EJ2aNbqMlIK3Pl{font-size:2rem}._2p0sNVxf5cc5Tt0KjuRMwf{margin-top:4.625rem}._2aGETbDYNTdb98HKjOoFcB{font-family:Montserrat;font-size:1.125rem;line-height:1.8}._100XhIJWqldLrSEbNNk17N{line-height:2.4;background-color:#f7f7f7}._3OCMvoyjUNLj-tl3gSHnoE{font-size:10px}\n", ""]);
// Exports
exports.locals = {
	"FaqHeader": "_3Qu5JfELGh_Sste4Q6nQef",
	"FaqHeaderParagraph": "_1Xr0U_eFX54INkC_hAG8KU",
	"FaqHeaderSpan": "_3rtS_ZX7EJ2aNbqMlIK3Pl",
	"FaqTab": "_2p0sNVxf5cc5Tt0KjuRMwf",
	"FaqTabHeader": "_2aGETbDYNTdb98HKjOoFcB",
	"FaqTabDesc": "_100XhIJWqldLrSEbNNk17N",
	"FaqTabIcon": "_3OCMvoyjUNLj-tl3gSHnoE"
};
module.exports = exports;
