import React from "react";

import { PhotoGallery } from "../../../common";
import { IPhoto } from "../../../common/Gallery/Photo";

export interface IAboutGallery {
  photos: IPhoto[] | null;
}

const AboutGallery: React.FC<IAboutGallery> = ({ photos }) => {
  return <>{photos && photos.length > 0 && <PhotoGallery photos={photos} />}</>;
};

export default AboutGallery;
