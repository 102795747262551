import { IContactValues } from "../components/common/Contact/ContactInitialValue";
import { dataService } from "./data.service";
export interface IContactFormData {
  email: string;
  phone: string;
  info: string;
  participantsCount: string;
  destination: string;
}

function contactFormMessage(values: IContactValues): IContactFormData {
  const dataMail: IContactFormData = {
    email: values.emailId,
    phone: values.phoneId,
    info: values.messageId,
    participantsCount: values.quantityId,
    destination: values.directionId,
  };
  return dataMail;
}

// eslint-disable-next-line
async function sendMessageMail(dataMail: IContactFormData) {
  // eslint-disable-next-line
  await dataService.postContactForm(dataMail);
}

export { sendMessageMail, contactFormMessage };
