import React from "react";
import classnames from "classnames";

interface IProps {
  className?: string;
  center?: boolean;
}

const Title: React.FC<IProps> = ({ className, children, center }) => {
  return (
    <h2 className={classnames("font-weight-bold text-uppercase", className, { "text-center": center })}>{children}</h2>
  );
};

export default Title;
