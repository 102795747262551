import React from "react";
import classnames from "classnames";

import styles from "./OurTeamElement.module.scss";
import { ITeam } from "../../AboutData";

interface IOurTeamElementProps {
  person: ITeam;
  isReverse: boolean;
}

const OurTeamElement: React.FC<IOurTeamElementProps> = ({ isReverse, person }) => {
  return (
    <div className="row">
      <div className={classnames(styles.elementOurTeamLine, "shangrilaDivider")} />

      <div className={classnames("d-flex row", { "flex-row-reverse": isReverse }, styles.elementOurTeam)}>
        <div className={classnames("col-sm-12 col-md-12 col-lg-6")}>
          <div
            className={styles.elementOurTeamImage}
            dangerouslySetInnerHTML={{
              __html: `<div style="
                background-image: url(${person?.photo?.url})" class=${styles.elementOurTeamPhoto}></div>`,
            }}
          ></div>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-6">
          {person?.name && (
            <h4
              className={classnames("font-weight-bolder text-uppercase mb-4 mt-5", styles.elementOurTeamName)}
              dangerouslySetInnerHTML={{
                __html: person?.name,
              }}
            ></h4>
          )}
          {person?.description && (
            <p
              className={classnames("font-weight-medium", styles.elementOurTeamCharacteristic)}
              dangerouslySetInnerHTML={{
                __html: person?.description,
              }}
            ></p>
          )}
        </div>
      </div>
    </div>
  );
};

export default OurTeamElement;
