import React from "react";
import classnames from "classnames";

import styles from "./FaqComponents.module.scss";
import PageSection from "../../../PageSection";
import { TitleHeader } from "../../../common";
import { FaqContent } from "../FaqData";
import { faqImg } from "../../../../assets/images";

const FaqHeader: React.FC = () => {
  return (
    <div className={styles.FaqHeader}>
      <PageSection>
        <div className="col-12 col-sm-12 col-md-7 col-lg-7 align-content-center">
          <TitleHeader classNameTitle="text-dark mb-5">FAQ.</TitleHeader>
          <p className={classnames("text-dark", styles.FaqHeaderParagraph)}>{FaqContent.subheader}</p>
        </div>

        <div className="col-sm-12 col-md-5 col-lg-5 d-flex justify-content-center align-content-center h-100">
          <img src={faqImg} />
        </div>
      </PageSection>
    </div>
  );
};

export default FaqHeader;
