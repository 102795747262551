import React, { useState } from "react";
import classnames from "classnames";

import { HideButton } from "../../common";
import { TripEstimateIcon } from "../..";
import PageSection from "../../../../PageSection";

import styles from "./TripEstimate.module.scss";
import "../../common/tripSectionExpand.scss";
import { ITrip } from "../../tripData";

interface IEstimateTrip {
  trip: ITrip;
}

const TripEstimate: React.FC<IEstimateTrip> = ({ trip }) => {
  const [btnText, setBtnText] = useState(false);
  const [btnIcon, setBtnIcon] = useState(false);
  const [fullContent, setFullContent] = useState(true);

  function handleTogglePoints(): void {
    showFullContent();
    changeBtnText();
    changeBtnIcon();
  }

  const changeBtnText = (): void => setBtnText(!btnText);
  const changeBtnIcon = (): void => setBtnIcon(!btnIcon);
  const showFullContent = (): void => setFullContent(!fullContent);

  return (
    <>
      <PageSection>
        <div className="col-12">
          {(trip?.priceExclude?.length > 0 || trip?.priceInclude?.length > 0) && (
            <h4 className="text-uppercase font-weight-bolder mb-5 pt-4">Cena i zawartość</h4>
          )}

          <div className="row d-flex justify-content-center align-content-center">
            {trip?.priceInclude?.length > 0 && (
              <div className="col-lg-5 col-md-12 col-sm-12">
                <TripEstimateIcon
                  icon="correct"
                  iconBg="bg-green"
                  fieldSize={styles.tripEstimateFieldLg}
                  iconSize={styles.tripEstimateIconLg}
                />

                <h4 className={classnames("text-uppercase font-weight-bolder mb-5", styles.tripEstimateType)}>
                  Cena zawiera
                </h4>
                <div className={classnames(fullContent ? "tripSectionContent" : "tripSectionContentExpanded")}>
                  {trip?.priceInclude?.map((priceIncludeEl: string, key: number) => (
                    <div className={classnames("row")} key={key}>
                      <div className="col-2">
                        <TripEstimateIcon
                          icon="correct"
                          iconBg="bg-green"
                          fieldSize={styles.tripEstimateFieldSm}
                          iconSize={styles.tripEstimateIconSm}
                        />
                      </div>

                      <p className={classnames("pl-3 col-8", styles.esmiatePoint)}>{priceIncludeEl}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {trip?.priceExclude?.length > 0 && (
              <div className="col-lg-5 col-md-12 col-sm-12">
                <TripEstimateIcon
                  icon="incorrect"
                  iconBg="bg-lightred"
                  fieldSize={styles.tripEstimateFieldLg}
                  iconSize={styles.tripEstimateIconLg}
                />

                <h4 className={classnames("text-uppercase font-weight-bolder mb-5", styles.tripEstimateType)}>
                  Cena nie zawiera
                </h4>
                <div className={classnames(fullContent ? "tripSectionContent" : "ripSectionContentExpanded")}>
                  {trip?.priceExclude?.map((priceExcludeEl: string, key: number) => (
                    <div className={classnames("row")} key={key}>
                      <div className="col-2">
                        <TripEstimateIcon
                          icon="incorrect"
                          iconBg="bg-lightred"
                          fieldSize={styles.tripEstimateFieldSm}
                          iconSize={styles.tripEstimateIconSm}
                        />
                      </div>

                      <p className={classnames("pl-3 col-8", styles.esmiatePoint)}>{priceExcludeEl}</p>
                    </div>
                  ))}
                  <div className="tripSectionContentFadeout" />
                </div>
              </div>
            )}
          </div>

          {(trip?.priceInclude?.length > 0 || trip?.priceExclude?.length > 0) && (
            <HideButton
              onClick={handleTogglePoints}
              buttonClassName="tripSectionFadeoutBtn"
              icon={classnames(btnIcon ? "chevron-top" : "chevron-bottom")}
            >
              {btnText && <span>Zwiń</span>}
              {!btnText && <span>Rozwiń</span>}
            </HideButton>
          )}
        </div>
      </PageSection>
    </>
  );
};

export default TripEstimate;
