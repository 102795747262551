import React from "react";
import classnames from "classnames";

interface IProps {
  fullWidth?: boolean;
  className?: string;
}

const PageSection: React.FC<IProps> = ({ fullWidth, children, className }) => {
  function renderContent(): JSX.Element {
    if (fullWidth) {
      return <>{children}</>;
    }
    return (
      <div className="container">
        <div className="row">{children}</div>
      </div>
    );
  }

  return (
    <div className={classnames("container-fluid", className)}>
      <div className="row">{renderContent()}</div>
    </div>
  );
};

export default PageSection;

PageSection.defaultProps = {
  fullWidth: false,
};
