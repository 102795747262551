import React, { useState } from "react";
import classnames from "classnames";

import { HideButton } from "../../common";
import PageSection from "../../../../PageSection";

import styles from "../../TripPage.module.scss";
import "../../common/tripSectionExpand.scss";
import { ITrip } from "../../tripData";
import { TourInfoSection } from "../../../../../API/types";

export interface ITripProgram {
  trip: ITrip;
}

const TripOtherInf: React.FC<ITripProgram> = ({ trip }) => {
  const [btnText, setBtnText] = useState(false);
  const [btnIcon, setBtnIcon] = useState(false);
  const [fullContent, setFullContent] = useState(true);

  function handleTogglePoints(): void {
    showFullContent();
    changeBtnText();
    changeBtnIcon();
  }

  const changeBtnText = (): void => setBtnText(!btnText);
  const changeBtnIcon = (): void => setBtnIcon(!btnIcon);
  const showFullContent = (): void => setFullContent(!fullContent);

  return (
    <PageSection>
      <div className={classnames("col-12", styles.tripOtherInf)}>
        <div className={classnames(fullContent ? "tripSectionContent" : "tripSectionContentExpanded")}>
          {trip?.infoSections?.map((tripInfoSection: TourInfoSection, key: number) => (
            <div key={key}>
              <h4 className="text-uppercase font-weight-bolder mb-4">{tripInfoSection.title}</h4>
              <div className={classnames("d-flex", styles.tileInfoTripContent)}>
                <i
                  className={classnames(tripInfoSection?.icon?.fontClasses, "mr-4 ml-3 mt-2", styles.tileInfoTripIcon)}
                ></i>
                <p
                  className={classnames("pl-1 mb-0", styles.tileInfoTripDesc)}
                  dangerouslySetInnerHTML={{
                    __html: tripInfoSection.content,
                  }}
                ></p>
              </div>
            </div>
          ))}
        </div>

        {trip?.infoSections && (
          <HideButton
            onClick={handleTogglePoints}
            icon={classnames(btnIcon ? "chevron-top" : "chevron-bottom")}
            buttonClassName="tripSectionFadeoutBtn"
          >
            {btnText && <span>Zwiń</span>}
            {!btnText && <span>Rozwiń</span>}
          </HideButton>
        )}
      </div>
    </PageSection>
  );
};

export default TripOtherInf;
