import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import ReactGA from "react-ga";

import { Button } from "../../common";

import styles from "./Tile.module.scss";
import { ITile } from "./tileData";
import { formatData, getUserFriendlyPriceFromApiPrice } from "../../../services/functions";

interface IProps {
  tile: ITile;
  tileClass?: string;
}

const TileTrip: React.FC<IProps> = ({ tile, tileClass }) => {
  // eslint-disable-next-line
  const handleInputMeasurement = () => {
    ReactGA.event({
      category: "Trip",
      action: "Clicked on a Tour",
      label: `Clicked on a Trip "${tile.name}"`,
    });
  };

  return (
    <div className={tileClass}>
      <div className={classnames(styles.tileCard, "card mb-3")}>
        {tile.photo.url ? (
          <Link
            onClick={handleInputMeasurement}
            className={classnames(styles.tileLinkPhoto, "text-decoration-none")}
            to={`/wyprawa/${tile.slug}`}
          >
            <img
              className={classnames(styles.tilePhoto, "card-img-top")}
              id={tile.photo.id}
              src={tile.photo.thumbUrl}
              alt={tile.shortName}
            />
          </Link>
        ) : (
          <Link
            to={`/wyprawa/${tile.slug}`}
            className={classnames(
              "text-decoration-none d-flex justify-content-center align-items-center",
              styles.noPhoto
            )}
          >
            <div className={classnames(styles.noPhotoInside, "card-img-top")}>
              <span className={classnames(styles.iconNoPhoto, "si si-photography ")} />
            </div>
          </Link>
        )}

        <div className="card-body d-flex flex-column justify-content-between align-content-between">
          <Link className={classnames("mt-3 text-decoration-none", styles.tileTitleLink)} to={`/wyprawa/${tile.slug}`}>
            <h3 className={classnames(styles.tileHeader, "font-weight-bolder card-title")}>{tile.shortName}</h3>
          </Link>
          <div className="d-flex">
            <div className={classnames(styles.cardInfTrip, "d-flex justify-content-start align-items-center")}>
              <span className={classnames(styles.tileIcon, "si si-plane")} />
              <div>
                <h5 className={classnames(styles.tileData, "my-0")}>termin</h5>
                <span className={classnames(styles.tileData, "font-weight-bold")}>{formatData(tile.startDate)}</span>
              </div>
            </div>

            <div className={classnames(styles.cardInfTrip, "d-flex justify-content-end align-items-center")}>
              <span className={classnames(styles.tileIcon, "si si-data")} />
              <div>
                <h5 className={classnames(styles.tileData, "my-0")}>czas:</h5>
                <span className={classnames(styles.tileData, "font-weight-bold")}>{tile.duration} dni</span>
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer bg-transparent d-flex flex-column align-items-end">
          {tile?.price?.amount && (
            <p className={classnames(styles.tileExpenseToRight, "mb-2")}>
              Cena:
              <span className={classnames(styles.tilePrice, "ml-2", "font-weight-bold")}>
                {getUserFriendlyPriceFromApiPrice(tile?.price?.amount)} {tile?.price?.currency}
                <br/>
                {tile?.additionalPrice?.amount && (
                  <span>
                    + {getUserFriendlyPriceFromApiPrice(tile?.additionalPrice?.amount)} {tile?.additionalPrice?.currency}
                  </span>
                ) || (
                  <span>
                    <br/>
                  </span>
                )}
              </span>
            </p>
          )}


          <Link className="mt-3" to={`/wyprawa/${tile.slug}`}>
            <Button className={"btn-green font-weight-bolder"}>Zobacz</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TileTrip;
