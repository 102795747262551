import React, { useEffect } from "react";

interface IProps {
  title: string;
  component: React.FC;
}

const PageWithTitle: React.FC<IProps> = ({ title, component }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  const PageComponent = component;

  return <PageComponent />;
};

export default PageWithTitle;
