// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".vdV0EGoZacbBHQ590sPWL{font-size:1.125rem;margin-bottom:83px;font-family:Montserrat}._1py6Xr5UMN3EqlRPX2xMZ_{margin-top:105px;margin-bottom:33px}._2ecXcEUFxyPeD7BKPq8rL5{font-size:1.25rem}._1L0N0Th0-Og7bxaiFIX9mE{margin-top:68px;margin-bottom:83px}.ZWv6STlaR-5OkVQkeEnZJ{margin-top:150px}._3sIX7jC3fr6H_KFdBFHnOd{margin-bottom:30px}._3QA9aSrPcKq2LjWy0viXAK{font-size:32px}._2ysF6kGMpFz8gPr1iw1Msz{line-height:1.8}.ugUlDiC8KYq3IERMCXnvw{margin-bottom:75px}._2YyzUG0p15cY9IMUafTa4N{margin-top:15px}._2YyzUG0p15cY9IMUafTa4N iframe{max-height:60px;height:60px}\n", ""]);
// Exports
exports.locals = {
	"tripPageDesc": "vdV0EGoZacbBHQ590sPWL",
	"tripOtherInf": "_1py6Xr5UMN3EqlRPX2xMZ_",
	"tripAsstetsMapHeader": "_2ecXcEUFxyPeD7BKPq8rL5",
	"tripSubtitle": "_1L0N0Th0-Og7bxaiFIX9mE",
	"tileInfoTrip": "ZWv6STlaR-5OkVQkeEnZJ",
	"tileInfoTripContent": "_3sIX7jC3fr6H_KFdBFHnOd",
	"tileInfoTripIcon": "_3QA9aSrPcKq2LjWy0viXAK",
	"tileInfoTripDesc": "_2ysF6kGMpFz8gPr1iw1Msz",
	"tripPageContactForm": "ugUlDiC8KYq3IERMCXnvw",
	"skaleoButtonInHeader": "_2YyzUG0p15cY9IMUafTa4N"
};
module.exports = exports;
