import { useCallback } from "react";

export enum ConversionsTargets {
  Contact = "AW-10776213196/TpvBCJXfp8gDEMztv5Io",
}

type Handler = () => void;

function useGAConversionHandler(target: ConversionsTargets): Handler {
  return useCallback(() => {
    if (typeof window !== "undefined") {
      window.gtag("event", "conversion", { send_to: target });
    }
  }, [target]);
}

export default useGAConversionHandler;
