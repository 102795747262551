import React from "react";
import { useParams } from "react-router";
import classnames from "classnames";

import { NextExpeditions } from "../../common";
import PostHeader from "./PostHeader/PostHeader";
import PageSection from "../../PageSection";

import useHomeData from "../../../services/dataHooks/useHomeData";
import usePostData from "../../../services/dataHooks/UsePost";
import { IPostBlog } from "./PostsBlog";
import styles from "./Post.module.scss";

import MetaTags from "react-meta-tags";
import { WWW_URL } from "appConfig";

export interface IParams {
  slug: string;
}

const PostPage: React.FC = () => {
  const { trips } = useHomeData();
  const tripsByDate = trips?.slice().sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate));
  const { slug: postSlug } = useParams<IParams>();
  const { post } = usePostData(postSlug);

  return (
    <>
      {trips && post ? (
        <>
          <MetaTags>
            <link rel="canonical" href={WWW_URL + "/blog/" + post?.slug} />

            <title>{post?.title} - Blog Shangrila Travel</title>
            {post?.metaDescription && post.metaDescription.length > 0 && (
              <meta name="description" content={post?.metaDescription} />
            )}
            {post?.metaKeywords && post.metaKeywords.length > 0 && (
              <meta name="keywords" content={post?.metaKeywords} />
            )}
            {post?.ogTitle && post.ogTitle.length > 0 && <meta property="og:title" content={post?.ogTitle} />}
            {post?.ogDescription && post.ogDescription.length > 0 && (
              <meta property="og:description" content={post?.ogDescription} />
            )}
            <meta property="og:locale" content="pl_PL" />
            {post?.slug && post.slug.length > 0 && <meta property="og:url" content={WWW_URL + "/blog/" + post?.slug} />}
            {post?.mainImage?.url && post.mainImage.url.length > 0 && (
              <meta property="og:image" content={post?.mainImage.url} />
            )}
            {post?.ogTitle && post.ogTitle.length > 0 && <meta name="twitter:title" content={post?.ogTitle} />}
            {post?.ogDescription && post.ogDescription.length > 0 && (
              <meta name="twitter:description" content={post?.ogDescription} />
            )}
            {post?.mainImage?.url && post.mainImage.url.length > 0 && (
              <meta name="twitter:image" content={post?.mainImage.url} />
            )}

            {post?.mainImage?.url && post.mainImage.url.length > 0 && (
              <meta name="twitter:card" content="summary_large_image" />
            )}
          </MetaTags>{" "}
          <PostHeader post={post ?? ({} as IPostBlog)} />
          <PageSection>
            {post?.content && (
              <div
                className={classnames("col-12", styles.postContent)}
                dangerouslySetInnerHTML={{
                  __html: post?.content,
                }}
              ></div>
            )}
          </PageSection>
          <PageSection>
            <div className="col-12 d-flex justify-content-between align-items-center mt-5">
              <span className={classnames(styles.postDate)}>
                {post?.createdAt && new Date(post.createdAt).toLocaleDateString()}
              </span>
            </div>
          </PageSection>
          {tripsByDate && <NextExpeditions trips={tripsByDate} />}
        </>
      ) : (
        <div id="first-global-spinner" className="spinner">
          <div></div>
        </div>
      )}
    </>
  );
};

export default PostPage;
