import React, { useState } from "react";
import classnames from "classnames";

import usePostsData from "../../../../../services/dataHooks/usePostsData";

import { SectionTitle } from "../../common";
import PageSection from "../../../../PageSection";
import { PostTile } from "../../../../common";

import style from "./BlogSection.module.scss";
import { ITilePost } from "../../../BlogPage/tilesPostsData";
import { ISort } from "../../../../../models";

const BlogSection: React.FC = () => {
  const [sort] = useState<ISort>({ orderBy: "name", orderDir: "asc" });

  const { posts } = usePostsData(sort);

  return (
    <PageSection>
      <div className={classnames(style.divider, "w-100")}>
        <div className="shangrilaDivider" />
      </div>
      <div className="col-12">
        <SectionTitle title="Ostatnio na naszym Blogu" buttonLabel="Zobacz więcej" link="/blog" />
      </div>
      {posts?.slice(0, 2).map((postBlog: ITilePost, index: number) => (
        <div key={index} className={classnames(style.posts, "col-md-6")}>
          <PostTile key={index} post={postBlog} />
        </div>
      ))}
    </PageSection>
  );
};

export default BlogSection;
