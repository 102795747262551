import React, { useMemo, useEffect, useState, useCallback } from "react";
import Swiper from "swiper";
import "swiper/css/swiper.css";

import { Title } from "..";
import PhotoGallery from "./PhotoGallery/PhotoGallery";

import "./GallerySlider.scss";
import PageSection from "../../PageSection";
import { getGallery } from "../../../services/tripService";
import { OnSlideChangeCallbackParam as onFullscreenSlideChangeCallbackParam } from "./GalleryLightbox";
import { IPhoto } from "./Photo";

export interface IPhotos {
  tripID: string;
}

const ITEMS_PER_PAGE = 5;

interface GalleryData {
  photos: IPhoto[];
  lastPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

interface FetchPhotos {
  lastPage: number;
  photos: IPhoto[];
}

const fetchPhotos = async (page: number, perPage: number, tripID?: string): Promise<FetchPhotos> => {
  if (!tripID) {
    return Promise.reject("no trip ID provided");
  }
  const rsp = await getGallery(tripID, page, perPage);
  const photos = rsp.data;
  const lastPage = parseInt(rsp.headers["x-last-page"]);

  return {
    lastPage,
    photos,
  };
};

function useGallery(tripID?: string): GalleryData {
  const [currentPage, setCurrentPage] = useState(1);
  const [photos, setPhotos] = useState<IPhoto[]>([]);
  const [lastPage, setLastPage] = useState(1);
  const [maxPageLoaded, setMaxPageLoaded] = useState(1);

  const handleSetCurrentPage = useCallback(
    (page) => {
      if (page < 1 || page > lastPage) {
        return;
      }
      setCurrentPage(page);
    },
    [lastPage]
  );

  // first page, loaded only on initial load
  useEffect(() => {
    fetchPhotos(1, ITEMS_PER_PAGE, tripID)
      .then(({ lastPage: lp, photos }) => {
        setLastPage(lp);
        setPhotos((prevPhotos) => [...prevPhotos, ...photos]);
      })
      .catch(() => {
        return;
      });
  }, [tripID]);

  // buffer next page
  useEffect(() => {
    const pageToLoad = currentPage + 1;
    if (pageToLoad > lastPage || pageToLoad <= maxPageLoaded) {
      return;
    }
    fetchPhotos(pageToLoad, ITEMS_PER_PAGE, tripID)
      .then(({ lastPage: lp, photos }) => {
        setLastPage(lp);
        setPhotos((prevPhotos) => [...prevPhotos, ...photos]);
        setMaxPageLoaded((prevMaxPageLoaded) => Math.max(prevMaxPageLoaded, pageToLoad));
      })
      .catch(() => {
        return;
      });
  }, [tripID, currentPage, lastPage, maxPageLoaded]);

  return {
    photos,
    lastPage,
    currentPage,
    setCurrentPage: handleSetCurrentPage,
  };
}

const GallerySlider: React.FC<IPhotos> = ({ tripID }) => {
  const { currentPage, setCurrentPage, lastPage, photos } = useGallery(tripID);
  const pages = useMemo(() => {
    return Array.from(Array(lastPage).keys()).map((_, index) => index + 1);
  }, [lastPage]);
  const [swiper, setSwiper] = useState<Swiper | null>(null);

  const handleNextSlide = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [setCurrentPage, currentPage]);
  const handlePrevSlide = useCallback(() => {
    setCurrentPage(currentPage - 1);
  }, [setCurrentPage, currentPage]);

  const handleFullscreenSlideChange = useCallback(
    (param: onFullscreenSlideChangeCallbackParam) => {
      const slidePage = Math.ceil((param.index + 1) / ITEMS_PER_PAGE);
      if (slidePage !== currentPage) {
        setCurrentPage(slidePage);
        swiper?.slideTo(slidePage - 1);
      }
    },
    [setCurrentPage, currentPage, swiper]
  );

  useEffect(() => {
    setSwiper(
      new Swiper(".swiper-container", {
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
          nextEl: ".gallerySliderNext",
          prevEl: ".gallerySliderPrev",
        },
        on: {},
        slideClass: "swiper-slide",
        wrapperClass: "swiper-wrapper",
      })
    );
  }, []);

  useEffect(() => {
    if (photos && photos?.length > 0 && swiper) {
      swiper?.update();
    }
    return;
  }, [photos, lastPage, swiper]);

  return (
    <>
      <div className="gallerySwiperContainer">
        {photos && photos?.length > 0 && <Title className="w-100 text-center mb-5">Galeria</Title>}
        <div className="swiper-container">
          {photos && photos?.length > 0 && (
            <div className="swiper-wrapper gallerySliderWrapper">
              {pages.map((page: number) => {
                // we only have current, and next page loaded
                if (page > currentPage + 1) {
                  return <div className="swiper-slide gallerySlide" key={page} />;
                }

                return (
                  <div className="swiper-slide gallerySlide" key={page}>
                    <PhotoGallery
                      photos={photos.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)}
                      fullscreenMoreNext={photos.slice(page * ITEMS_PER_PAGE, photos.length)}
                      fullscreenMorePrev={photos.slice(0, (page - 1) * ITEMS_PER_PAGE)}
                      onFullscreenSlideChange={handleFullscreenSlideChange}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {photos && photos?.length > ITEMS_PER_PAGE && (
          <PageSection>
            <div className="col-12">
              <div className="gallerySliderNavigation">
                <div className="gallerySliderPrev" onClick={handlePrevSlide}>
                  <span className="si si-chevron-left gallerySliderIcon" />
                </div>
                <div className="gallerySliderNext" onClick={handleNextSlide}>
                  <span className="si si-chevron gallerySliderIcon" />
                </div>
              </div>
            </div>
          </PageSection>
        )}
      </div>
    </>
  );
};

export default GallerySlider;

