import React from "react";

import PageSection from "../../../PageSection";

import styles from "./ApiPageHeader.module.scss";
import { TitleHeader } from "../../../common";

export interface IHeaderApiPage {
  title: string;
}

const ApiPageHeader: React.FC<IHeaderApiPage> = ({ title }) => {
  return (
    <div className={styles.apiPageHeader}>
      <PageSection className="d-flex flex-column">
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 align-content-center">
          <TitleHeader classNameTitle="text-white mb-5">{title}</TitleHeader>
        </div>
      </PageSection>
    </div>
  );
};

export default ApiPageHeader;
