export interface Shangrilla {
  swagger: string;
  info: Info;
  basePath: string;
  schemes: string[];
  paths: Paths;
  definitions: Definitions;
  tags: null;
  host: string;
}

export interface Definitions {
  modelIcon: ModelIcon;
  modelImage: ModelImage;
  modelMoney: ModelMoney;
  modelRoutePoint: ModelRoutePoint;
  modelTour: ModelTour;
  modelTourInfoSection: ModelTourInfoSection;
  modelTourScheduleElement: ModelTourScheduleElement;
  timeTime: TimeTime;
  typesStringArray: TypesStringArray;
  typesUuid: TypesUUID;
}

export interface ModelIcon {
  type: Type;
  properties: ModelIconProperties;
}

export interface ModelIconProperties {
  fontClasses: FontClasses;
  name: TimeTime;
  type: TimeTime;
  url: FontClasses;
}

export interface FontClasses {
  $ref: string;
}

export interface TimeTime {
  type: Type;
}

export enum Type {
  Object = "object",
  String = "string",
}

export interface ModelImage {
  type: Type;
  properties: ModelImageProperties;
}

export interface ModelImageProperties {
  h: H;
  url: TimeTime;
  w: H;
}

export interface H {
  type: string;
  format: string;
}

export interface ModelMoney {
  type: Type;
  properties: ModelMoneyProperties;
}

export interface ModelMoneyProperties {
  amount: H;
  currency: TimeTime;
  url: H;
}

export interface ModelRoutePoint {
  type: Type;
  properties: ModelRoutePointProperties;
}

export interface ModelRoutePointProperties {
  lat: H;
  long: H;
}

export interface ModelTour {
  type: Type;
  properties: ModelTourProperties;
}

export interface ModelTourProperties {
  additionalPrice: FontClasses;
  basePrice: FontClasses;
  dateStart: FontClasses;
  description: FontClasses;
  difficulty: TimeTime;
  duration: H;
  id: FontClasses;
  infoSections: InfoSections;
  kinds: string[];
  name: TimeTime;
  participantsLimit: FontClasses;
  photo: FontClasses;
  priceExclude: PriceExclude;
  priceInclude: PriceExclude;
  pros: PriceExclude;
  routePoints: InfoSections;
  schedule: InfoSections;
  type: TimeTime;
}

export interface InfoSections {
  type: string;
  items: FontClasses;
}

export interface PriceExclude {
  type: string;
  items: TimeTime;
}

export interface ModelTourInfoSection {
  type: Type;
  properties: ModelTourInfoSectionProperties;
}

export interface ModelTourInfoSectionProperties {
  content: TimeTime;
  icon: FontClasses;
  title: TimeTime;
}

export interface ModelTourScheduleElement {
  type: Type;
  properties: ModelTourScheduleElementProperties;
}

export interface ModelTourScheduleElementProperties {
  dayEnd: H;
  dayStart: H;
  description: TimeTime;
}

export interface TypesStringArray {
  type: Type;
  properties: TypesStringArrayProperties;
}

export interface TypesStringArrayProperties {
  Values: PriceExclude;
}

export interface TypesUUID {
  type: Type;
  properties: TypesUUIDProperties;
}

export interface TypesUUIDProperties {
  UUID: string;
}

export interface Info {
  description: string;
  version: string;
  termsOfService: string;
  title: string;
  contact: Contact;
  license: License;
}

export interface Contact {
  email: string;
}

export interface License {
  name: string;
}

export interface Paths {
  "/tours": Tours;
}

export interface Tours {
  get: Get;
}

export interface Get {
  tags: string[];
  summary: string;
  description: string;
  operationId: string;
  produces: string[];
  consumes: string[];
  parameters: Parameter[];
  responses: Responses;
}

export interface Parameter {
  in: string;
  name: string;
  description: string;
  required: boolean;
  schema: FontClasses;
}

export interface Responses {
  "200": The200;
}

export interface The200 {
  description: string;
  schema: InfoSections;
}
