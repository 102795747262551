import React from "react";

import PageSection from "../../../../PageSection";
import { SectionTitle } from "../../common";
import Reason from "./Reason";
import { Reasons } from "./WhyUsData";

import style from "./WhyUs.module.scss";

const WhyUs: React.FC = () => {
  return (
    <PageSection className={style.section}>
      <div className="col-12">
        <SectionTitle title="dlaczego my?" buttonLabel="Więcej o nas" link="/about" />
      </div>
      {Reasons.map((reasonProps, index) => (
        <div key={index} className="col-sm-12 col-md-4 col-lg-3">
          <Reason {...reasonProps} />
        </div>
      ))}
    </PageSection>
  );
};

export default WhyUs;
