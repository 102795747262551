// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2hhZ5jxwu4YbvXgiu9eyw3{margin:0}._2sYmzD9t6MCPWLAwZu6rSh{margin-top:6.875rem;margin-bottom:5.375rem}._2kwLCgwJVBTAgiYmV9MXZl{font-size:1.25rem;letter-spacing:0.08em}._1rrjuMhD6e4GVkgbhQUnph{font-size:1rem;line-height:2.4;margin-top:3.125rem}._3r3vy5CVintD5vaCs7ej2G{width:540px;height:540px}._1da5hD3ZsiFQMiFi_79UfQ{width:100%;height:100%;background-size:cover;background-repeat:no-repeat;background-position:center}@media only screen and (max-width: 1200px){._3r3vy5CVintD5vaCs7ej2G{width:450px;height:450px}}@media only screen and (max-width: 991px){._3r3vy5CVintD5vaCs7ej2G{width:85vw;height:85vw}}\n", ""]);
// Exports
exports.locals = {
	"elementOurTeamLine": "_2hhZ5jxwu4YbvXgiu9eyw3",
	"elementOurTeam": "_2sYmzD9t6MCPWLAwZu6rSh",
	"elementOurTeamName": "_2kwLCgwJVBTAgiYmV9MXZl",
	"elementOurTeamCharacteristic": "_1rrjuMhD6e4GVkgbhQUnph",
	"elementOurTeamImage": "_3r3vy5CVintD5vaCs7ej2G",
	"elementOurTeamPhoto": "_1da5hD3ZsiFQMiFi_79UfQ"
};
module.exports = exports;
