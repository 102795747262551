import React from "react";
import classnames from "classnames";

import { Title } from "../../../../common";
import PageSection from "../../../../PageSection";

import styles from "./TripAssets.module.scss";
import { ITrip } from "../../tripData";

export interface IProsTrip {
  trip: ITrip;
}

const TripAssets: React.FC<IProsTrip> = ({ trip }) => {
  return (
    <PageSection>
      <div className="col-12">
        {trip?.pros?.length > 0 && <Title className="mb-4">Główne atuty i zalety oferty</Title>}
        <div className="row mt-5">
          {trip?.pros?.map((prosEl: string, key: number) => (
            <div className="col-12 col-sm-6 align-items-center mb-5" key={key}>
              <div className="d-flex">
                <span
                  className={classnames(
                    "si si-star mr-4 d-flex flex-column justify-content-center",
                    styles.tripAssetsIcon
                  )}
                />
                <h4 className="font-weight-bolder text-uppercase mb-0 d-flex flex-column justify-content-center">
                  {prosEl}
                </h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </PageSection>
  );
};

export default TripAssets;
