// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/backgroundElTrees.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._2u5CIlLdBPMsPoRGh9bO_B{padding-top:7.25rem;background:linear-gradient(180deg, #fff 0%, rgba(255,255,255,0) 100%),linear-gradient(0deg, rgba(217,217,217,0.46), rgba(217,217,217,0.46))}._3y_8Q4UQzThnLYkKuxmmGC{padding-bottom:6rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat right bottom}._1iyF5pOiAAkAkSyFXrwYnU{opacity:0;transition:all 0.6s ease}._1nXJXXTr4He5uPEJkrmaGm{padding-top:2.375rem;border:2px solid transparent;transition:all 0.6s ease}._1nXJXXTr4He5uPEJkrmaGm:hover{background-color:#fff;border:2px solid #e2e2e2;box-sizing:border-box;box-shadow:10px 10px 35px rgba(116,116,116,0.08)}._1nXJXXTr4He5uPEJkrmaGm:hover ._1iyF5pOiAAkAkSyFXrwYnU{opacity:1}.pRrZSq46KoLzbi8n1sR18{margin-bottom:3.375rem;font-family:Montserrat;font-size:1.125rem;line-height:1.8}.pRrZSq46KoLzbi8n1sR18 a{text-decoration:underline}._1x-wgs_TcEHeZsg3-aRovf{letter-spacing:0.08em}._1L8Lc3fYGaiPcXgggwB9J8{color:#000;font-size:6.25rem}\n", ""]);
// Exports
exports.locals = {
	"listOfTripsType": "_2u5CIlLdBPMsPoRGh9bO_B",
	"backgroundImageTrees": "_3y_8Q4UQzThnLYkKuxmmGC",
	"tileTripTypeButton": "_1iyF5pOiAAkAkSyFXrwYnU",
	"tileTripType": "_1nXJXXTr4He5uPEJkrmaGm",
	"tileTripTypeParagraph": "pRrZSq46KoLzbi8n1sR18",
	"tileTripTypeName": "_1x-wgs_TcEHeZsg3-aRovf",
	"tileTripTypeImage": "_1L8Lc3fYGaiPcXgggwB9J8"
};
module.exports = exports;
