// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EF5UM8Jj4AmUcN0ZSJk_Z{height:750px;padding-top:150px;background-position:center;background-repeat:no-repeat;background-size:cover;padding-top:212px}._23Mmc2kC18rNJRi7naDWDi{height:100%}._2gxnP6eg2Q_gv6PTZPWlLc{background-color:#2bad57;border-radius:50%;width:172px;height:172px}._3viEpCt2BiSIEdQT6jzpWu{font-size:1.688rem;line-height:1.2}._3GnqqC49io7tz1e_-I3lwM{margin-left:20px;margin-right:20px}._3GnqqC49io7tz1e_-I3lwM:disabled,._3GnqqC49io7tz1e_-I3lwM[disabled]{opacity:1}._2RT-ata4s8NsqjCg29asIy{background-color:rgba(43,173,86,0.509804)}._3PupJZgtADk9R3kIZMGM5Y{width:100%}@media only screen and (max-width: 1200px){.EF5UM8Jj4AmUcN0ZSJk_Z{padding-top:100px;height:100%}}@media only screen and (max-width: 800px){.EF5UM8Jj4AmUcN0ZSJk_Z{height:100%}}._23Mmc2kC18rNJRi7naDWDi iframe{width:100% !important}\n", ""]);
// Exports
exports.locals = {
	"tripHeader": "EF5UM8Jj4AmUcN0ZSJk_Z",
	"tripHeaderContent": "_23Mmc2kC18rNJRi7naDWDi",
	"tripHeaderPriceCircle": "_2gxnP6eg2Q_gv6PTZPWlLc",
	"tripHeaderPriceMain": "_3viEpCt2BiSIEdQT6jzpWu",
	"tagHeader": "_3GnqqC49io7tz1e_-I3lwM",
	"tripHeaderNoImage": "_2RT-ata4s8NsqjCg29asIy",
	"tripHeaderTitle": "_3PupJZgtADk9R3kIZMGM5Y"
};
module.exports = exports;
