import { useEffect, useState } from "react";

import { ITrip } from "../../components/pages/TripPage/tripData";
import { getDetailsOfTheTrip } from "../tripService";

interface ITripData {
  trip: ITrip | undefined;
}

export default function useTripData(slug: string): ITripData {
  const [trip, setTrip] = useState<ITrip>();

  useEffect(() => {
    const fetchTrip = async (): Promise<void> => {
      const trip = await getDetailsOfTheTrip(slug);
      setTrip(trip);
    };

    fetchTrip();
  }, [slug]);

  return { trip };
}
