import React, { useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { Button } from "../common";

import useGAConversionHandler, { ConversionsTargets } from "../../services/componentHooks/useGAConversionHandler";

import styles from "./Navbar.module.scss";
import { mainData as data } from "../../content";
import { navLogo } from "../../assets/images";
import { menuLinks } from "./NavbarData";

const Navbar: React.FC = () => {
  const handleContact = useGAConversionHandler(ConversionsTargets.Contact);
  const [collapsed, setCollapsed] = useState(true);

  function handleToggleNav(): void {
    setCollapsed((prevCollapsed) => !prevCollapsed);
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light font-weight-bolder">
        <div className="container">
          <div className="navbar-brand d-flex justify-content-center align-items-center">
            <Link to="/">
              <img src={navLogo} className={styles.navLogo} alt="Shangrila" />
            </Link>

            <span className={classnames("si si-phone ml-5")} style={{ fontSize: 28 }} />
            <a className={classnames("ml-1", styles.navPhone)} href={`tel://${data.phone}`} onClick={handleContact}>
              {data.visiblePhone}
            </a>
          </div>

          <button
            className={classnames("navbar-toggler navbar-toggler-right", { collapsed }, styles.navCollapseIcon)}
            type="button"
            onClick={handleToggleNav}
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div
            className={classnames("collapse navbar-collapse justify-content-end", {
              show: !collapsed,
            })}
          >
            <ul className={classnames("nav navbar-nav", styles.navMenuButtons)}>
              <li className="nav-item mr-2 active">
                <Link to="/szukaj-wyprawy">
                  <Button className={"btn-green nav-item font-weight-bolder"}>Wyprawy</Button>
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link className={classnames("btn font-weight-bolder", styles.navLink)} to="/o-nas">
                  O nas
                </Link>
              </li>

              {menuLinks.map((menuLink, key: number) => (
                <li className="nav-item mx-2" key={key}>
                  <a
                    target={menuLink.target}
                    rel={menuLink.rel}
                    className={classnames("btn font-weight-bolder", styles.navLink)}
                    href={menuLink.url}
                  >
                    {menuLink.name}
                  </a>
                </li>
              ))}
              <li className="nav-item ml-2">
                <Link to="/kontakt">
                  <Button className={"btn-black nav-item font-weight-bolder"}>{data.contact}</Button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
