import { useEffect, useState } from "react";
import { ITeam } from "../../components/pages/About/AboutData";
import { getAboutTeamInformation } from "../restService";

interface ITeamData {
  team: ITeam[] | undefined | null;
}

export default function useTeamData(): ITeamData {
  const [team, setTeam] = useState<ITeam[] | null>();

  useEffect(() => {
    const fetchTeamData = async (): Promise<void> => {
      const team = await getAboutTeamInformation();
      setTeam(team);
    };

    fetchTeamData();
  }, []);

  return { team };
}
