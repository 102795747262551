// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3sftf5w5v7GKefZmhstn0p{height:750px;padding-top:150px;background-position:center;background-repeat:no-repeat;background-size:cover;height:auto;margin-bottom:6.875rem;background-blend-mode:color-burn;background-color:#2bad57}._1PLmTEkcOdFIZUnwkDjLnd{font-family:Montserrat;font-style:normal;font-size:1.125rem;line-height:1.8}\n", ""]);
// Exports
exports.locals = {
	"apiPageHeader": "_3sftf5w5v7GKefZmhstn0p",
	"apiPageSubheader": "_1PLmTEkcOdFIZUnwkDjLnd"
};
module.exports = exports;
