import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import useHomeData from "../../../services/dataHooks/useHomeData";
import usePostsData from "../../../services/dataHooks/usePostsData";

import PageSection from "../../PageSection";
import { ButtonTriangular, Contact, NextExpeditions } from "../../common";
import PostsList from "./PostaList";

import styles from "./BlogPage.module.scss";
import { contactData } from "../../../content";
import { initialValues } from "../../common/Contact/ContactInitialValue";
import { formatData } from "../../../services/functions";
import { ISort } from "../../../models";
import { ITilePost } from "./tilesPostsData";

interface InitialPostProps {
  post: ITilePost;
}

const InitialPost: React.FC<InitialPostProps> = ({ post }) => {
  if (!post) {
    return <PageSection />;
  }
  return (
    <PageSection>
      <div className="col d-none d-md-flex">
        <div
          className={classnames("w-100 position-relative", styles.mainPost)}
          style={{ backgroundImage: `url(${post.image?.url})` }}
        >
          <div className={classnames("d-flex justify-content-between w-100", styles.postInf)}>
            <span className={classnames("text-white align-self-center", styles.postDate)}>{formatData(post.date)}</span>
            <div className={classnames("text-white text-uppercase d-inline-block rounded mr-4", styles.tag)}>
              {post.tag}
            </div>
          </div>

          <div className={classnames(styles.postText)}>
            <h4 className={classnames("text-uppercase text-white font-weight-bolder", styles.postTextTitle)}>
              {post.title}
            </h4>
            <div className={classnames("text-white mb-3", styles.postTextDesc)}>{post.description}</div>

            <Link to={`/blog/${post.slug}`} className="d-flex justify-content-end mr-4 mb-4 mt-5">
              <ButtonTriangular className="font-weight-bolder btn-green">Czytaj więcej</ButtonTriangular>
            </Link>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

const BlogPage: React.FC = () => {
  const [sort] = useState<ISort>({ orderBy: "createdAt", orderDir: "desc" });
  const [hasMore, setHasMore] = useState<boolean>(true);

  const { trips } = useHomeData();
  const { posts, allPostsCount, fetchPosts } = usePostsData(sort);

  useEffect(() => {
    if (allPostsCount && posts && posts.length >= allPostsCount) setHasMore(false);
  }, [posts, allPostsCount]);

  const tripsByDate = trips?.sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate));

  return (
    <>
      {trips && posts ? (
        <>
          {/* <BlogHeader blog={posts ?? ({} as IPostBlog)} /> */}

          {posts.length > 0 && <InitialPost post={posts[0]} />}
          {posts.length > 0 && (
            <PostsList posts={posts} postsCount={allPostsCount || 0} fetchMore={fetchPosts} hasMore={hasMore} />
          )}

          <div className="pt-5 pb-5">
            <Contact
              header={contactData.goAhead}
              headerClass="text-center"
              actionText={contactData.actionText}
              onSubmit={() => alert("onSubmit")}
              initialValues={initialValues}
              showAdditionalInputs={false}
            />
          </div>

          {tripsByDate && <NextExpeditions trips={tripsByDate} />}
        </>
      ) : (
        <div id="first-global-spinner" className="spinner">
          <div></div>
        </div>
      )}
    </>
  );
};

export default BlogPage;
