/* eslint-disable max-len */
import React from "react";
import classnames from "classnames";

import PageSection from "../../../../PageSection";

import styles from "./Cooperation.module.scss";
import { cooperationLogos } from "./CooperationData";

const Cooperation: React.FC = () => {
  return (
    <PageSection fullWidth={true} className={styles.cooperation}>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex flex-column flex-lg-row justify-content-between">
            <h2
              className={classnames(
                "align-self-center font-weight-bold m-0 mr-md-5 text-center text-md-left text-uppercase",
                styles.cooperationHeader
              )}
            >
              Współpracujemy z:
            </h2>

            <div className="align-content-center d-flex flex-grow-1 flex-wrap justify-content-center justify-content-md-between">
              {cooperationLogos.map((cooperationLogo, key: number) => (
                <div
                  className="d-flex align-items-center justify-content-end justify-content-lg-end my-4 px-2"
                  key={key}
                >
                  <img className={styles.cooperationLogo} src={cooperationLogo.image} alt={cooperationLogo.name} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default Cooperation;
