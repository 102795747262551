interface IContentData {
  contactHeaderDesc: string;
  conatctHeaderAdditionalDesc: string;
  contactMapHeader: string;
}

export const contactContent: IContentData = {
  contactHeaderDesc:
    /* eslint-disable max-len */
    "Wieloletnie doświadczenie w branży turystycznej połączone jednocześnie z pasją i zamiłowaniem do odkrywania świata, doprowadziło do powstania biura podróży Shangrila Travel. Biura, które przede wszystkim stawia na najlepszych pilotów, świetnie dopracowane programy wyjazdów, kameralne grupy oraz wspólne kulinarne doświadczenia. Oferujemy najwyższą jakość obsługi oraz całodobową opiekę przewodnika grupy, po to aby zapewnić Wam bezpieczeństwo i komfort w trakcie wyjazdów oraz cudowne wspomnienia z podróży z nami.",
  conatctHeaderAdditionalDesc: "Jesteśmy do Twojej dyspozycji!",
  contactMapHeader: "Shangrila Travel",
};
