import { AxiosPromise, AxiosResponse } from "axios";
import { apiService } from "./api.service";
import { Page, Team, Tour, Post } from "../API/types";
import { OrderBy, OrderDir, IFilter, IDestination } from "../models";
import { IContactFormData } from "./contactForm";
import { IPhoto } from "../components/common/Gallery/Photo";

class DataService {
  getTours(
    orderBy?: OrderBy,
    orderDir?: OrderDir,
    kind?: IFilter | null,
    type?: IFilter | null,
    filters?: IFilter[],
    perPage?: number
  ): AxiosPromise<Tour[]> {
    const query = [kind, type, ...(filters || [])].reduce(
      (acu, filter) => {
        if (filter?.value === null || filter?.value === "") {
          return acu;
        }

        return {
          ...acu,
          [`filter[${filter?.param}][${filter?.filterMode}]`]: filter?.value,
        };
      },
      { orderBy, orderDir, perPage }
    );

    return apiService.get("/tours", { params: query }).catch((error: AxiosResponse) => Promise.reject(error));
  }

  getPosts(orderBy?: OrderBy, orderDir?: OrderDir): AxiosPromise<Post[]> {
    const query = {
      orderBy: orderBy,
      orderDir: orderDir,
    };

    return apiService.get("/blog-posts", { params: query }).catch((error: AxiosResponse) => Promise.reject(error));
  }

  getTour(slug: string): AxiosPromise<Tour> {
    return apiService.get(`/tours-search/${slug}`).catch((error: AxiosResponse) => Promise.reject(error));
  }

  getContact(slug: string): AxiosPromise<Page> {
    return apiService.get(`/pages-search/${slug}`).catch((error: AxiosResponse) => Promise.reject(error));
  }

  getAbout(slug: string): AxiosPromise<Page> {
    return apiService.get(`/pages-search/${slug}`).catch((error: AxiosResponse) => Promise.reject(error));
  }

  async getPagesSearch(slug: string): Promise<AxiosResponse<Page>> {
    return apiService.get<Page>(`/pages-search/${slug}`).catch((error: AxiosResponse) => Promise.reject(error));
  }

  getAboutTeam(): AxiosPromise<Team[]> {
    return apiService.get(`/team-members`).catch((error: AxiosResponse) => Promise.reject(error));
  }

  getPost(slug: string): AxiosPromise<Post> {
    return apiService.get(`/blog-posts-search/${slug}`).catch((error: AxiosResponse) => Promise.reject(error));
  }

  getDestination(): AxiosPromise<IDestination[]> {
    const query = {
      orderBy: "name",
      orderDir: "ASC",
    };

    return apiService.get("/destinations", { params: query }).catch((error: AxiosResponse) => Promise.reject(error));
  }

  getSilimarTours(id: string): AxiosPromise<Tour[]> {
    const query = {
      orderBy: "dateStart",
      orderDir: "ASC",
    };

    return apiService
      .get(`tours/${id}/similar-tours`, { params: query })
      .catch((error: AxiosResponse) => Promise.reject(error));
  }

  getGallery(id: string, page = 1, perPage = 5): AxiosPromise<IPhoto[]> {
    return apiService
      .get(`tours/${id}/gallery`, {
        params: {
          page,
          perPage,
        },
      })
      .catch((error: AxiosResponse) => Promise.reject(error));
  }

  postContactForm(dataMail: IContactFormData): AxiosPromise {
    return apiService.post(`contact-form`, dataMail).catch((error: AxiosResponse) => Promise.reject(error));
  }

  getPageGallery(id: string, page = 1, perPage = 5): AxiosPromise<IPhoto[]> {
    return apiService
      .get(`pages/${id}/gallery`, {
        params: {
          page,
          perPage,
        },
      })
      .catch((error: AxiosResponse) => Promise.reject(error));
  }
}

export const dataService = new DataService();
