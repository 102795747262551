// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._32qivLeZeZa7vBHM9CyTzl{margin-bottom:20px}.IKAmsHRnUVT1C6N81P81t{color:#2bad57;font-size:35px}._2bVQGundxgOVGAMm_XCzXN{width:87px;height:87px;margin-right:2rem;border-radius:50%;background-color:#2bad57}._2TGHAx3ZAuREaEDNhwBCcO{color:#fff;font-size:36px}\n", ""]);
// Exports
exports.locals = {
	"contactNumberSection": "_32qivLeZeZa7vBHM9CyTzl",
	"contactNumber": "IKAmsHRnUVT1C6N81P81t",
	"contactPhoneIconBg": "_2bVQGundxgOVGAMm_XCzXN",
	"contactPhoneIcon": "_2TGHAx3ZAuREaEDNhwBCcO"
};
module.exports = exports;
