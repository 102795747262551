// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._17cLLAdhVnFIjGXaO2YopN{margin:2rem 0}._3GCm2TWktBNXbPSqgeuNO_{line-height:2.3}._31wr4fQ0LWl3AD2QDIehzD{width:100%;height:370px;margin-bottom:30px}@media (min-width: 992px){._31wr4fQ0LWl3AD2QDIehzD{width:90%;margin-bottom:-70px}}@media (min-width: 768px){._31wr4fQ0LWl3AD2QDIehzD{width:90%;height:370px}}\n", ""]);
// Exports
exports.locals = {
	"textWithMapImage": "_17cLLAdhVnFIjGXaO2YopN",
	"textWithMapDesc": "_3GCm2TWktBNXbPSqgeuNO_",
	"textWithMapZoom": "_31wr4fQ0LWl3AD2QDIehzD"
};
module.exports = exports;
