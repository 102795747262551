import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import { Button } from "../../../../common";

import styles from "./TypesOfTrips.module.scss";
import { ITypesOfTrips } from "./TypesOfTripsData";

interface IProps {
  type: ITypesOfTrips;
  searchParam?: string;
  url?: string;
}

const TripType: React.FC<IProps> = ({ type, searchParam, url }) => {
  // eslint-disable-next-line

  return (
    <div
      className={classnames(styles.tileTripType, "d-flex flex-column justify-content-center align-items-center pb-4")}
    >
      <span className={classnames(`si si-${type.image}`, styles.tileTripTypeImage, "mb-3 mt-4")} />
      <h4 className={classnames("text-uppercase text-dark font-weight-bolder mb-4", styles.tileTripTypeName)}>
        {type.name}
      </h4>

      {searchParam && (
        <Link to={`szukaj-wyprawy/?type=${searchParam}`} className={styles.tileTripTypeButton}>
          <Button className="btn-green font-weight-bolder">Zobacz wyprawy</Button>
        </Link>
      )}

      {url && (
        <Link to={type.url ?? "/"} className={styles.tileTripTypeButton}>
          <Button className="btn-green font-weight-bolder">Zobacz wyprawy</Button>
        </Link>
      )}
    </div>
  );
};

export default TripType;
