import { logoFreeride, logoIduna, logoOffWork } from "../../../../../assets/images";

export interface ICooperationLogos {
  name: string;
  image: string;
  url: string;
}

export const cooperationLogos: ICooperationLogos[] = [
  {
    name: "OffWork",
    image: logoOffWork,
    url: "/",
  },
  {
    name: "Freeride",
    image: logoFreeride,
    url: "/",
  },
  {
    name: "Signal Iduna",
    image: logoIduna,
    url: "/",
  },
];
