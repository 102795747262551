import React from "react";
import { useParams } from "react-router";
import MetaTags from "react-meta-tags";

import { WWW_URL } from "appConfig";

import useApiPageData from "../../../services/dataHooks/useApiPageData";

import PageSection from "../../PageSection";

import { Contact } from "../../common";
import { initialValues } from "../../common/Contact/ContactInitialValue";
import { contactData } from "../../../content";

import { ApiPageHeader } from "./";
import classnames from "classnames";
import styles from "./ApiPageHeader/ApiPageHeader.module.scss";

export const ApiPage: React.FC = () => {
  const { slug } = useParams();
  const { data: apiPage, pending, error } = useApiPageData(slug);

  if (pending) {
    return <ApiPageHeader title="Ładowanie..." />;
  }

  if (error) {
    return <ApiPageHeader title={"Błąd"} />;
  }

  return (
    <>
      <MetaTags>
        <link rel="canonical" href={WWW_URL + "/" + slug} />

        <title>{apiPage?.title} - Shangrila Travel</title>
        {(apiPage?.metaDescription?.length as number) > 0 && (
          <meta name="description" content={apiPage?.metaDescription} />
        )}
        {(apiPage?.metaKeywords?.length as number) > 0 && <meta name="keywords" content={apiPage?.metaKeywords} />}
        {(apiPage?.ogTitle?.length as number) > 0 && <meta property="og:title" content={apiPage?.ogTitle} />}
        {(apiPage?.ogDescription?.length as number) > 0 && (
          <meta property="og:description" content={apiPage?.ogDescription} />
        )}
        <meta property="og:locale" content="pl_PL" />
        {(apiPage.mainImage?.url?.length as number) > 0 && (
          <meta property="og:image" content={apiPage?.mainImage?.url} />
        )}
        {(apiPage?.ogTitle?.length as number) > 0 && <meta name="twitter:title" content={apiPage?.ogTitle} />}
        {(apiPage?.ogDescription?.length as number) > 0 && (
          <meta name="twitter:description" content={apiPage?.ogDescription} />
        )}
        {(apiPage?.mainImage?.url?.length as number) > 0 && (
          <meta name="twitter:image" content={apiPage?.mainImage?.url} />
        )}
        {(apiPage?.mainImage?.url?.length as number) > 0 && <meta name="twitter:card" content="summary_large_image" />}
      </MetaTags>

      <ApiPageHeader title={apiPage.title} />

      <PageSection>
        <div className="col-12 pb-5">
          <div className="shangrilaDivider mb-4" />
          <p className="py-5 text-center mb-0">
            <span className="text text-uppercase font-weight-bold">Shangrila Travel</span>  posiada zezwolenie na 
            organizację imprez turystycznych o numerze 1701 wydane przez Marszałka Województwa Śląskiego oraz 
            certyfikat ubezpieczyciela Signal Iduna o numerze M 524519.
          </p>
          <div className="shangrilaDivider mt-4 mb-5" />
        </div>
      </PageSection>

      <PageSection>
        <div className="col-12">
          {apiPage?.content && (
            <p
              className={classnames(styles.apiPageSubheader)}
              dangerouslySetInnerHTML={{
                __html: apiPage?.content,
              }}
            />
          )}
        </div>
      </PageSection>

      <div className="pt-5 pb-5">
        <Contact
          header={contactData.goAhead}
          headerClass="text-center"
          actionText={contactData.actionText}
          onSubmit={() => alert("onSubmit")}
          initialValues={initialValues}
          showAdditionalInputs={false}
        />
      </div>
    </>
  );
};
