import React, { useEffect } from "react";

import {
  FindTheTrip,
  Hero,
  TypesOfTrips,
  TripMasonry,
  WhyUs,
  UpcomingTrips,
  Cooperation,
  BlogSection,
} from "./Sections";

const Home: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero />
      <TypesOfTrips />
      <UpcomingTrips />
      <TripMasonry />
      <FindTheTrip />
      <WhyUs />
      <BlogSection />
      <Cooperation />
    </>
  );
};

export default Home;
