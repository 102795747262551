import { apiService } from "./api.service";
import { LocalStorageService } from "./LocalStorage.service";

interface SubscribeValues {
  email: string;
}

export class NewsletterService {
  static async subscribe(subscriptionData: SubscribeValues): Promise<void> {
    await apiService.post(`/newsletter-subscribers`, subscriptionData);
    LocalStorageService.set("newsletter", { email: subscriptionData.email, subscribed: true, declined: false });
  }

  static decline(): void {
    LocalStorageService.set("newsletter", { email: "", subscribed: false, declined: true });
  }
}
