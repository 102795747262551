// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._34TEw86vhuau1q_npfJZFx::before{position:absolute;content:\"\";z-index:-2;height:100vh;width:100%;background:linear-gradient(180deg, #fff 0%, rgba(255,255,255,0) 100%),linear-gradient(0deg, #e6e6e6, #e6e6e6);box-sizing:border-box}._3rjDOLB9QiM6laSfdJXjCF{bottom:-3.75rem;left:0}@media only screen and (max-width: 600px){._3rjDOLB9QiM6laSfdJXjCF{bottom:-10rem}}\n", ""]);
// Exports
exports.locals = {
	"tripsSearchEngineBackground": "_34TEw86vhuau1q_npfJZFx",
	"filterContainer": "_3rjDOLB9QiM6laSfdJXjCF"
};
module.exports = exports;
