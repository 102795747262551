// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Jm4J5jSJlwFHD94MrxA5V{margin-bottom:3.813rem}._2iDg4tM1m_qG1o1HHrSZ1T{margin-top:6.75rem;margin-bottom:2.8rem}\n", ""]);
// Exports
exports.locals = {
	"FaqContentContact": "_3Jm4J5jSJlwFHD94MrxA5V",
	"FaqConatctForm": "_2iDg4tM1m_qG1o1HHrSZ1T"
};
module.exports = exports;
