// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._277RABLE9F2UN4LB2kIOV-{height:750px;padding-top:150px;background-position:center;background-repeat:no-repeat;background-size:cover;margin-bottom:6.875rem;background-blend-mode:color-burn;background-color:#aaaaaa}.VGI8GUyEskKJnOgYCd5GG{font-family:Montserrat;font-style:normal;font-size:1.125rem;line-height:1.8}\n", ""]);
// Exports
exports.locals = {
	"aboutHeader": "_277RABLE9F2UN4LB2kIOV-",
	"aboutSubheader": "VGI8GUyEskKJnOgYCd5GG"
};
module.exports = exports;
