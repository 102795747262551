// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._25kN5JI27q7UtRKQHjZE0L{background-color:#f8fbff;padding-top:5.5rem;padding-bottom:5.5rem}._1A4E2QRmK0Xs3KYy-TbPHC{max-height:70px}._39-3zxUiGj7unvqgd7o7AP{font-size:1.25rem}@media (min-width: 576px){._39-3zxUiGj7unvqgd7o7AP{font-size:2rem}}\n", ""]);
// Exports
exports.locals = {
	"cooperation": "_25kN5JI27q7UtRKQHjZE0L",
	"cooperationLogo": "_1A4E2QRmK0Xs3KYy-TbPHC",
	"cooperationHeader": "_39-3zxUiGj7unvqgd7o7AP"
};
module.exports = exports;
