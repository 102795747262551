// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".b0bnUjCTIwCalXFCC-K0p{margin:73px 15px}._3tylUw8u9HyiCMZq_akb3g{margin:145px 0px}\n", ""]);
// Exports
exports.locals = {
	"divider": "b0bnUjCTIwCalXFCC-K0p",
	"posts": "_3tylUw8u9HyiCMZq_akb3g"
};
module.exports = exports;
