// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3O1mooq9TlEx7o_dgf7q9L{margin-bottom:60px;padding-top:30px}._1niDABZ_l62_BU0vnsXm8B{border-radius:50%;background-color:#fff;color:#2bad57;width:29px;height:29px}\n", ""]);
// Exports
exports.locals = {
	"hideButton": "_3O1mooq9TlEx7o_dgf7q9L",
	"hideButtonIcon": "_1niDABZ_l62_BU0vnsXm8B"
};
module.exports = exports;
