// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".zzdbM3JOAb7Y11DBGxf0X{padding-bottom:110px}._20h667qf6VIlA3AfKAdNv6{width:100%;margin-bottom:32px}._2ew_OusgXhE_j-WINhEcfG{border:2px solid #e2e2e2;box-sizing:border-box;box-shadow:10px 10px 35px rgba(116,116,116,0.08);border-radius:10px;height:100%;height:600px;background-repeat:no-repeat;background-size:cover;background-position:center center;background-blend-mode:color-burn;margin-bottom:32px}._3_HQ2C34ugXQwCTqOy1t5V{margin-top:32px}._2qC4ych_Ioyfi88tdlnhKM{padding:1.5rem 1rem 0 2rem}.VHNV6MUSYyFqqOmaOoXNX{font-size:0.875rem;background-color:#cabe50;padding:0.5rem;height:36px}._3Glw1pXmPz39-bizClQ_Un{font-size:1.25rem;padding:1.25rem 4rem}._12O_bQmpLObUBPavt_meqG{padding:0 4rem}.K-3s4PUWaipQ5bklu0FE6{height:300px}._3XzYw8QpHjIHvDNbO8BRYX{height:100%;overflow:hidden}\n", ""]);
// Exports
exports.locals = {
	"postsBlogPage": "zzdbM3JOAb7Y11DBGxf0X",
	"postBlogPage": "_20h667qf6VIlA3AfKAdNv6",
	"mainPost": "_2ew_OusgXhE_j-WINhEcfG",
	"showMore": "_3_HQ2C34ugXQwCTqOy1t5V",
	"postInf": "_2qC4ych_Ioyfi88tdlnhKM",
	"tag": "VHNV6MUSYyFqqOmaOoXNX",
	"postTextTitle": "_3Glw1pXmPz39-bizClQ_Un",
	"postTextDesc": "_12O_bQmpLObUBPavt_meqG",
	"postMain": "K-3s4PUWaipQ5bklu0FE6",
	"postMainPhoto": "_3XzYw8QpHjIHvDNbO8BRYX"
};
module.exports = exports;
