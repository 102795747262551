import React, { useRef } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import useHomeData from "../../../../../services/dataHooks/useHomeData";
import { useSlider } from "../../../../../services/componentHooks/useSlider";

import PageSection from "../../../../PageSection";
import Tile from "../../../../common/Tile/Tile";
import { Button } from "../../../../common";
import { SectionIntro } from "../../common";

import styles from "./UpcomingTrips.module.scss";

const UpcomingTrips: React.FC = () => {
  const sliderContainer = useRef<HTMLDivElement | null>(null);
  const { trips } = useHomeData();

  const tripsByDate = trips?.sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate));
  const {
    moveLeft,
    moveRight,
    isLeftButtonDisabled,
    isRightButtonDisabled,
    sliderPosition,
    elemWidth,
    containerWidth,
  } = useSlider({ sliderContainer, elements: trips ?? [] });

  const tripWidth = elemWidth;

  function renderNavigation(): React.ReactNode {
    return (
      <div className={classnames(styles.navContainer, "mb-2 col-12 d-flex justify-content-between")}>
        <button
          className={classnames(styles.nav, "bg-white p-3 mr-1 rounded-circle")}
          disabled={isLeftButtonDisabled}
          onClick={moveLeft}
        >
          <span className="si si-chevron-left" />
        </button>
        <button
          className={classnames(styles.nav, "bg-white p-3 ml-1 rounded-circle")}
          disabled={isRightButtonDisabled}
          onClick={moveRight}
        >
          <span className="si si-chevron" />
        </button>
      </div>
    );
  }

  function renderDivider(): React.ReactNode {
    return (
      <div className="col-12">
        <div className="d-flex">
          <div className="shangrilaDivider" />
          <Link to="/szukaj-wyprawy" className="flex-shrink-0 mx-4 align-self-center justify-content-end">
            <Button className="font-weight-bolder btn-green">Zobacz wyprawy</Button>
          </Link>
          <div className="shangrilaDivider" />
        </div>
      </div>
    );
  }

  return (
    <PageSection className={classnames(styles.sectionWrapper)}>
      <div className={classnames("col-12 col-lg-8 offset-0 offset-lg-2 flex-column align-items-center")}>
        <SectionIntro title="Najbliższe wyjazdy" subtitle="subtitle" />
      </div>
      <div ref={sliderContainer} className={styles.slider}>
        <div className={styles.viewPort} style={{ width: 4 * tripWidth }}>
          <div className={styles.container} style={{ width: containerWidth, left: -sliderPosition * tripWidth }}>
            {tripsByDate &&
              tripsByDate.map((trip, index) => (
                <div
                  key={index}
                  className={classnames(styles.trip, "col-12 col-sm-12 col-md-6 col-lg-4", {
                    [styles.dimmed]: index >= sliderPosition + 3,
                  })}
                  style={{ width: tripWidth }}
                >
                  <Tile tileClass={styles.tileFullSize} tile={trip} />
                </div>
              ))}
          </div>
        </div>
      </div>
      {renderNavigation()}
      {renderDivider()}
    </PageSection>
  );
};

export default UpcomingTrips;
