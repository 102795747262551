import React from "react";
import classnames from "classnames";

import { Map, TileLayer, Marker } from "react-leaflet";

interface IZoomMap {
  className: string;
  // eslint-disable-next-line
  position: any;
  zoom: number;
}

const ZoomMap: React.FC<IZoomMap> = ({ className, position, zoom }) => {
  // eslint-disable-next-line
  return (
    <Map center={position} zoom={zoom} className={classnames(className)}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      <Marker position={position} />
    </Map>
  );
};
export default ZoomMap;
