import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import PageSection from "../../../PageSection";
import { ButtonIcon } from "../../../common";

import styles from "./FindTheTrip.module.scss";

const FindTheTrip: React.FC = () => {
  return (
    <PageSection fullWidth={true} className={classnames("bg-primary", styles.sectionFind)}>
      <div className="container">
        <div className="row d-flex justyfy-content-between align-items-center">
          <div className="col-12 col-xl-9 col-lg-9 col-md-6 col-sm-12 col-xs-12 py-3">
            <h2 className="font-weight-bold text-white text-uppercase m-0">Gotów na wakacje swojego życia?</h2>
          </div>
          <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 my-3 py-3">
            <Link to="/szukaj-wyprawy" className="text-decoration-none">
              <ButtonIcon icon={"search"} iconSize={30} className={"btn-dynamic font-weight-bolder text-nowrap"}>
                Znajdź wyprawę
              </ButtonIcon>
            </Link>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default FindTheTrip;
