import { ReasonProps as Reason } from "./Reason";
export const Reasons: Reason[] = [
  {
    img: "star",
    name: "Najlepsi piloci na rynku",
  },
  {
    img: "group",
    name: "kameralne grupy",
  },
  {
    img: "route",
    name: "dopracowane programy wypraw",
  },
  {
    img: "safety",
    name: "doświadczenie i bezpieczeństwo",
  },
];
