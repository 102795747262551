// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1XAegTSpEM4eoKsa-Y3_Uu{border:2px solid #e2e2e2;box-sizing:border-box;box-shadow:10px 10px 35px rgba(116,116,116,0.08);border-radius:10px;height:100%}._1XAegTSpEM4eoKsa-Y3_Uu img{border-top-left-radius:7px;border-top-right-radius:7px}._2GrXEuDN0PlxjaqK0aBDlk{top:0;padding:1.5rem 1rem 0 2rem}._2DCJxOT4zDlIFTM5iNQS2E{font-size:0.875rem;background-color:#cabe50;padding:0.5rem;height:36px}.qinDpD4i2aU2UxSZ6nkNj{bottom:0;font-size:1.25rem;padding:1.25rem 2rem}\n", ""]);
// Exports
exports.locals = {
	"post": "_1XAegTSpEM4eoKsa-Y3_Uu",
	"postInf": "_2GrXEuDN0PlxjaqK0aBDlk",
	"tag": "_2DCJxOT4zDlIFTM5iNQS2E",
	"postTitle": "qinDpD4i2aU2UxSZ6nkNj"
};
module.exports = exports;
