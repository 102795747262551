import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { useFormik } from "formik";

import { TextInput } from "../index";

import { NewsletterService } from "../../../services/Newsletter.service";
import useApiRequest from "../../../services/dataHooks/useApiRequest";

import style from "./NewsletterModal.module.scss";
import { LocalStorageService } from "../../../services/LocalStorage.service";

const NEWSLETTER_MODAL_SHOW_DELAY = 2000;

export const NewsletterModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const handleDecline = useCallback(() => {
    NewsletterService.decline();
    closeModal();
  }, [closeModal]);
  const [subscribe, handleSubscribe] = useApiRequest(NewsletterService.subscribe);

  const NewsletterSchema = Yup.object().shape({
    email: Yup.string().email("Błędny adres email").required("Pole wymagane"),
  });

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: NewsletterSchema,

    onSubmit: async (values) => {
      await handleSubscribe(values);
      closeModal();
      formik.resetForm();
    },
  });

  useEffect(() => {
    const isSubscribed = LocalStorageService.get<boolean>("newsletter", "subscribed");
    const declinedNewsletter = LocalStorageService.get<boolean>("newsletter", "declined");
    if (isSubscribed || declinedNewsletter) {
      return;
    }

    setTimeout(() => {
      setOpen(true);
    }, NEWSLETTER_MODAL_SHOW_DELAY);
  }, []);

  return (
    <Modal show={open} size="lg">
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="px-3">
            <div className="pt-3">
              <h3>Zapisz się do newslettera!</h3>
              <h4>Dowiedz się pierwszy o zniżkach i nowych kierunkach naszych wypraw!</h4>
            </div>
            <div className="pt-5">
              <TextInput
                icon="mail"
                inputId="email"
                label="Adres e-mail"
                placeholder="Podaj swój adres e-mail"
                value={formik.values.email}
                onChange={formik.handleChange}
                containerClassName="d-flex"
              />
              {formik.errors.email && <div className={style.validation}>* {formik.errors.email}</div>}
            </div>
            <p className="pt-5">
              Klikając zapisz akceptujesz{" "}
              <Link onClick={closeModal} to="/regulamin">
                regulamin
              </Link>
              &nbsp;i&nbsp;
              <Link onClick={closeModal} to="/polityka-prywatnosci">
                politykę prywatności
              </Link>
              . Korekta lub usunięcie danych osobowych jest możliwe na podstawie wiadomości email przesłanej z adresu ,
              który został podany w zgłoszeniu. Ta strona używa plików cookies zgodnie z polityką prywatności.
            </p>

            {subscribe.error && <p className="pt-5">{subscribe.error}</p>}
          </div>

          <div className="px-3 text-right pt-3 d-flex d-md-block flex-direction-row justify-content-between">
            <button className="btn btn-black font-weight-bolder mx-1 mx-md-2" type="button" onClick={handleDecline}>
              Nie pytaj więcej
            </button>
            <button
              disabled={subscribe.pending}
              type="submit"
              className="btn btn-green font-weight-bolder mx-1 mx-md-2"
            >
              Zapisz się
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
