import _ from "lodash";

export class LocalStorageService {
  static get<T = string>(key: string, path?: string): T {
    if (!path) {
      return JSON.parse(localStorage.getItem(key) || "{}");
    }
    return _.get(JSON.parse(localStorage.getItem(key) || "{}"), path);
  }

  static set(key: string, value: Record<string, unknown>): void {
    localStorage.setItem(
      key,
      JSON.stringify({
        ...LocalStorageService.get(key),
        ...value,
      })
    );
  }
}
