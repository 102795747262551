import React from "react";
import classnames from "classnames";

import { Button } from "../../common";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string;
  iconSize: number;
  iconClassName?: string;
}

const ButtonIcon: React.FC<IProps> = ({ className, icon, iconSize, children, iconClassName, ...props }) => {
  return (
    <Button className={classnames("d-flex justify-content-around align-items-center btn-icon", className)} {...props}>
      <span className={classnames(`si si-${icon}`, iconClassName, "mr-2")} style={{ fontSize: iconSize }} />
      {children}
    </Button>
  );
};

export default ButtonIcon;
