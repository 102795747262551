import React from "react";

import PageSection from "../../../../PageSection";

import { ITrip } from "../../tripData";
import { Video } from "../../../../../API/types";
import styles from "./TripVideo.module.scss";

interface ITripVideo {
  trip: ITrip;
}

const TripVideo: React.FC<ITripVideo> = ({ trip }) => {
  const getId = (url: string): string | null => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };

  return (
    <PageSection>
      <div className="col-12">
        <div className="shangrilaDivider" />
        {trip?.videos?.map((video: Video, key) => (
          <div key={key} className={styles.video}>
            <h3>{video.title}</h3>
            <iframe
              key={key}
              className={styles.video}
              width="560"
              height="315"
              src={`//www.youtube.com/embed/${getId(video.youTubeUrl)}`}
              frameBorder="0"
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        ))}
        <div className="shangrilaDivider mb-5" />
      </div>
    </PageSection>
  );
};

export default TripVideo;
