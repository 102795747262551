import React from "react";
import classnames from "classnames";
import MetaTags from "react-meta-tags";

import PageSection from "../../PageSection";
import { ContactHeader } from "./ContactHeader";
import { Contact, TextWithMap, NextExpeditions } from "../../common";

import useHomeData from "../../../services/dataHooks/useHomeData";
import useContactData from "../../../services/dataHooks/useContactData";
import { initialValues } from "../../common/Contact/ContactInitialValue";
import { contactContent } from "./ContactData";
import { mainData as data } from "../../../content";
import styles from "./Contact.module.scss";
import { WWW_URL } from "appConfig";
import { Page } from "../../../API/types";

import { PhotoGallery } from "../../common";
import usePageGallery from "../../../services/dataHooks/usePageGallery";
import useGAConversionHandler, { ConversionsTargets } from "../../../services/componentHooks/useGAConversionHandler";

export interface IParams {
  slug: string;
}

const ContactPage: React.FC = () => {
  const contactHandler = useGAConversionHandler(ConversionsTargets.Contact);

  const { trips } = useHomeData();
  const tripsByDate = trips?.sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate));

  const { contact } = useContactData("kontakt");
  const { gallery } = usePageGallery(contact?.id);

  return (
    <>
      <MetaTags>
        <link rel="canonical" href={WWW_URL + "/kontakt"} />

        <title>{contact?.title} - Shangrila Travel</title>
        {contact?.metaDescription && contact.metaDescription.length > 0 && (
          <meta name="description" content={contact?.metaDescription} />
        )}
        {contact?.metaKeywords && contact.metaKeywords.length > 0 && (
          <meta name="keywords" content={contact?.metaKeywords} />
        )}
        {contact?.ogTitle && contact.ogTitle.length > 0 && <meta property="og:title" content={contact?.ogTitle} />}
        {contact?.ogDescription && contact.ogDescription.length > 0 && (
          <meta property="og:description" content={contact?.ogDescription} />
        )}
        <meta property="og:locale" content="pl_PL" />
        {contact?.mainImage?.url && contact?.mainImage?.url?.length > 0 && (
          <meta property="og:image" content={contact?.mainImage.url} />
        )}
        {contact?.ogTitle && contact.ogTitle.length > 0 && <meta name="twitter:title" content={contact?.ogTitle} />}
        {contact?.ogDescription && contact.ogDescription.length > 0 && (
          <meta name="twitter:description" content={contact?.ogDescription} />
        )}
        {contact?.mainImage?.url && contact?.mainImage?.url?.length > 0 && (
          <meta name="twitter:image" content={contact?.mainImage?.url} />
        )}

        {contact?.mainImage?.url && contact?.mainImage?.url?.length > 0 && (
          <meta name="twitter:card" content="summary_large_image" />
        )}
      </MetaTags>

      <ContactHeader contact={contact ?? ({} as Page)} />

      <Contact
        header=""
        actionText=""
        headerClass="text-left"
        onSubmit={() => alert("onSubmit")}
        initialValues={initialValues}
        showAdditionalInputs={false}
      />
      <PageSection className="pt-5">
        <div className="col-12">
          <div className="shangrilaDivider" />
        </div>
      </PageSection>

      <TextWithMap header={contactContent.contactMapHeader} position={[50.30393, 18.95818]} zoom={13} />

      <PageSection>
        <div className={classnames(styles.contactNumberSection, "col")}>
          <div className="d-flex">
            <div
              className={classnames("d-flex justify-content-center align-content-center", styles.contactPhoneIconBg)}
            >
              <span
                className={classnames(
                  styles.contactPhoneIcon,
                  "si si-phone d-flex justify-content-center align-items-center"
                )}
              />
            </div>
            <div>
              <h2 className="font-weight-bold my-0">Zadzwoń do nas!</h2>
              <a
                className={classnames(styles.contactNumber, "font-weight-bold text-decoration-none")}
                href={`tel://{${data.phone}}`}
                onClick={contactHandler}
              >
                {data.visiblePhone}
              </a>
            </div>
          </div>
        </div>
      </PageSection>

      {tripsByDate && <NextExpeditions trips={tripsByDate} />}

      <PageSection>{gallery && gallery.length > 0 && <PhotoGallery photos={gallery} />}</PageSection>
    </>
  );
};

export default ContactPage;
