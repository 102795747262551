import React from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

import "./GalleryLightbox.scss";
import PageSection from "../../PageSection";

export interface OnSlideChangeCallbackParam {
  index: number;
}

interface IProps {
  imageContainerClassName?: string;
  imageClassName?: string;
  callbacks: {
    onSlideChange: (param: OnSlideChangeCallbackParam) => void;
  };
}

const options = {
  settings: {
    autoplaySpeed: 1500,
    transitionSpeed: 900,
    slideAnimationType: "fade",
  },
  thumbnails: {
    showThumbnails: false,
  },
  buttons: {
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: false,
  },
};

const GalleryLightbox: React.FC<IProps> = ({ children, ...props }) => {
  return (
    <PageSection fullWidth={false}>
      <div className="col-12">
        <SimpleReactLightbox>
          <SRLWrapper options={options} {...props}>
            {children}
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
    </PageSection>
  );
};

export default GalleryLightbox;
