import { useEffect } from "react";
import { Page } from "../../API/types";
import { getApiPageData } from "../restService";
import useApiRequest from "./useApiRequest";

interface Request<T> {
  pending: boolean;
  error: unknown;
  data: T;
}

export default function useApiPageData(slug: string): Request<Page> {
  const [page, getPage] = useApiRequest(getApiPageData, { data: {} as Page });

  useEffect(() => {
    getPage(slug);
  }, [getPage, slug]);

  return page as Request<Page>;
}
