/* eslint-disable max-len */
import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { TitleHeader, ButtonIcon } from "../../../../common";
import PageSection from "../../../../PageSection";

import styles from "./TripHeader.module.scss";
import { ITrip } from "../../tripData";
import { formatData, endDate, getUserFriendlyPriceFromApiPrice } from "../../../../../services/functions";

export interface IHeaderTrip {
  trip: ITrip;
}

const TripHeaderContent: React.FC<IHeaderTrip> = ({ trip }) => {
  const { t } = useTranslation();

  return (
    <PageSection className={styles.tripHeaderContent}>
      <TitleHeader classNameTitle={classnames(styles.tripHeaderTitle, "text-white col-12 col-md-12 col-lg-12")}>
        {trip?.name}
      </TitleHeader>

      <div className="col-12 mt-5 d-flex justify-content-lg-end">
        <div
          className={classnames(
            "d-flex flex-column justify-content-center align-items-end pr-3",
            styles.tripHeaderPriceCircle
          )}
        >
          {trip?.basePrice && (
            <span className={classnames("text-white text-uppercase font-weight-bolder", styles.tripHeaderPriceMain)}>
              {getUserFriendlyPriceFromApiPrice(trip?.basePrice?.amount)} {trip?.basePrice?.currency}
            </span>
          )}

          {trip?.additionalPrice?.amount && (
            <span className={classnames("text-white text-uppercase font-weight-bolder", styles.tripHeaderPricePlus)}>
              + {getUserFriendlyPriceFromApiPrice(trip?.additionalPrice?.amount)} {trip?.additionalPrice?.currency}
            </span>
          )}
        </div>
      </div>

      <div className="col-12 mt- mb-2">
        <div className="d-flex flex-wrap justify-content-start w-100">
          {trip?.typeOfTrip && (
            <ButtonIcon
              icon={trip?.typeOfTrip}
              iconSize={40}
              disabled
              aria-disabled
              className={classnames("btn-sm btn-white text-dark font-weight-bolder my-2", styles.tagHeader)}
            >
              {t(`type.${trip?.typeOfTrip}`)}
            </ButtonIcon>
          )}

          {trip?.duration && (
            <ButtonIcon
              icon={"date-time"}
              iconSize={40}
              disabled
              aria-disabled
              className={classnames("btn-sm btn-white text-dark font-weight-bolder my-2", styles.tagHeader)}
            >
              {trip?.duration} dni
            </ButtonIcon>
          )}

          {trip?.startDate && trip?.duration && (
            <ButtonIcon
              icon={"data"}
              iconSize={34}
              disabled
              aria-disabled
              className={classnames("btn-sm btn-white text-dark font-weight-bolder my-2", styles.tagHeader)}
            >
              {formatData(trip?.startDate)} - {endDate(trip?.startDate, trip?.duration)}
            </ButtonIcon>
          )}

          {trip?.numberOfPeople && (
            <ButtonIcon
              icon={"group"}
              iconSize={34}
              disabled
              aria-disabled
              className={classnames("btn-sm btn-white text-dark font-weight-bolder my-2", styles.tagHeader)}
            >
              {trip?.numberOfPeople} osób
            </ButtonIcon>
          )}

          {trip?.kindsOfTrip?.map((kind: string, key: number) => (
            <ButtonIcon
              key={key}
              icon={kind}
              iconSize={40}
              disabled
              aria-disabled
              className={classnames("btn-sm btn-white text-dark font-weight-bolder my-2", styles.tagHeader)}
            >
              {t(`kind.${kind}`)}
            </ButtonIcon>
          ))}
        </div>
      </div>

    </PageSection>
  );
};

export default TripHeaderContent;
