import React from "react";

import MessengerCustomerChat from "react-messenger-customer-chat";

import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import PageSection from "./PageSection";
import { NewsletterModal } from "./common";

import { SocialMedia } from "./common/SocialMedia";
import useGoogleAnalytics from "./useAnalytics";

const BaseLayout: React.FC = ({ children }) => {
  useGoogleAnalytics();

  return (
    <div className="position-relative">
      <Navbar />
      <PageSection fullWidth={true}>
        <SocialMedia />
      </PageSection>
      {children}
      <Footer />
      <PageSection>
        {/*<MessengerCustomerChat
          pageId="185114764893991"
          appId="763588437750058"
          htmlRef="https://www.facebook.com/shangrilatravelpl/"
	  />*/}
      </PageSection>
      <NewsletterModal />
    </div>
  );
};

export default BaseLayout;
