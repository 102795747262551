import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import ButtonIcon from "../../../../common/Buttons/ButtonIcon";

import style from "./Brick.module.scss";

export interface BrickProps {
  imgSrc: string;
  minHeight: number;
  button?: {
    icon: string;
    label: string;
    searchParam: string;
  };
}

const Brick: React.FC<BrickProps> = (props: BrickProps) => {
  const brickStyle = {
    minHeight: props.minHeight,
    backgroundImage: `url(${props.imgSrc})`,
  };

  const { button } = props;

  return (
    <div style={brickStyle} className={classnames(style.brick, "d-flex justify-content-center align-items-center")}>
      {button && (
        <Link to={`szukaj-wyprawy/?kind=${button.searchParam}`} className="text-decoration-none">
          <ButtonIcon
            icon={button.icon}
            iconSize={40}
            className="btn-sm btn-white btn-gray400 text-dark font-weight-bolder"
          >
            {button.label}
          </ButtonIcon>
        </Link>
      )}
    </div>
  );
};

export default Brick;
