export interface IMenuLinks {
  name: string;
  url: string;
  target: string;
  rel?: string;
}

export const menuLinks: IMenuLinks[] = [
  {
    name: "Blog",
    url: "/blog",
    target: "_self",
  },
];
