import { useEffect, useState, useCallback } from "react";
import { ITilePost } from "../../components/pages/BlogPage/tilesPostsData";
import { ISort } from "../../models";
import { getListOfPosts } from "../blogService";

interface IPostsData {
  posts: ITilePost[] | null;
  allPostsCount: number | null;
  fetchPosts: () => void;
}

export default function usePostsData({ orderBy, orderDir }: ISort): IPostsData {
  const [posts, setPosts] = useState<ITilePost[] | null>(null);
  const [allPostsCount, setAllPostsCount] = useState<number>(0);

  const fetchPosts = useCallback(async () => {
    const newPosts = await getListOfPosts(orderDir, orderBy);
    setPosts([...newPosts]);
    setAllPostsCount(newPosts.length);
  }, [orderBy, orderDir]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  return { posts, allPostsCount, fetchPosts };
}
