// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3KY4J1RPuuXHPpMpWpiNkP{white-space:nowrap;color:#000}._3KY4J1RPuuXHPpMpWpiNkP:hover{color:rgba(1,1,1,0.8);text-decoration:none}._2Ux2igCVQjay8mSP8ZwLGz{width:5.25rem;height:5.25rem}@media only screen and (max-width: 1075px){._2RqGAmjdp63pHMmu39G1nb{margin-top:1rem;margin-bottom:1rem}}@media only screen and (max-width: 600px){.m8rY7eLUVejTzP6SnTkZe{margin-top:0.5rem}}\n", ""]);
// Exports
exports.locals = {
	"navPhone": "_3KY4J1RPuuXHPpMpWpiNkP",
	"navLogo": "_2Ux2igCVQjay8mSP8ZwLGz",
	"navMenuButtons": "_2RqGAmjdp63pHMmu39G1nb",
	"navCollapseIcon": "m8rY7eLUVejTzP6SnTkZe"
};
module.exports = exports;
