import React from "react";
import classnames from "classnames";

import Brick from "./Brick";
import PageSection from "../../../../PageSection";
import { SectionIntro } from "../../common";

import { Bricks } from "./TripMasonryData";
import style from "./TripMasonry.module.scss";

const TripMasonry: React.FC = () => {
  return (
    <PageSection className={classnames(style.section, "mb-5")}>
      <div className={classnames("col-12 col-lg-8 offset-0 offset-lg-2 flex-column align-items-center")}>
        <SectionIntro title="Rodzaje wyjazdów" subtitle="subtitle" />
        <p className={classnames("mb-4 text-center", style.tileTripTypeParagraph)}>
          Wszystkie wyprawy pogrupowane są na kategorie aby ułatwić Wam znalezienie wymarzonej podróży.
        </p>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <Brick {...Bricks[0]} />
            <Brick {...Bricks[1]} />
            <Brick {...Bricks[2]} />
          </div>
          <div className="col-sm-12 col-md-6">
            <Brick {...Bricks[3]} />
            <Brick {...Bricks[4]} />
          </div>
        </div>
        {/* hidden at the customer's request */}
        {/* <div className="row">
          <div className="col-12 flex-wrap d-flex align-items-center">
            <h4 className={classnames("mr-4 d-inline-block text-uppercase text-dark font-weight-bolder mb-4")}>
              Sprawdź również
            </h4>
            <div className="d-flex flex-wrap">
              {Checkouts.map((checkout, index) => (
                <div key={index} className="mr-3 mb-4 d-inline-block align-self-center">
                  <Tags icon={checkout.icon} searchParam={checkout.label} label={checkout.label} />
                </div>
              ))}
            </div>
          </div>
        </div> */}
      </div>
    </PageSection>
  );
};

export default TripMasonry;
