import moment from "moment";
import bigInt from "big-integer";

import { DATE_FORMAT, API_DATE_FORMAT, FRACTION } from "../common/constans";

export const formatData = (date: string): string => {
  return moment.utc(date, API_DATE_FORMAT).format(DATE_FORMAT);
};

export const endDate = (start: string, duration: string): string => {
  return moment(start, API_DATE_FORMAT).add(parseInt(duration), "days").subtract(1, "days").format(DATE_FORMAT);
};

export const getUserFriendlyPriceFromApiPrice = (amount: string): number => {
  const fraction = FRACTION;
  const { quotient, remainder } = bigInt(amount).divmod(fraction);
  return quotient.valueOf() + remainder.valueOf() / fraction;
};
