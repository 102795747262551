import { hot } from "react-hot-loader/root";
import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ScrollToTop from "react-router-scroll-top";

import moment from "moment";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SimpleReactLightbox from "simple-react-lightbox";

import "../assets/svg-icons/fonticon.font";
import "../assets/styles/index.scss";

import BaseLayout from "./BaseLayout";
import PageWithTitle from "./PageWithTitle";
import { Home, About, ApiPage, TripPage, Faq, BlogPage, PostPage } from "./pages";
import { TripsSearchEngine } from "./pages/TripsSearchEngine";
import { Contact } from "./pages/Contact";

moment.locale("pl");

const App: React.FC = () => {
  return (
    <SimpleReactLightbox>
      <Router>
        <ScrollToTop>
          <BaseLayout>
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <PageWithTitle
                    component={Home}
                    title="Shangrila Travel - wyprawy i wycieczki po świecie dla każdego"
                  />
                )}
              />
              <Route exact path="/o-nas" component={About} />
              <Route exact path="/search" component={TripsSearchEngine} />
              <Route
                exact
                path="/szukaj-wyprawy"
                render={() => (
                  <PageWithTitle
                    component={TripsSearchEngine}
                    title="Shangrila Travel - znajdź swoją idealną wycieczkę"
                  />
                )}
              />
              <Route exact path="/kontakt" component={Contact} />
              <Route exact path="/trip/:slug" component={TripPage} />
              <Route exact path="/wyprawa/:slug" component={TripPage} />
              <Route exact path="/faq" component={Faq} />
              <Route
                exact
                path="/blog"
                render={() => (
                  <PageWithTitle component={BlogPage} title="Blog Shangrila Travel - sprawdź podróże z nami" />
                )}
              />
              <Route exact path="/blog/:slug" component={PostPage} />

              <Route exact path="/:slug" component={ApiPage} />
              <Redirect to="/" />
            </Switch>
          </BaseLayout>
        </ScrollToTop>
      </Router>
    </SimpleReactLightbox>
  );
};

export default hot(App);
