import React from "react";
import classnames from "classnames";

import { ButtonIcon } from "../../../../common";

import styles from "./HideButton.module.scss";

interface IHideButton {
  onClick?: () => void;
  icon: string;
  buttonClassName?: string;
}

const HideButton: React.FC<IHideButton> = ({ buttonClassName, onClick, children, icon }) => {
  return (
    <div className={classnames("d-flex justify-content-center", styles.hideButton)}>
      <ButtonIcon
        onClick={onClick}
        icon={icon}
        iconClassName={classnames(
          "d-flex justify-content-center align-items-center cursor-pointer mr-3",
          styles.hideButtonIcon
        )}
        iconSize={12}
        className={classnames("btn-sm btn-green text-white font-weight-bolder px-2", buttonClassName)}
      >
        {children}
      </ButtonIcon>
    </div>
  );
};

export default HideButton;
