import moment from "moment";

import { ITile } from "../components/common/Tile/tileData";

import { Tour } from "../API/types";
import { dataService } from "./data.service";
import { ITrip } from "../components/pages/TripPage/tripData";
import { OrderBy, OrderDir, IFilter, IDestination } from "../models";
import { AxiosResponse } from "axios";
import { IPhoto } from "../components/common/Gallery/Photo";

function mapToursToTiles(tours: Tour[]): ITile[] {
  const tiles: ITile[] = tours.map((tour: Tour) => ({
    id: tour.id,
    startDate: moment(tour.dateStart).format(),
    duration: tour.duration,
    name: tour.name,
    slug: tour.slug,
    shortName: tour.shortName,
    photo: {
      ...tour.photo,
      h: tour.photo?.height,
      w: tour.photo?.width,
      thumbUrl: tour.photo?.thumbUrl,
    },
    price: {
      ...tour?.basePrice,
      amount: tour?.basePrice?.amount?.toString(),
    },
    additionalPrice: tour?.additionalPrice,
    url: "/",
  }));

  return tiles;
}

function detailsOfTheTour(tour: Tour): ITrip {
  const specificTrip: ITrip = {
    id: tour.id,
    name: tour.name,
    slug: tour.slug,
    description: tour.description,
    photo: tour.photo,
    pros: tour.pros,
    numberOfPeople: tour.participantsLimit,
    basePrice: tour.basePrice,
    additionalPrice: tour.additionalPrice,
    priceInclude: tour.priceInclude,
    priceExclude: tour.priceExclude,
    startDate: moment(tour.dateStart).format(),
    duration: tour.duration,
    kindsOfTrip: tour.kinds,
    typeOfTrip: tour.type,
    schedule: tour.schedule,
    infoSections: tour.infoSections,
    routePoints: tour.routePoints,
    route: tour.routeDescription,
    videos: tour.videos,
    ogTitle: tour.ogTitle,
    ogDescription: tour.ogDescription,
    metaDescription: tour.metaDescription,
    metaKeywords: tour.metaKeywords,
    skaleoButtonCode: tour.skaleoButtonCode
  };

  return specificTrip;
}

async function getDetailsOfTheTrip(slug: string): Promise<ITrip> {
  const tour = await dataService.getTour(slug);
  return detailsOfTheTour(tour.data);
}

async function getTripDirection(): Promise<IDestination[]> {
  const destination = await dataService.getDestination();
  return destination.data;
}

async function getSimilarTrips(id: string): Promise<ITile[]> {
  const similar = await dataService.getSilimarTours(id);
  return mapToursToTiles(similar.data);
}

async function getUpcomingTrips(): Promise<ITile[]> {
  const tours = await dataService.getTours("dateStart", "asc", null, null, [], 10);
  return mapToursToTiles(tours.data);
}

async function getListOfTrips(
  orderBy: OrderBy,
  orderDir: OrderDir,
  kinds: IFilter | null,
  type: IFilter | null,
  filters?: IFilter[]
): Promise<ITile[]> {
  const tours = await dataService.getTours(orderBy, orderDir, kinds, type, filters);
  return mapToursToTiles(tours.data);
}

async function getListOfPhotos(id: string): Promise<IPhoto[]> {
  const gallery = (await dataService.getGallery(id)).data;
  return gallery;
}

async function getGallery(id: string, page: number, perPage: number): Promise<AxiosResponse<IPhoto[]>> {
  return dataService.getGallery(id, page, perPage);
}

export {
  getUpcomingTrips,
  getGallery,
  getListOfTrips,
  getDetailsOfTheTrip,
  getSimilarTrips,
  getTripDirection,
  getListOfPhotos,
};
