import React, { useEffect, useState } from "react";
import classnames from "classnames";

import ReactMarkdown from "react-markdown";

import styles from "./FaqComponents.module.scss";
import { IFaqTabs } from "../FaqData";

interface IFaqTab {
  data: IFaqTabs;
  active?: string;
}

const FaqTab: React.FC<IFaqTab> = ({ data, active }) => {
  const [collapsed, setCollapsed] = useState(true);

  function handleToggleNav(): void {
    setCollapsed((prevCollapsed) => !prevCollapsed);
  }

  useEffect(() => {
    if (active === data.id) setCollapsed(false);
    // eslint-disable-next-line
  }, [active]);

  return (
    <>
      <div
        className={classnames("d-flex justify-content-between align-content-center cursor-pointer", styles.FaqTab)}
        onClick={handleToggleNav}
      >
        <h3 className={classnames(styles.FaqTabHeader)}>{data.title}</h3>

        {collapsed ? (
          <span className={classnames("si si-chevron", { collapsed })} />
        ) : (
          <span className={classnames("si si-arrowDown", styles.FaqTabIcon, { collapsed })} />
        )}
      </div>

      <div
        className={classnames("collapse", {
          show: !collapsed,
        })}
      >
        <ReactMarkdown className={classnames("mt-4 py-4 px-4", styles.FaqTabDesc)} source={data.desc}></ReactMarkdown>
      </div>
      <div className="shangrilaDivider" />
    </>
  );
};

export default FaqTab;
