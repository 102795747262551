import React from "react";
import classnames from "classnames";

import { TitleHeader } from "../../../common";
import PageSection from "../../../PageSection";

import styles from "./PostHeader.module.scss";
import { IPostBlog } from "../PostsBlog";

interface IPostHeader {
  post: IPostBlog;
}

const PostHeader: React.FC<IPostHeader> = ({ post }) => {
  return (
    <div
      className={classnames(styles.postHeader, "d-flex flex-column justify-content-center")}
      style={{ backgroundImage: post.mainImage && post.mainImage.url ? `url(${post.mainImage.url})` : "none" }}
    >
      <PageSection>
        <div className="col-12">
          <TitleHeader classNameTitle={classnames(styles.postHeaderTitle, "text-white")}>{post.title}</TitleHeader>
          <div className={classnames(styles.postHeaderDesc, "text-white")}>{post.subtitle}</div>
        </div>
      </PageSection>
    </div>
  );
};

export default PostHeader;
