import React from "react";
import classnames from "classnames";
import useHomeData from "../../../services/dataHooks/useHomeData";

import PageSection from "../../PageSection";
import { FaqHeader, FaqContentTab } from "./components";
import { Contact, TextWithMap, NextExpeditions } from "../../common";

import styles from "./Faq.module.scss";
import { FaqContent } from "./FaqData";
import { initialValues } from "../../common/Contact/ContactInitialValue";

const Faq: React.FC = () => {
  const { trips } = useHomeData();
  const tripsByDate = trips?.sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate));

  return (
    <>
      <FaqHeader />

      <PageSection>
        <div className="col-12">
          <FaqContentTab />
        </div>
      </PageSection>

      <div className={styles.FaqContentContact}>
        <Contact
          header={FaqContent.contactFormHeader}
          headerClass={classnames("text-center", styles.FaqConatctForm)}
          onSubmit={() => alert("onSubmit")}
          initialValues={initialValues}
          showAdditionalInputs={false}
        />
      </div>

      <PageSection className="mb-5">
        <div className="col-12">
          <div className="shangrilaDivider" />
        </div>

        <TextWithMap header="Shangrila Travel" position={[50.30393, 18.95818]} zoom={13} />
      </PageSection>

      {tripsByDate && <NextExpeditions trips={tripsByDate} />}
    </>
  );
};

export default Faq;
