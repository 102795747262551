import React from "react";
import classnames from "classnames";

import PageSection from "../../../PageSection";

import styles from "./AboutHeader.module.scss";
import { TitleHeader } from "../../../common";
import { Page } from "../../../../API/types";

export interface IHeaderAbout {
  about: Page;
}

const AboutHeader: React.FC<IHeaderAbout> = ({ about }) => {
  return (
    <div
      className={styles.aboutHeader}
      style={{ backgroundImage: about.mainImage && about.mainImage.url ? `url(${about.mainImage.url})` : "none" }}
    >
      <PageSection className="d-flex flex-column">
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 align-content-center">
          <TitleHeader classNameTitle="text-white mb-5">
            {about?.title && (
              <span
                dangerouslySetInnerHTML={{
                  __html: about?.title,
                }}
              />
            )}
          </TitleHeader>
          {about?.content && (
            <p
              className={classnames("text-white", styles.aboutSubheader)}
              dangerouslySetInnerHTML={{
                __html: about?.content,
              }}
            />
          )}
        </div>
      </PageSection>
    </div>
  );
};

export default AboutHeader;
