import React from "react";
import classnames from "classnames";

import PageSection from "../../PageSection";
import { ZoomMap } from "../";

import styles from "./TextWithMap.module.scss";
import { mainData } from "../../../content";

interface ITextWithMap {
  header: string;
  classNameHeader?: string;
  // eslint-disable-next-line
  position: any;
  zoom: number;
}

const TextWithMap: React.FC<ITextWithMap> = ({ header, classNameHeader, position, zoom }) => {
  return (
    <PageSection>
      <div className="col">
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-sm-12 col-md-6 col-lg-5">
            <h2 className={classnames("font-weight-bold mb-3", classNameHeader)}>{header}</h2>
            <p>{mainData.addressHint}</p>
            <p>{mainData.email}</p>
            <p>{mainData.code}</p>
            <p>{mainData.address}</p>
          </div>

          <div
            className={classnames(
              styles.textWithMap,
              "col-sm-12 col-md-6 col-lg-6 d-flex justify-content-lg-end justify-content-md-center"
            )}
          >
            <ZoomMap position={position} zoom={zoom} className={classnames(styles.textWithMapZoom)} />
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default TextWithMap;
