// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1mtS_6ojmQjJ_aORCAX8zo{padding-top:150px}._2Gw8fwb-W04pEWiZVTNDc2{font-family:Montserrat;font-style:normal;font-size:1.125rem;margin-bottom:55px}._3twN4TDjUcc-apTp_0UhOE{font-size:2rem}\n", ""]);
// Exports
exports.locals = {
	"contactHeader": "_1mtS_6ojmQjJ_aORCAX8zo",
	"contactHeaderParagraph": "_2Gw8fwb-W04pEWiZVTNDc2",
	"contactHeaderSpan": "_3twN4TDjUcc-apTp_0UhOE"
};
module.exports = exports;
