import React, { useState, useEffect, useMemo } from "react";
import classnames from "classnames";
import { useSearchParam } from "react-use";
import MetaTags from "react-meta-tags";

import { NoTripsFound, SearchHeader, ListOfTrips, SearchInput, FilterTripsContainer } from "./";

import useTripsData from "../../../services/dataHooks/useTripsData";
import styles from "./TripsSearchEngine.module.scss";
import { IFilter, ISort } from "../../../models";

const TripsSearchEngine: React.FC = () => {
  const kindTrip = useSearchParam("kinds");
  const typeTrip = useSearchParam("type");

  const [sort, setSort] = useState<ISort>({ orderBy: "dateStart", orderDir: "asc" });
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [filters, setFilters] = useState<IFilter[]>([]);

  const kinds = useMemo((): IFilter => {
    return { param: "kinds", filterMode: "in", value: kindTrip as string };
  }, [kindTrip]);

  const type = useMemo((): IFilter => {
    return { param: "type", filterMode: "in", value: typeTrip as string };
  }, [typeTrip]);

  const { trips, allTripsCount, fetchTrips } = useTripsData(sort, filters, kinds, type);

  useEffect(() => {
    if (allTripsCount && trips && trips.length >= allTripsCount) setHasMore(false);
  }, [trips, allTripsCount]);

  return (
    <>
      <MetaTags>
        <meta name="robots" content="`noindex, follow`" />
      </MetaTags>

      <div className="position-relative">
        <SearchHeader />
        <div className={classnames(styles.filterContainer, "position-absolute w-100")}>
          <FilterTripsContainer handleFilters={setFilters} />
        </div>
      </div>

      {/* TODO. hidden temporarily. It will be on when there are more tours */}
      {/* {trips && <div className={styles.tripsSearchEngineBackground} />} */}
      <SearchInput allTripsCount={allTripsCount || 0} handleSort={setSort} />
      <ListOfTrips trips={trips} tripsCount={allTripsCount || 0} fetchMore={fetchTrips} hasMore={hasMore} />
      <NoTripsFound />
    </>
  );
};

export default TripsSearchEngine;
