import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getListOfPhotos } from "../../../../../services/tripService";

import { PhotoGallery } from "../../../../common";
import { IPhoto } from "../../../../common/Gallery/Photo";
import { IParams } from "../../TripPage";

/* eslint-disable max-len */

export interface ITripGallery {
  url?: string;
  w?: number;
  h?: number;
}

const TripGallery: React.FC = () => {
  const { slug: tripSlug } = useParams<IParams>();
  const [gallery, setGallery] = useState<IPhoto[]>([]);

  const fetchGallery = useCallback(async () => {
    const newGallery = await getListOfPhotos(tripSlug);
    setGallery([...newGallery]);
  }, [tripSlug]);

  useEffect(() => {
    fetchGallery();
  }, [fetchGallery]);

  return <PhotoGallery photos={gallery} />;
};

export default TripGallery;
