import { useCallback, useEffect, useState } from "react";

import { getSimilarTrips } from "../tripService";
import { ITile } from "../../components/common/Tile/tileData";

interface similarTripsData {
  similarTrips: ITile[] | null;
}

export default function useSimilarTrip(id?: string): similarTripsData {
  const [similarTrips, setSimilarTrips] = useState<ITile[] | null>(null);

  // useEffect(() => {
  //   const fetchTrip = async (): Promise<void> => {
  //     const similarTrips = await getSimilarTrips(id);
  //     setTrips(similarTrips);
  //   };

  //   fetchTrip();
  // }, [id]);

  const fetchSimilarTrips = useCallback(async () => {
    if (id) {
      const newSimilarTrips = await getSimilarTrips(id);
      setSimilarTrips([...newSimilarTrips]);
    }
  }, [id]);

  useEffect(() => {
    fetchSimilarTrips();
  }, [fetchSimilarTrips]);

  return { similarTrips };
}
