/* eslint-disable */
import ReactGA from "react-ga";
import { GA_ID } from "appConfig";

const TRACKING_ID = GA_ID;

function init() {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  ReactGA.initialize(TRACKING_ID, { debug: isDev });
}

function sendEvent(payload: any) {
  ReactGA.event(payload);
}

function sendPageview(path: any) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export default {
  init,
  sendEvent,
  sendPageview,
};
