interface IMainData {
  name: string;
  address: string;
  addressHint: string;
  code: string;
  phone: string;
  visiblePhone: string;
  expedition: string;
  contact: string;
  email: string;
}

interface IContactData {
  useTheForm: string;
  goAhead: string;
  description: string;
  actionText: string;
}

export const mainData: IMainData = {
  name: "SHANGRILA TRAVEL",
  address: "Metalowców 13",
  addressHint: "Lofty Kościuszko",
  email: "info@shangrilatravel.pl",
  code: "41-500 Chorzów",
  phone: "0048517031267",
  visiblePhone: "+48 573 504 170",
  expedition: "Wyprawy",
  contact: "Kontakt",
};

export const contactData: IContactData = {
  useTheForm: "lub skorzystaj z formularza:",
  goAhead: "Masz pytania? Pisz śmiało, jesteśmy do Twojej dyspozycji.",
  description:
    // eslint-disable-next-line max-len
    "Akapit wprowadzający do trzech głównych sekcji oferty. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse velit nunc, tristique sit amet sem ullamcorper, aliquet blandit velit.",
  actionText: "Wyślij i oczekuj niezwłocznego kontaktu zwrotnego",
};
