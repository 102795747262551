import { bootstrapBreakpoints } from "../constants";

type size = "xl" | "lg" | "md" | "sm" | "xs";

export function useBootstrapBreakpoints(windowWidth: number): size {
  if (windowWidth > bootstrapBreakpoints.xl) {
    return "xl";
  }
  if (windowWidth > bootstrapBreakpoints.lg) {
    return "lg";
  }
  if (windowWidth > bootstrapBreakpoints.md) {
    return "md";
  }
  if (windowWidth > bootstrapBreakpoints.sm) {
    return "sm";
  }

  return "xs";
}
