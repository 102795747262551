// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SyodRGti_u3_Bu1nVRL7O{position:relative;z-index:4;height:650px}._1CtYhZ40NBhpHcFPBnoXvV{z-index:-2;object-position:center;object-fit:cover}.iEwBCkadiFCwV1Xlj2neF{z-index:-1;background-color:rgba(8,30,56,0.3)}.iEwBCkadiFCwV1Xlj2neF,._1CtYhZ40NBhpHcFPBnoXvV{position:absolute;top:0;right:0;width:100%;height:100%}\n", ""]);
// Exports
exports.locals = {
	"hero": "SyodRGti_u3_Bu1nVRL7O",
	"heroVideo": "_1CtYhZ40NBhpHcFPBnoXvV",
	"videoBackground": "iEwBCkadiFCwV1Xlj2neF"
};
module.exports = exports;
