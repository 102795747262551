import React from "react";
import classnames from "classnames";

import PageSection from "../../PageSection";

import { ITile } from "../Tile/tileData";
import { Title, TileTrip } from "./..";
import styles from "./NextExpeditions.module.scss";

interface INextExpeditionsProps {
  trips: ITile[] | null;
}

const NextExpeditions: React.FC<INextExpeditionsProps> = ({ trips }) => {
  return (
    <PageSection fullWidth={true} className={classnames(styles.nextExpeditionsSection, "bg-lightblue")}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Title className={classnames("text-center mb-5")}>Najbliższe wyprawy</Title>

            <div className="row">
              {trips &&
                trips.slice(0, 3).map((trip: ITile, key: number) => (
                  <div key={key} className="col-12 col-sm-12 col-md-6 col-lg-4">
                    <TileTrip tile={trip} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default NextExpeditions;
