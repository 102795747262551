import React from "react";
import classnames from "classnames";

import { SelectInput } from "../../../common";
import PageSection from "../../../PageSection";

import { searchSortOptions, ISortOptions, SortBy } from "../TripsSearchEngineData";
import styles from "./SearchInput.module.scss";
import { OrderBy, OrderDir, ISort } from "../../../../models";

interface ISearchInput {
  allTripsCount: number;
  handleSort: (sortBy: ISort) => void;
}

const SearchInput: React.FC<ISearchInput> = ({ allTripsCount, handleSort }) => {
  const interpretSortValue = (sort: SortBy): { orderBy: OrderBy; orderDir: OrderDir } => {
    let orderBy: OrderBy;
    let orderDir: OrderDir;

    switch (sort) {
      case SortBy.dateStartAsc:
        orderBy = "dateStart";
        orderDir = "asc";
        break;
      case SortBy.dateStartDesc:
        orderBy = "dateStart";
        orderDir = "desc";
        break;
      case SortBy.basePriceAsc:
        orderBy = "basePrice";
        orderDir = "asc";
        break;
      case SortBy.basePriceDesc:
        orderBy = "basePrice";
        orderDir = "desc";
        break;
      case SortBy.durationAsc:
        orderBy = "duration";
        orderDir = "asc";
        break;
      case SortBy.durationDesc:
        orderBy = "duration";
        orderDir = "desc";
        break;
      case SortBy.nameAsc:
        orderBy = "name";
        orderDir = "asc";
        break;
      case SortBy.nameDesc:
        orderBy = "name";
        orderDir = "desc";
        break;
    }

    return { orderBy, orderDir };
  };
  return (
    <PageSection>
      <div className={classnames(styles.searchInput, "col-12")}>
        <div className="row justify-content-center justify-content-md-between align-items-center mt-5 mb-4">
          <p className={classnames("col-12 col-sm-12 col-md-6 col-lg-4 mb-0", styles.searchCounter)}>
            Znaleziono idealne wyprawy dla Ciebie: {allTripsCount}
          </p>

          <div className="col-12 col-sm-12 col-md-6 col-lg-4 mt-sm-4">
            <SelectInput
              isInputMultiple={false}
              label="Sortuj według"
              options={searchSortOptions}
              inputId="sortOptionsID"
              placeholder={searchSortOptions[0].label}
              containerClassName="form-group"
              disabled={false}
              onChange={(selected: ISortOptions) => handleSort(interpretSortValue(selected.value))}
            />
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default SearchInput;
