const polityka = "/polityka-prywatnosci";
const regulamin = "/regulamin";
const owu = "/owu";
const ubezpieczenie = "/ubezpieczenie";

export interface IFooter {
  header: string;
  links: ILinks[];
}

export interface ILinks {
  name: string;
  url: string;
  target: string;
}

export const footerLinks: IFooter[] = [
  {
    header: "O nas",
    links: [
      {
        name: "Nasz team",
        url: "/o-nas",
        target: "_self",
      },
    ],
  },
  {
    header: "Informacje",
    links: [
      {
        name: "FAQ",
        url: "/faq",
        target: "_self",
      },
      {
        name: "Polityka prywatności",
        url: polityka,
        target: "_blank",
      },
      {
        name: "Regulamin",
        url: regulamin,
        target: "_blank",
      },
      {
        name: "Ubezpieczenie",
        url: ubezpieczenie,
        target: "_blank",
      },
      {
        name: "OWU",
        url: owu,
        target: "_blank",
      },
    ],
  },
];
