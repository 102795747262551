// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Ws1tJ45DBn9x0HCwfhncE{box-shadow:10px 10px 35px rgba(116,116,116,0.08)}._2RZ1XC59krV-ZOpcuW-oXN{letter-spacing:0.08em;text-transform:uppercase}._3MqH_vF_Odfph2Sda9Bjfn{font-size:32px;margin-right:5px}._2DZedvUNVTDpDeTGTBfDTk{color:#393939}._2qL-xZPevtdS-nHtAIrKOW{font-size:20px;text-align:right}._1KKVh13ayHqcMp_2OB9tKx{font-size:20px}._1uqBf83ZS7jmhjMmR3E-LU{color:#2bad57}._2uVA6dP-tz0wwjyAXGy-8C{flex:1}.Vk6p5Q0CRbKiXnXL36wzb{height:260px}._3zJwd3m1LGa_BDoCDhWh41{object-fit:cover;height:100%}._2pNM90vP7qhrNrGF05IpXn{height:262px;background-color:rgba(43,173,86,0.509804)}._2KGlhfFWEl_otg32EKtMTs{position:relative}._2fWl3YpF04LqFY-OpmRVcl{position:absolute;top:-37.5px;left:calc(50% - 37.5px);color:white;font-size:75px}._3iHowGj3ikUiTxAbZEKw7-{color:#393939}._3iHowGj3ikUiTxAbZEKw7-:hover{color:#393939}@media (max-width: 1200px){._2pNM90vP7qhrNrGF05IpXn{height:216px}.Vk6p5Q0CRbKiXnXL36wzb{height:218px}}@media (max-width: 992px){._2pNM90vP7qhrNrGF05IpXn{height:245px}.Vk6p5Q0CRbKiXnXL36wzb{height:247px}}@media (max-width: 767px){._2pNM90vP7qhrNrGF05IpXn{height:380px}.Vk6p5Q0CRbKiXnXL36wzb{height:382px}}\n", ""]);
// Exports
exports.locals = {
	"tileCard": "_3Ws1tJ45DBn9x0HCwfhncE",
	"tileHeader": "_2RZ1XC59krV-ZOpcuW-oXN",
	"tileIcon": "_3MqH_vF_Odfph2Sda9Bjfn",
	"tileData": "_2DZedvUNVTDpDeTGTBfDTk",
	"tileExpenseToRight": "_2qL-xZPevtdS-nHtAIrKOW",
	"tileExpense": "_1KKVh13ayHqcMp_2OB9tKx",
	"tilePrice": "_1uqBf83ZS7jmhjMmR3E-LU",
	"cardInfTrip": "_2uVA6dP-tz0wwjyAXGy-8C",
	"tileLinkPhoto": "Vk6p5Q0CRbKiXnXL36wzb",
	"tilePhoto": "_3zJwd3m1LGa_BDoCDhWh41",
	"noPhoto": "_2pNM90vP7qhrNrGF05IpXn",
	"noPhotoInside": "_2KGlhfFWEl_otg32EKtMTs",
	"iconNoPhoto": "_2fWl3YpF04LqFY-OpmRVcl",
	"tileTitleLink": "_3iHowGj3ikUiTxAbZEKw7-"
};
module.exports = exports;
