import React from "react";
import classnames from "classnames";
import "./Inputs.scss";

interface IProps {
  label: string;
  placeholder: string;
  icon: string;
  inputId: string;
  iconClassName?: string;
  inputClassName?: string;
  containerClassName: string;
  value: string;
  onChange: React.EventHandler<React.ChangeEvent<HTMLInputElement>>;
}

const TextInput: React.FC<IProps> = ({
  label,
  icon,
  placeholder,
  inputId,
  iconClassName,
  inputClassName,
  containerClassName,
  ...props
}) => {
  return (
    <div className={containerClassName}>
      <span
        className={classnames(
          `si si-${icon}`,
          "d-flex justify-content-center align-items-center inputIcon",
          iconClassName
        )}
      />

      <div className="form-group inputFieldSize">
        <label htmlFor={inputId} className="input-label font-weight-bold">
          {label}
        </label>
        <input
          className={classnames("form-control", inputClassName)}
          id={inputId}
          placeholder={placeholder}
          {...props}
        />
      </div>
    </div>
  );
};

export default TextInput;
