import React from "react";
import classnames from "classnames";

import { socialFB, socialInsagram, socialYoutube } from "./images";
import styles from "./SocialMedia.module.scss";

interface ISocialMedia {
  img: string;
  url: string;
}

const socialMedia: ISocialMedia[] = [
  { img: socialFB, url: "https://www.facebook.com/shangrilatravelpl" },
  { img: socialInsagram, url: "https://www.instagram.com/shangrilatravel/" },
  { img: socialYoutube, url: "https://www.youtube.com/channel/UCFIK-RmjbM5-m9R_nwHWWxA" },
];

const SocialMedia: React.FC = () => {
  return (
    <div className="col">
      <div className="d-flex justify-content-end align-content-end">
        <div
          className={classnames(
            "bg-white d-flex flex-column justify-content-center align-items-center px-2 py-2",
            styles.socialMedia
          )}
        >
          {socialMedia.map((elementSocialMedia: ISocialMedia, key: number) => (
            <a className="mb-2" key={key} href={elementSocialMedia.url} rel="noopener noreferrer" target="_blank">
              <img src={elementSocialMedia.img} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
