import { BrickProps } from "./Brick";
import { mosonry1, mosonry2, mosonry3, mosonry4, mosonry5 } from "../../../../../assets/images";

interface Button {
  icon: string;
  label: string;
  searchParam: string;
}

export const Bricks: BrickProps[] = [
  {
    imgSrc: mosonry4,
    minHeight: 400,
    button: {
      icon: "adventure",
      label: "przygoda",
      searchParam: "adventure",
    },
  },
  {
    imgSrc: mosonry2,
    minHeight: 350,
    button: {
      icon: "culture",
      label: "kultura",
      searchParam: "culture",
    },
  },
  {
    imgSrc: mosonry3,
    minHeight: 350,
    button: {
      icon: "road-trip",
      label: "road trip",
      searchParam: "road-trip",
    },
  },

  {
    imgSrc: mosonry1,
    minHeight: 600,
    button: {
      icon: "photography",
      label: "fotografia",
      searchParam: "photography",
    },
  },

  {
    imgSrc: mosonry5,
    minHeight: 530,
    button: {
      icon: "nature",
      label: "przyroda",
      searchParam: "nature",
    },
  },
];

export const Checkouts: Button[] = [
  {
    icon: "photography",
    label: "fotografia",
    searchParam: "photography",
  },
  {
    icon: "culinary",
    label: "kulinarne",
    searchParam: "culinary",
  },
  {
    icon: "trekking",
    label: "trekking",
    searchParam: "trekking",
  },
  {
    icon: "yoga",
    label: "joga",
    searchParam: "yoga",
  },
];
