// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._33etx7IWeA8x1VPq79D3p6{font-family:\"Noto\";font-size:0.875rem;color:rgba(55,73,120,0.35);letter-spacing:0.1rem}\n", ""]);
// Exports
exports.locals = {
	"subtitle": "_33etx7IWeA8x1VPq79D3p6"
};
module.exports = exports;
