import React from "react";
import classnames from "classnames";
import styles from "./Reason.module.scss";

export interface ReasonProps {
  className?: string;
  name: string;
  img: string;
}

const Reason: React.FC<ReasonProps> = (props) => {
  return (
    <div
      className={classnames(
        styles.reason,
        props.className,
        "border container d-flex flex-column justify-content-center"
      )}
    >
      <span className={classnames(styles.image, `si si-${props.img}`, "mb-3 mt-4 mx-auto")} />
      <h4 className={classnames("text-uppercase text-dark text-center font-weight-bolder mb-4")}>{props.name}</h4>
    </div>
  );
};

export default Reason;
