// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ONPtvpyYp22mQT8znnlEU{color:#2bad57;border:2px solid #e2e2e2;box-shadow:10px 10px 35px rgba(116,116,116,0.08);border-radius:50px}._3ONPtvpyYp22mQT8znnlEU:hover{opacity:0.8}._33-JZwgRW9kPY1i3gZN8sw{width:100%}\n", ""]);
// Exports
exports.locals = {
	"scrollIcon": "_3ONPtvpyYp22mQT8znnlEU",
	"tileFullSize": "_33-JZwgRW9kPY1i3gZN8sw"
};
module.exports = exports;
