import React from "react";
import classnames from "classnames";

import { ButtonIcon, ZoomMap } from "../common";
import PageSection from "../PageSection";

import useGAConversionHandler, { ConversionsTargets } from "../../services/componentHooks/useGAConversionHandler";

import styles from "./Footer.module.scss";
import { mainData as data } from "../../content";
import { footerLinks } from "./FooterData";

const Footer: React.FC = () => {
  const contactHandler = useGAConversionHandler(ConversionsTargets.Contact);
  return (
    <PageSection fullWidth={true} className={styles.footer}>
      <div className="container">
        <div className={classnames("row", styles.footerBackground)}>
          <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-12">
            <div className={styles.footerElement}>
              <h2 className="font-weight-bold">Kontakt</h2>
              <ButtonIcon
                icon={"phone"}
                iconSize={22}
                className={classnames("btn-gray600 text-white btn-icon btn-sm d-flex mb-4")}
              >
                <a className="font-family-secondary" href={`tel://${data.phone}`} onClick={contactHandler}>
                  {data.visiblePhone}
                </a>
              </ButtonIcon>

              <ZoomMap position={[50.30393, 18.95818]} zoom={13} className={classnames(styles.footerMap)} />

              <div className={styles.footerAddress}>
                <h3 className="font-weight-bold pb-0 pt-3">{data.name}</h3>
                <p className="mt-2 mb-0">{data.addressHint}</p>
                <p className="mt-2 mb-0">{data.address}</p>
                <p className="mt-2 mb-0">{data.code}</p>
              </div>
            </div>
          </div>

          {footerLinks.map((footerLink, keyFooter: number) => (
            <div key={keyFooter} className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6">
              <div className={styles.footerElement}>
                <h2 className="font-weight-bold">{footerLink.header}</h2>
                <ul>
                  {footerLink.links.map((link, keyLink: number) => (
                    <li key={keyLink}>
                      <a rel="noopener noreferrer" target={link.target} href={link.url}>
                        {link.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.footerEnd}></div>
    </PageSection>
  );
};

export default Footer;
