import { useEffect, useState } from "react";

import { ITile } from "../../components/common/Tile/tileData";
import { getUpcomingTrips } from "../tripService";

interface homeData {
  trips: ITile[] | null;
}

export default function useHomeData(): homeData {
  const [trips, setTrips] = useState<ITile[] | null>(null);

  useEffect(() => {
    const fetchTrips = async (): Promise<void> => {
      const trips = await getUpcomingTrips();
      setTrips(trips);
    };

    fetchTrips();
  }, []);

  return { trips };
}
