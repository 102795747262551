import { Page, PageImage, Team } from "../API/types";
import { IPhoto } from "../components/common/Gallery/Photo";
import { ITeam } from "../components/pages/About/AboutData";
import { dataService } from "./data.service";

function aboutTeamInformation(team: Team[]): ITeam[] {
  const aboutTeam: ITeam[] = team.map((person: Team) => ({
    id: person.id,
    name: person.name,
    description: person.description,
    position: person.position,
    photo: {
      id: person.photo?.id,
      thumbUrl: person.photo?.thumbUrl,
      url: person.photo?.url,
      title: person.photo?.title,
      altText: person.photo?.altText,
      h: person.photo?.h,
      w: person.photo?.w,
    },
  }));

  return aboutTeam;
}

function galleryItems(gallery: PageImage[]): IPhoto[] {
  const galleryPhotos: IPhoto[] = gallery.map((item: PageImage) => ({
    id: item.id,
    thumbUrl: item.thumbUrl,
    url: item.url,
    title: item.title,
    altText: item.altText,
    h: item.h,
    w: item.w,
  }));

  return galleryPhotos;
}

async function getApiPageData(slug: string): Promise<Page> {
  const page = await dataService.getPagesSearch(slug);
  return page.data;
}

async function getAboutTeamInformation(): Promise<ITeam[]> {
  const team = await dataService.getAboutTeam();
  return aboutTeamInformation(team.data);
}

async function getPageGallery(id: string): Promise<IPhoto[]> {
  const photos = await dataService.getPageGallery(id);
  return galleryItems(photos.data);
}

export { getApiPageData, getAboutTeamInformation, getPageGallery };
