// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/searchHeader.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._3jyVBIGIPsJTcIYJRl-Z23{background:rgba(8,30,56,0.3) url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:750px;padding-top:150px;background-position:center;background-repeat:no-repeat;background-size:cover}\n", ""]);
// Exports
exports.locals = {
	"searchHeader": "_3jyVBIGIPsJTcIYJRl-Z23"
};
module.exports = exports;
