import { useCallback, useMemo, useReducer } from "react";

export enum UseApiRequestActionType {
  START,
  SUCCESS,
  ERROR,
}

type Action<T> =
  | { type: UseApiRequestActionType.START }
  | { type: UseApiRequestActionType.SUCCESS; response: T }
  | { type: UseApiRequestActionType.ERROR; error: Error };

type State<T> = { pending: boolean; data: T | null; error: Error | null };

type PromiseType<P extends unknown[] | [], T> = (...args: P) => Promise<T>;

type HookReturnType<P extends unknown[] | [], T> = [State<T>, PromiseType<P, T>];

const initialStateFactory = <T>(pending = false, error: Error | null = null, data: T | null = null): State<T> => ({
  pending,
  error,
  data,
});

const reducer = <T>(state: State<T>, action: Action<T>): State<T> => {
  switch (action.type) {
    case UseApiRequestActionType.START:
      return {
        ...state,
        pending: true,
        data: null,
        error: null,
      };
    case UseApiRequestActionType.SUCCESS:
      return {
        ...state,
        data: action.response,
        pending: false,
      };
    case UseApiRequestActionType.ERROR:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    default:
      return state;
  }
};

export default function useApiRequest<P extends unknown[] | [], T>(
  promise: PromiseType<P, T>,
  userDefinedInitialState?: Partial<State<T>>
): HookReturnType<P, T> {
  const [requestState, dispatch] = useReducer<(state: State<T>, action: Action<T>) => State<T>>(
    reducer,
    initialStateFactory<T>(
      userDefinedInitialState?.pending,
      userDefinedInitialState?.error,
      userDefinedInitialState?.data
    )
  );

  const makeRequest: PromiseType<P, T> = useCallback(
    async (...params: P): Promise<T> => {
      dispatch({ type: UseApiRequestActionType.START });

      try {
        const response = await promise(...params);
        dispatch({ type: UseApiRequestActionType.SUCCESS, response });
        return response;
      } catch (error) {
        dispatch({ type: UseApiRequestActionType.ERROR, error });
        return await Promise.reject(error);
      }
    },
    [promise]
  );

  return useMemo(() => [requestState, makeRequest], [makeRequest, requestState]);
}
