import React, { useMemo } from "react";
import classnames from "classnames";

import { GalleryLightbox } from "../..";

import { OnSlideChangeCallbackParam } from "../GalleryLightbox";

import styles from "./PhotoGallery.module.scss";
import { IPhoto } from "../Photo";
// export interface IPhoto {
//   url?: string;
//   w?: number;
//   h?: number;
//   altText?: string;
//   title?: string;
//   caption?: string;
//   thumbUrl: string;
// }

interface IPhotos {
  photos: IPhoto[];
  fullscreenMoreNext?: IPhoto[];
  fullscreenMorePrev?: IPhoto[];
  onFullscreenSlideChange?: (changeParam: OnSlideChangeCallbackParam) => void;
}

const PhotoGallery: React.FC<IPhotos> = ({
  photos,
  fullscreenMoreNext,
  fullscreenMorePrev,
  onFullscreenSlideChange,
}) => {
  const callbacks = useMemo(
    () => ({
      onSlideChange: (changeParam: OnSlideChangeCallbackParam) => {
        if (typeof onFullscreenSlideChange === "function") {
          onFullscreenSlideChange(changeParam);
        }
      },
    }),
    [onFullscreenSlideChange]
  );

  return (
    <GalleryLightbox callbacks={callbacks}>
      <div className={styles.galleryCongtainer}>
        <div className={classnames("col-12")}>
          <div className={classnames("row")}>
            {fullscreenMorePrev?.map((fullscreenMore, keyPhoto) => (
              <div key={keyPhoto}>
                <div>
                  <a href={fullscreenMore.url} data-attribute="SRL">
                    <img
                      className="galleryPhoto"
                      src={fullscreenMore.url}
                      width={fullscreenMore.url}
                      alt={fullscreenMore.altText}
                      title={fullscreenMore.title}
                    />
                  </a>
                </div>
              </div>
            ))}

            <div className={classnames("d-flex align-content-center", styles.mainPhoto, styles.galleryPhoto)}>
              {photos[0]?.url ? (
                <a href={photos[0]?.url} data-attribute="SRL" className={styles.mainPhotoA}>
                  <img
                    src={photos[0]?.thumbUrl}
                    height={photos[0]?.h}
                    width={photos[0]?.w}
                    alt={photos[0]?.altText}
                    title={photos[0]?.title}
                    className={classnames(styles.image, styles.mainImageMobile, styles.imageVertical, "w-100")}
                  />
                </a>
              ) : (
                <div
                  className={classnames(
                    "d-flex justify-content-center align-items-center",
                    styles.noImage,
                    styles.noImageMain
                  )}
                >
                  <span className={classnames(styles.iconNoImage, "si si-photography")} />
                </div>
              )}
            </div>
            <div className={classnames("d-flex flex-column", styles.nextPhotos)}>
              <div className={classnames("d-flex justify-content-around flex-nowrap", styles.photosRow)}>
                <div className="pl-3 pb-2" style={{ flex: 3 }}>
                  {photos[1]?.url ? (
                    <a href={photos[1]?.url} data-attribute="SRL">
                      <img
                        src={photos[1].thumbUrl}
                        width={photos[1]?.w}
                        height={photos[1]?.h}
                        alt={photos[1]?.altText}
                        title={photos[1]?.title}
                        className={classnames(styles.image, styles.imageRow, "w-100")}
                      />
                    </a>
                  ) : (
                    <div className={classnames("d-flex justify-content-center align-items-center", styles.noImage)}>
                      <span className={classnames(styles.iconNoImage, "si si-photography")} />
                    </div>
                  )}
                </div>

                <div className="pl-3 pb-2" style={{ flex: 2 }}>
                  {photos[2]?.url ? (
                    <a href={photos[2]?.url} data-attribute="SRL">
                      <img
                        src={photos[2]?.thumbUrl}
                        width={photos[2]?.w}
                        height={photos[2]?.h}
                        alt={photos[2]?.altText}
                        title={photos[2]?.title}
                        className={classnames(styles.image, styles.imageRow, "w-100")}
                      />
                    </a>
                  ) : (
                    <div className={classnames("d-flex justify-content-center align-items-center", styles.noImage)}>
                      <span className={classnames(styles.iconNoImage, "si si-photography")} />
                    </div>
                  )}
                </div>
              </div>
              <div className={classnames("d-flex justify-content-around flex-nowrap", styles.photosRow)}>
                <div className={classnames("pl-3 pt-2", styles.photosSecondRow)} style={{ flex: 2 }}>
                  {photos[3]?.url ? (
                    <a href={photos[3]?.url} data-attribute="SRL">
                      <img
                        src={photos[3]?.thumbUrl}
                        width={photos[3]?.w}
                        height={photos[3]?.h}
                        title={photos[3]?.title}
                        alt={photos[3]?.altText}
                        className={classnames(styles.image, styles.imageRow, "w-100")}
                      />
                    </a>
                  ) : (
                    <div className={classnames("d-flex justify-content-center align-items-center", styles.noImage)}>
                      <span className={classnames(styles.iconNoImage, "si si-photography")} />
                    </div>
                  )}
                </div>

                <div className="pl-3 pt-2" style={{ flex: 3 }}>
                  {photos[4]?.url ? (
                    <a href={photos[4]?.url} data-attribute="SRL">
                      <img
                        src={photos[4]?.thumbUrl}
                        width={photos[4]?.w}
                        height={photos[4]?.h}
                        alt={photos[4]?.altText}
                        title={photos[4]?.title}
                        className={classnames(styles.image, styles.imageRow, "w-100")}
                      />
                    </a>
                  ) : (
                    <div className={classnames("d-flex justify-content-center align-items-center", styles.noImage)}>
                      <span className={classnames(styles.iconNoImage, "si si-photography")} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {fullscreenMoreNext?.map((fullscreenMore, keyPhoto) => (
              <div key={keyPhoto}>
                <div>
                  <a href={fullscreenMore.url} data-attribute="SRL">
                    <img
                      className="galleryPhoto"
                      src={fullscreenMore.url}
                      width={fullscreenMore.url}
                      alt={fullscreenMore.altText}
                      title={fullscreenMore.title}
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </GalleryLightbox>
  );
};

export default PhotoGallery;
