// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1GSGX03kJGMFp6HySBkNW1{padding-top:60px;padding-bottom:85px;margin-top:75px}\n", ""]);
// Exports
exports.locals = {
	"nextExpeditionsSection": "_1GSGX03kJGMFp6HySBkNW1"
};
module.exports = exports;
