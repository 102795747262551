import { useEffect, useState } from "react";
import { Page } from "../../API/types";
import { getApiPageData } from "../restService";

interface IContactData {
  contact: Page | undefined;
}

export default function useContactData(slug: string): IContactData {
  const [contact, setContact] = useState<Page>();

  useEffect(() => {
    const fetchContactData = async (): Promise<void> => {
      const contact = await getApiPageData(slug);
      setContact(contact);
    };

    fetchContactData();
  }, [slug]);

  return { contact };
}
