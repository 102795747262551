import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import { TripType } from "..";
import PageSection from "../../../../PageSection";
import { SectionIntro } from "../../common";

import styles from "./TypesOfTrips.module.scss";
import { tripsTypes, ITypesOfTrips } from "./TypesOfTripsData";

const TypesOfTrips: React.FC = () => {
  return (
    <PageSection className={classnames(styles.listOfTripsType)}>
      <div className={styles.backgroundImageTrees}>
        <div className={classnames("col-12 col-lg-8 offset-0 offset-lg-2 flex-column align-items-center")}>
          <SectionIntro title="Typy wyjazdów" subtitle="subtitle" />
          <p className={classnames("mb-4 text-center", styles.tileTripTypeParagraph)}>
            Szukasz egzotycznej wycieczki dopasowanej do Twoich potrzeb? Wybierz jeden z 3 typów wyjazdów od Shangrila
            Travel. Lubisz wyprawy z plecakiem - sprawdź
            <Link to={`szukaj-wyprawy/?type=tramping`} className="text-dark font-weight-bold ml-2 text-nowrap">
              tramping
            </Link>
            ; komfortowe i wygodne noclegi - wycieczki
            <Link to={`szukaj-wyprawy/?type=premium`} className="text-dark font-weight-bold mx-2 text-nowrap">
              premium
            </Link>
            spełnią Twoje oczekiwania; masz grupę znajomych i szukasz czegoś niestandardowego, dopasowanego do Waszych
            potrzeb - wycieczki
            <Link className="text-dark font-weight-bold mx-2 text-nowrap" to={"/kontakt"}>
              szyte na miarę
            </Link>
            sprawdzą się w tym przypadku.
            <Link className="text-dark font-weight-bold ml-2" to={"/faq?faqSection=1"}>
              Dowiedz się więcej...
            </Link>
          </p>
        </div>
        <div className="col-12">
          <div className="row justify-content-center">
            {tripsTypes.map((tripType: ITypesOfTrips, key: number) => (
              <div key={key} className="col-12 col-md-6 col-lg-3">
                <TripType type={tripType} searchParam={tripType.searchParam} url={tripType.url} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default TypesOfTrips;
