import React from "react";
import classnames from "classnames";

import styles from "./Subtitle.module.scss";

interface IProps {
  className?: string;
}

const Subtitle: React.FC<IProps> = ({ className, children }) => {
  return (
    <span className={classnames("text-uppercase font-weight-bold mb-3", styles.subtitle, className)}>{children}</span>
  );
};

export default Subtitle;
