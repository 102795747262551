// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Rc4IyDfGTvhtE1yw5VDwv{margin:37px 0}._3CQ6OTb1sMJDtI7UC56Nh-{border-radius:50%}._2rM_1I8Gjt-bJS1p7NaRik{width:90px;height:90px}._3PMTDqRCJdf6cELKEbOYbU{font-size:1.875rem}._2ZKczbSlEFbSjdiRz7DHOe{font-size:0.875rem}._1Wzu9scf2WyRW8PGVuBM__{width:35px;height:35px}._20_EhKHnH4A5qmcHB1dYYT{margin-bottom:77px}\n", ""]);
// Exports
exports.locals = {
	"tripEstimateType": "_1Rc4IyDfGTvhtE1yw5VDwv",
	"tripEstimateIcon": "_3CQ6OTb1sMJDtI7UC56Nh-",
	"tripEstimateFieldLg": "_2rM_1I8Gjt-bJS1p7NaRik",
	"tripEstimateIconLg": "_3PMTDqRCJdf6cELKEbOYbU",
	"tripEstimateIconSm": "_2ZKczbSlEFbSjdiRz7DHOe",
	"tripEstimateFieldSm": "_1Wzu9scf2WyRW8PGVuBM__",
	"esmiatePoint": "_20_EhKHnH4A5qmcHB1dYYT"
};
module.exports = exports;
