// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._35CrrHnKrDqiqaNhTZCdlW{width:100%;min-height:600px;height:auto}@media only screen and (max-width: 990px){._35CrrHnKrDqiqaNhTZCdlW{min-height:400px}}@media only screen and (max-width: 500px){._35CrrHnKrDqiqaNhTZCdlW{min-height:300px}}\n", ""]);
// Exports
exports.locals = {
	"video": "_35CrrHnKrDqiqaNhTZCdlW"
};
module.exports = exports;
