import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { ButtonIcon } from "../../../../common";
import PageSection from "../../../../PageSection";

import styles from "./Hero.module.scss";

const HeroTitle: React.FC = () => {
  return (
    <PageSection>
      <div className="col-12 col-md-10 col-lg-8">
        <h1 className={classnames(styles.title, "font-weight-bolder mb-4 text-uppercase text-white")}>
          Zaplanuj swoje wyjątkowe wakacje
        </h1>
        <div>
          <Link to="/szukaj-wyprawy" className="text-decoration-none">
            <ButtonIcon
              icon={"search"}
              iconSize={33}
              className={"btn-rounded btn-lg btn-green font-weight-bolder mb-3"}
            >
              Znajdź wyprawę
            </ButtonIcon>
          </Link>
        </div>
      </div>
    </PageSection>
  );
};

export default HeroTitle;
