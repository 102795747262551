import React from "react";
import classnames from "classnames";

import { Button } from "../../common";

interface IProps {
  className: string;
  wrapperClassName?: string;
}

const ButtonTriangular: React.FC<IProps> = ({ wrapperClassName, className, children, ...props }) => {
  return (
    <div className={classnames("btn-triangular-wrapper", wrapperClassName)}>
      <Button className={classnames("btn-triangular", className)} {...props}>
        {children}
      </Button>
    </div>
  );
};

export default ButtonTriangular;
