import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { Button } from "../../../common";
import PageSection from "../../../PageSection";

import styles from "./NoTripsFound.module.scss";

const NoTripsFound: React.FC = () => {
  return (
    <PageSection fullWidth={true} className={classnames(styles.sectionNoFound, "bg-lightblue")}>
      <div className="container">
        <div className="row d-flex justyfy-content-between align-items-center">
          <div className="col-12 col-xl-9 col-lg-9 col-md-6 col-sm-12 col-xs-12">
            <h2 className="font-weight-bold text-uppercase m-0">Nie znalazłeś swojej wyprawy?</h2>
          </div>
          <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <Link to="/kontakt" className="text-decoration-none">
              <Button className={"btn-green font-weight-bolder"}>Skontaktuj się z nami</Button>
            </Link>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default NoTripsFound;
