/* eslint-disable max-len */
import React from "react";
import classnames from "classnames";

import styles from "./TripHeader.module.scss";
import { ITrip } from "../../tripData";
import TripHeaderContent from "./TripHeaderContent";

export interface IHeaderTrip {
  trip: ITrip;
}

const TripHeader: React.FC<IHeaderTrip> = ({ trip }) => {
  return (
    <>
      {trip.photo ? (
        <div className={styles.tripHeader} style={{ backgroundImage: `url(${trip?.photo?.url})` }}>
          <TripHeaderContent trip={trip} />
        </div>
      ) : (
        <div className={classnames(styles.tripHeader, styles.tripHeaderNoImage)}>
          <TripHeaderContent trip={trip} />
        </div>
      )}
    </>
  );
};

export default TripHeader;
