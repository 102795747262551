import React from "react";
import classnames from "classnames";

import HeroVideo from "./HeroVideo";
import HeroTitle from "./HeroTitle";

import styles from "./Hero.module.scss";
import { heroVideo } from "../../../../../assets/video";

const Hero: React.FC = () => {
  return (
    <div className={classnames(styles.hero, "d-flex flex-column justify-content-center")}>
      <HeroVideo heroVideo={heroVideo}>
        <HeroTitle />
      </HeroVideo>
    </div>
  );
};

export default Hero;
