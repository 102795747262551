// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2JmtpMnOH_jCUwhgJk2OZX{background-color:#f8fbff;padding-top:5.75rem;padding-bottom:5.75rem;margin-top:5rem;margin-bottom:1.875rem}\n", ""]);
// Exports
exports.locals = {
	"sectionNoFound": "_2JmtpMnOH_jCUwhgJk2OZX"
};
module.exports = exports;
