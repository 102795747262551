// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bG11lhl2mhpdCE4LGvc4-{background:rgba(8,30,56,0.3);height:750px;padding-top:150px;background-position:center;background-repeat:no-repeat;background-size:cover;padding-top:0;height:460px;margin-bottom:140px}.yzppMd8X_fDn9b5FHJSun{font-family:Montserrat;font-style:normal;font-weight:normal;font-size:1.125rem;line-height:1.8;flex-shrink:1}.QRyu1Hf1wPGTkbyuqPMQU{flex-shrink:1}._3n35Nvd-DG-OArIjVxeB8D{width:32px;height:32px;border-radius:50%}._1Af-UBnWoQO2D4UoECWo35{font-size:20px}.-R0Uo2bsR-zNjubTPQoD_{font-family:Montserrat;font-style:normal;font-weight:normal;font-size:18.8}\n", ""]);
// Exports
exports.locals = {
	"postHeader": "bG11lhl2mhpdCE4LGvc4-",
	"postHeaderDesc": "yzppMd8X_fDn9b5FHJSun",
	"postHeaderTitle": "QRyu1Hf1wPGTkbyuqPMQU",
	"authorImg": "_3n35Nvd-DG-OArIjVxeB8D",
	"authorName": "_1Af-UBnWoQO2D4UoECWo35",
	"postDate": "-R0Uo2bsR-zNjubTPQoD_"
};
module.exports = exports;
