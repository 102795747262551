// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/shangFooter.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._2Pe6RQN9Q3ARaQ1aS32_6Y{background-color:#292929}._2jb4Qo-rlHqyHY9-tksKdG{background:linear-gradient(to bottom, rgba(41,41,41,0.85) 0%, rgba(41,41,41,0.85) 100%),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") bottom right no-repeat;background-size:50%}._1J0ta7tDX4hdUJlda0i_co{padding-bottom:2em;padding-top:3em}._1J0ta7tDX4hdUJlda0i_co h2{font-family:\"Noto\";font-size:1.5rem;color:#fff;margin-bottom:3.5rem}._1J0ta7tDX4hdUJlda0i_co ul{padding:0}._1J0ta7tDX4hdUJlda0i_co li{font-size:0.875rem;list-style:none;margin-bottom:1.625rem}._1J0ta7tDX4hdUJlda0i_co a{text-decoration:none;color:#fff}._1J0ta7tDX4hdUJlda0i_co a:hover{text-decoration:none;color:#fff;opacity:0.6}._1J0ta7tDX4hdUJlda0i_co h2,._1J0ta7tDX4hdUJlda0i_co li{font-family:\"Noto\";letter-spacing:0.035em;text-transform:uppercase}._6m3I8WGruKF9veK9TX7L6{width:80%;height:220px}._6m3I8WGruKF9veK9TX7L6 a{color:#151515}._6m3I8WGruKF9veK9TX7L6 a:hover{color:#151515}.opyx6hhs1hZ1d6ZK_6Hk4 p{white-space:nowrap}.opyx6hhs1hZ1d6ZK_6Hk4 p,.opyx6hhs1hZ1d6ZK_6Hk4 h3{font-family:\"Noto\";letter-spacing:0.035em;font-size:1rem;letter-spacing:0.1em;color:#fff}._1RtX_U6-ZnO0zfPwagZJuB{width:100%;height:2.25rem;background-color:#151515}\n", ""]);
// Exports
exports.locals = {
	"footer": "_2Pe6RQN9Q3ARaQ1aS32_6Y",
	"footerBackground": "_2jb4Qo-rlHqyHY9-tksKdG",
	"footerElement": "_1J0ta7tDX4hdUJlda0i_co",
	"footerMap": "_6m3I8WGruKF9veK9TX7L6",
	"footerAddress": "opyx6hhs1hZ1d6ZK_6Hk4",
	"footerEnd": "_1RtX_U6-ZnO0zfPwagZJuB"
};
module.exports = exports;
