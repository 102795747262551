export interface IFilter {
  param: Params;
  filterMode?: FilterModes;
  value: number | string;
}

export interface ISort {
  orderBy: OrderBy;
  orderDir: OrderDir;
}

export type FilterModes = "value" | "in" | "phrase" | "gt" | "gte" | "lt" | "lte";
export type Params = "destination" | "type" | "kinds" | "duration" | "difficulty";
export type OrderBy = "dateStart" | "name" | "duration" | "basePrice" | "createdAt";
export type OrderDir = "asc" | "desc";

export enum FilterFormTypes {
  destination = "destination",
  type = "type",
  kinds = "kinds",
  length = "duration",
  difficulty = "difficulty",
}

export enum ContactFieldsTypes {
  direction = "directionId",
  quantity = "quantityId",
  phone = "phoneId",
  email = "emailId",
  message = "messageId",
}
