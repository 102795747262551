import { Post } from "../API/types";
import { ITilePost } from "../components/pages/BlogPage/tilesPostsData";
import { IPostBlog } from "../components/pages/Post/PostsBlog";
import { dataService } from "./data.service";
import moment from "moment";
import { OrderBy, OrderDir } from "../models";

function postBlogContent(post: Post): IPostBlog {
  const postContent: IPostBlog = {
    id: post.id,
    slug: post.slug,
    title: post.title,
    subtitle: post.subtitle,
    content: post.content,
    author: post.author,
    createdAt: post.createdAt,
    mainImage: post.mainImage,
    metaDescription: post.metaDescription,
    metaKeywords: post.metaKeywords,
    ogDescription: post.ogDescription,
    ogTitle: post.ogTitle,
  };

  return postContent;
}

function mapPostsToTiles(posts: Post[]): ITilePost[] {
  const blogPosts: ITilePost[] = posts.map((post: Post) => ({
    image: post.mainImage,
    date: moment(post.createdAt).format(),
    title: post.title,
    tag: post.tag,
    description: post.description,
    slug: post.slug,
  }));

  return blogPosts;
}

async function getPostBlog(slug: string): Promise<IPostBlog> {
  const post = await dataService.getPost(slug);
  return postBlogContent(post.data);
}

async function getListOfPosts(orderDir: OrderDir, orderBy: OrderBy): Promise<ITilePost[]> {
  const posts = await dataService.getPosts(orderBy, orderDir);
  return mapPostsToTiles(posts.data);
}

export { getPostBlog, getListOfPosts };
