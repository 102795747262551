import React from "react";
import classnames from "classnames";

import InfiniteScroll from "react-infinite-scroll-component";

import PageSection from "../../../PageSection";
import { TileTrip } from "../../../common";

import styles from "./ListOfTrips.module.scss";
import { ITile } from "../../../common/Tile/tileData";

interface IListOfTripsProps {
  trips: ITile[] | null;
  tripsCount: number | undefined;
  fetchMore: () => void;
  hasMore: boolean;
}

const ListOfTrips: React.FC<IListOfTripsProps> = ({ trips, tripsCount, fetchMore, hasMore }) => {
  return (
    <>
      {tripsCount && (
        <InfiniteScroll
          dataLength={tripsCount}
          next={fetchMore}
          hasMore={hasMore}
          loader={
            <div className="d-flex justify-content-center align-content-center pt-5">
              <span className={classnames("si si-chevron bg-white px-3 py-3", styles.scrollIcon)} onClick={fetchMore} />
            </div>
          }
        >
          <PageSection>
            <div className="col-12">
              <div className="row">
                {trips?.map((trip: ITile, key: number) => (
                  <div key={key} className="col-12 col-sm-12 col-md-6 col-lg-4">
                    <TileTrip tileClass={styles.tileFullSize} tile={trip} />
                  </div>
                ))}
              </div>
            </div>
          </PageSection>
        </InfiniteScroll>
      )}
    </>
  );
};

export default ListOfTrips;
