import { useCallback, useEffect, useState } from "react";
import { IPhoto } from "../../components/common/Gallery/Photo";
import { getPageGallery } from "../restService";

interface galleryData {
  gallery: IPhoto[] | null;
}

export default function usePageGallery(id?: string): galleryData {
  const [gallery, setGallery] = useState<IPhoto[] | null>(null);

  const fetchGallery = useCallback(async () => {
    if (id) {
      const newGallery = await getPageGallery(id);
      setGallery([...newGallery]);
    }
  }, [id]);

  useEffect(() => {
    fetchGallery();
  }, [fetchGallery]);

  return { gallery };
}
