// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1qyMgpWzjLOOpEXGOPyn4F{color:red;margin-top:10px;font-size:10px}\n", ""]);
// Exports
exports.locals = {
	"validation": "_1qyMgpWzjLOOpEXGOPyn4F"
};
module.exports = exports;
