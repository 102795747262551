import React from "react";
import classnames from "classnames";

interface ITitleHeader {
  classNameTitle: string;
}

const TitleHeader: React.FC<ITitleHeader> = ({ children, classNameTitle }) => {
  return <h1 className={classnames(classNameTitle, "text-white font-weight-bolder text-uppercase")}>{children}</h1>;
};

export default TitleHeader;
