import React from "react";
import classnames from "classnames";

import styles from "./TripEstimate.module.scss";

interface ITripEstimateIcon {
  icon: string;
  iconBg: string;
  iconSize: string;
  fieldSize: string;
}

const TripEstimateIcon: React.FC<ITripEstimateIcon> = ({ icon, iconBg, iconSize, fieldSize }) => {
  return (
    <div
      className={classnames(
        "d-flex justify-content-center align-items-center align-items-center text-white",
        iconBg,
        iconSize,
        fieldSize,
        styles.tripEstimateIcon
      )}
    >
      <span className={classnames(`si si-${icon}`)}></span>
    </div>
  );
};

export default TripEstimateIcon;
