import { IOption, IOptionNumber } from "./input.models";

export enum TripDuration {
  first = "7",
  second = "7-14",
  third = "14-21",
  forth = "21",
}

export const tripsTypeOptions: IOption[] = [
  { label: "Premium", value: "premium" },
  { label: "Tramping", value: "tramping" },
];

export const tripsKindOptions: IOption[] = [
  { label: "Przyroda", value: "nature" },
  { label: "Road trip", value: "road-trip" },
  { label: "Kultura", value: "culture" },
  { label: "Przygoda", value: "adventure" },
  { label: "Fotografia", value: "photoshooting" },
  { label: "Relaks", value: "relax" },
  { label: "Trekking", value: "trekking" },
  { label: "Sport", value: "sport" },
  { label: "Safari", value: "safari" },
];

export const tripsDirectionOptions: IOption[] = [
  { value: "Hawaje", label: "Hawaje" },
  { value: "Norwegia", label: "Norwegia" },
  { value: "Andy", label: "Andy" },
  { value: "Kazachstan", label: "Kazachstan" },
  { value: "Australia", label: "Austaria" },
];

export const tripsLevelOfDifficulty: IOption[] = [
  { label: "Łatwy", value: "easy" },
  { label: "Średni", value: "medium" },
  { label: "Trudny", value: "hard" },
  { label: "Ekstremalny", value: "extreme" },
];

export const tripsLengthOptions: IOption[] = [
  { value: TripDuration.first, label: `< ${TripDuration.first} dni` },
  { value: TripDuration.second, label: `${TripDuration.second} dni` },
  { value: TripDuration.third, label: `${TripDuration.third} dni` },
  { value: TripDuration.forth, label: `${TripDuration.forth}+ dni` },
];

export const tripNumberOfPeople: IOptionNumber[] = [
  { value: 1, label: "1 osoba" },
  { value: 2, label: "2 osoby" },
  { value: 3, label: "3 osoby" },
  { value: 4, label: "4 osoby" },
  { value: 5, label: "5 osób" },
  { value: 6, label: "6 osób" },
  { value: 7, label: "7 osób" },
  { value: 8, label: "8 osób" },
  { value: 9, label: "9 osób" },
  { value: 10, label: "10 osób" },
  { value: 11, label: "11 osób" },
  { value: 12, label: "12 osób" },
  { value: 13, label: "13 osób" },
  { value: 14, label: "14 osób" },
  { value: 15, label: "15 osób" },
];
