import React, { useState } from "react";
import classnames from "classnames";

import { HideButton } from "../../common";
import PageSection from "../../../../PageSection";

import { ITrip } from "../../tripData";
import { TourScheduleElement } from "../../../../../API/types";

import styles from "../../TripPage.module.scss";
import "../../common/tripSectionExpand.scss";

export interface ITripProgram {
  trip: ITrip;
}

const TripProgram: React.FC<ITripProgram> = ({ trip }) => {
  const [btnText, setBtnText] = useState(false);
  const [btnIcon, setBtnIcon] = useState(false);
  const [fullContent, setFullContent] = useState(true);

  function handleTogglePoints(): void {
    showFullContent();
    changeBtnText();
    changeBtnIcon();
  }

  const changeBtnText = (): void => setBtnText(!btnText);
  const changeBtnIcon = (): void => setBtnIcon(!btnIcon);
  const showFullContent = (): void => setFullContent(!fullContent);

  return (
    <PageSection>
      <div className="col-12">
        {trip?.schedule?.length > 0 && <h4 className="text-uppercase font-weight-bolder mb-5">Program wyprawy</h4>}
        <div className="row d-flex justify-content-center">
          <div className="col-10">
            <div className={classnames(fullContent ? "tripSectionContentProgram" : "tripSectionContentExpanded")}>
              {trip?.schedule?.map((tripProgramPoint: TourScheduleElement, key: number) => (
                <div key={key} className={classnames("d-flex", styles.tileInfoTripContent)}>
                  <span className={classnames(`si si-data`, "mr-4 ml-3", styles.tileInfoTripIcon)} />
                  <div className="d-flex flex-column mt-4">
                    <div>
                      <h4 className="text-uppercase font-weight-bolder mb-4">
                        <span>{tripProgramPoint.dayStart} </span>
                        {tripProgramPoint.dayStart < tripProgramPoint.dayEnd && (
                          <span> - {tripProgramPoint.dayEnd} </span>
                        )}
                        DZIEŃ
                      </h4>
                    </div>

                    <p className={classnames("pl-1 mb-0", styles.tileInfoTripDesc)}>{tripProgramPoint.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {trip?.schedule?.length > 0 && (
          <HideButton
            onClick={handleTogglePoints}
            buttonClassName="tripSectionFadeoutBtn"
            icon={classnames(btnIcon ? "chevron-top" : "chevron-bottom")}
          >
            {btnText && <span>Zwiń</span>}
            {!btnText && <span>Rozwiń</span>}
          </HideButton>
        )}
      </div>
    </PageSection>
  );
};

export default TripProgram;
