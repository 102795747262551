import React from "react";
import { Title } from "../../../../common";

export interface SectionIntroProps {
  title: string;
  subtitle?: string;
}

const SectionIntro: React.FC<SectionIntroProps> = ({ title }) => {
  return (
    <>
      {/* {subtitle && <Subtitle className="d-flex justify-content-center">{subtitle}</Subtitle>} */}
      <Title className="d-flex justify-content-center">{title}</Title>
      <div className="shangrilaDivider" />
    </>
  );
};

export default SectionIntro;
