import { useEffect, useState } from "react";
import { IPostBlog } from "../../components/pages/Post/PostsBlog";
import { getPostBlog } from "../blogService";

interface IPostData {
  post: IPostBlog | undefined;
}

export default function usePostData(slug: string): IPostData {
  const [post, setPost] = useState<IPostBlog>();

  useEffect(() => {
    const fetchTrip = async (): Promise<void> => {
      const post = await getPostBlog(slug);
      setPost(post);
    };

    fetchTrip();
  }, [slug]);

  return { post };
}
