// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._358-OHBgXxlWZldIIHd29x{margin:2rem 0}._1pBwWcUo6G_WxkQ6E9MKmU{line-height:2.3}._3VRTV6BnPzsq5fBeb7dzo{width:100%;height:333px}@media (min-width: 768px){._3VRTV6BnPzsq5fBeb7dzo{width:90%;height:333px}}\n", ""]);
// Exports
exports.locals = {
	"textWithMapImage": "_358-OHBgXxlWZldIIHd29x",
	"textWithMapDesc": "_1pBwWcUo6G_WxkQ6E9MKmU",
	"textWithMapZoom": "_3VRTV6BnPzsq5fBeb7dzo"
};
module.exports = exports;
