import { useEffect, useState, useCallback } from "react";

import { ITile } from "../../components/common/Tile/tileData";

import { getListOfTrips } from "../tripService";
import { IFilter, ISort } from "../../models";

interface ITripsData {
  trips: ITile[] | null;
  allTripsCount: number | null;
  fetchTrips: () => void;
}

export default function useTripsData(
  { orderBy, orderDir }: ISort,
  filters: IFilter[],
  kinds: IFilter | null,
  type: IFilter | null
): ITripsData {
  const [trips, setTrips] = useState<ITile[] | null>(null);
  const [allTripsCount, setAllTripsCount] = useState<number>(0);

  const fetchTrips = useCallback(async () => {
    const newTrips = await getListOfTrips(orderBy, orderDir, kinds, type, filters);
    setTrips([...newTrips]);
    setAllTripsCount(newTrips.length);
  }, [orderBy, orderDir, filters, kinds, type]);

  useEffect(() => {
    fetchTrips();
  }, [fetchTrips, kinds, type]);

  return { trips, allTripsCount, fetchTrips };
}
