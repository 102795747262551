export interface ITypesOfTrips {
  name: string;
  image: string;
  searchParam?: string;
  url?: string;
}

export const tripsTypes: ITypesOfTrips[] = [
  {
    name: "Tramping",
    image: "tramping",
    searchParam: "tramping",
  },
  {
    name: "Premium",
    image: "premium",
    searchParam: "premium",
  },
  {
    name: "Szyte na miarę",
    image: "puzzle",
    url: "/kontakt",
  },
];
